//Boilerplate
@use "./boilerplate/default.scss" as *;
@use "./boilerplate/fonts.scss" as *;
@use "./boilerplate/layout.scss" as *;
@use "./boilerplate/theme.scss" as *;
//Pages
@use "./pages/introduction.scss" as *;
@use "./pages/prestations.scss" as *;
@use "./pages/presentation.scss" as *;
@use "./pages/feed.scss" as *;
@use "./pages/temoignages.scss" as *;
//Components
@use "./pages/components/nav.scss" as *;
@use "./pages/components/mobile-nav.scss" as *;
@use "./pages/components/mobile-menu.scss" as *;
@use "./pages/components/loader.scss" as *;
@use "./pages/components/header.scss" as *;
@use "./pages/components/footer.scss" as *;
@use "./pages/components/subfooter.scss" as *;
