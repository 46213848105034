@use "./theme.scss" as *;

::selection {
  color: $light-gray;
  background: $gold;
}

* {
  box-sizing: border-box;
}

html.has-scroll-smooth {
  backface-visibility: hidden;
  transform: translateZ(0);
}

[data-load-container] {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
}

html,
body {
  font-family: -apple-system, Inter, sans-serif;
  --background: #0a0c1a;
  --foreground: #0a0c1a;
  background: $light-gray;
  color: $black;
  margin: 0;
  padding: 0;

  @media only screen and (min-width: $laptop-breakpoint) {
    overflow: hidden;
  }
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $dark-blue;
  font-weight: 400;
  margin: 0;
}

h1 {
  font-size: $xsmall-font-size;
  font-weight: lighter;
  opacity: 0.75;
  text-transform: uppercase;
  letter-spacing: $text-smaller-spacing;
  color: $light-gray2;
  padding-bottom: $text-big-spacing;
  // @media only screen and (max-width: $laptop-breakpoint) {
  //   font-size: $regular-font-size;
  // }
  @media only screen and (max-width: $landscape-mobile-breakpoint) {
    font-size: $smaller-font-size;
    letter-spacing: 1px;
    padding-bottom: $text-medium-spacing;
    font-weight: bolder;
  }
}

h2 {
  font-family: Bigilla;
  font-size: 5vw;
  // font-size: $wide-font-size;
  @media only screen and (max-width: $mobile-breakpoint) {
    font-size: 10vw;
    font-weight: bolder;
  }
  @media only screen and (min-width: $mobile-breakpoint) and (max-width: $tablet-breakpoint) {
    font-size: 10vw;
  }
  @media only screen and (min-width: $tablet-breakpoint) and (max-width: $laptop-breakpoint) {
    font-size: $large-font-size;
  }
  @media only screen and (min-width: $laptop-breakpoint) and (max-width: $desktop-breakpoint) {
    font-size: $large-font-size;
  }
  .script-font {
    font-family: WonderGardenScript;
    font-weight: lighter;
    font-size: 12vw;
    line-height: 0;
    text-transform: capitalize;
    color: $gold;
  }
}

h3 {
  @extend h2;
  font-size: 4.8vw;
  @media only screen and (max-width: $mobile-breakpoint) {
    font-size: 9vw;
    font-weight: bolder;
  }
  @media only screen and (max-width: $landscape-mobile-breakpoint) {
    font-size: 9vw;
    font-weight: bolder;
  }
  @media only screen and (min-width: $desktop-breakpoint) {
    font-size: $large-font-size;
  }
}

p {
  font-size: $small-font-size;
}

h2.section {
  font-size: $large-font-size;
}

p.subtitle {
  font-size: $xsmall-font-size;
  text-transform: uppercase;
  font-weight: lighter;
  letter-spacing: $text-smaller-spacing;
  color: $light-gray2;
  // padding-bottom: $text-base-spacing;
  @media only screen and (max-width: $laptop-breakpoint) {
    text-align: center;
  }
  @media only screen and (max-width: $laptop-breakpoint) {
    font-size: $smaller-font-size;
    padding-bottom: $text-small-spacing;
    letter-spacing: 1px;
  }
  @media only screen and (max-width: $landscape-mobile-breakpoint) {
    letter-spacing: 1px;
  }
}

a {
  color: $gold;
  svg {
    fill: $white;
  }
}

ul {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
  }
}

button {
  background-color: $gold;
  cursor: pointer;
  padding: 20px $text-medium-spacing;
  color: $white;
  border: none;
  text-transform: uppercase;
  letter-spacing: $text-smaller-spacing;
  border-radius: 1000px;
  font-size: $small-font-size;
  position: relative;
  overflow: hidden;
  &:focus {
    outline: 0;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    background: rgba(255, 255, 255, 0.5);
    width: 60px;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0.5;
    filter: blur(30px);
    transform: translateX(-100px) skewX(-15deg);
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    background: rgba(255, 255, 255, 0.353);
    width: 30px;
    height: 100%;
    left: 30px;
    top: 0;
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-100px) skewX(-15deg);
  }
  &:hover {
    color: $white;
    background-color: $gold-light;
    &:before {
      transform: translateX(400px) skewX(-15deg);
      opacity: 0.6;
      transition: 1s;
      background-color: transparent;
    }
    &:after {
      transform: translateX(400px) skewX(-15deg);
      opacity: 1;
      transition: 1s;
      background-color: transparent;
    }
  }
  &.secondary {
    background-color: transparent;
    border: 1px solid $gold;
    transition: 0.5s all ease;
    &:hover {
      color: $white;
      background-color: $gold;
    }
  }
  &.tertiary {
    background-color: $light-gray3;
    transition: 0.5s all ease;
    &:hover {
      color: $white;
      background-color: $gold;
    }
  }
  @media only screen and (max-width: $tablet-breakpoint) {
    padding: 15px $text-medium-spacing;
    font-size: $xsmall-font-size;
    margin-bottom: $text-small-spacing;
  }
  @media only screen and (max-width: $landscape-mobile-breakpoint) {
    width: 100%;
    letter-spacing: 1px;
  }
  &.small {
    padding: 10px $text-base-spacing;
    // font-size: $xsmall-font-size;
    letter-spacing: 0;
  }
  img {
    width: 16px;
    margin-right: $text-small-spacing;
  }
}

input {
  background-color: transparent;
  border: 0px;
  border-bottom: 1px solid #ffffff2c;
  font-size: $regular-font-size;
  padding: $text-base-spacing;
  color: $white;
  outline: 0;
}

.separator {
  width: 319px;
  padding-bottom: $text-large-spacing;
  @media only screen and (max-width: $tablet-breakpoint) {
    padding-bottom: 0;
  }
}

#separator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: $wide-spacing auto $small-spacing auto;
  @media only screen and (max-width: $tablet-breakpoint) {
    margin: $base-spacing auto $small-spacing auto;
  }
}

#separator2 {
  @extend #separator;
  margin: $big-spacing auto 30px auto;
  @media only screen and (max-width: $tablet-breakpoint) {
    margin: $base-spacing auto $small-spacing auto;
  }
  @media only screen and (max-width: $mobile-breakpoint) {
    margin: 4px auto $text-medium-spacing auto;
  }
}

#scroll-top {
  position: fixed;
  z-index: 99;
  top: $wide-spacing * 3;
  right: $text-large-spacing;
  @media only screen and (max-width: $tablet-breakpoint) {
    top: $wide-spacing * 2.25;
    right: $text-small-spacing;
  }
  display: none;
  &.active-scroll-top {
    display: block;
  }
  .scroll-top {
    mix-blend-mode: difference;
  }
}

.desktop-only {
  @media only screen and (max-width: $tablet-breakpoint) {
    display: none;
  }
}

.gold {
  color: $gold;
}

.desc {
  color: $light-gray2;
}

.test {
  display: block;
  padding: $wide-spacing;
  margin: $wide-spacing;
  height: 1000px;
}

.responsive {
  max-width: 100%;
  height: auto;
}

.required {
  text-align: left;
  color: #ffffff57;
  font-size: 12px;
  padding-left: 20px;
}

.hidden {
  display: none;
}

.circle {
  height: 252px;
  width: 252px;
  position: relative;
  top: -$text-medium-spacing;
  .background {
    fill: transparent;
    stroke: $gold;
    transition: all 900ms ease;
  }

  .foreground {
    fill: transparent;
    stroke-dasharray: 790;
    stroke-dashoffset: 790;
    stroke: $gold;
    transform-origin: 50% 50%;
    transform: rotate(-360deg);
    transition: all 1800ms ease;
  }

  &:hover {
    cursor: pointer;

    .background {
      stroke: transparent;
      // fill: $gold;
    }

    .foreground {
      stroke-dashoffset: 0;
      transform: rotate(-90deg);
    }

    .line2 {
      transform: rotate(0);
    }
  }
}

.c-scrollbar {
  width: 5px;
  position: absolute;
  right: 0;
  top: 0;
  height: 100vh;
  transform-origin: center right;
  transform: scaleX(1);
  transition: transform 0.2s linear;
}
.c-scrollbar:hover {
  transform: scaleX(1.3);
  cursor: -webkit-grab;
  cursor: grab;
}
.c-scrollbar_thumb {
  background-color: $light-gray2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-radius: $small-spacing;
}
.c-scrollbar_thumb:hover {
  cursor: pointer;
}

.scroll-top-link {
  cursor: pointer;
}
