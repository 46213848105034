@use "../../boilerplate/theme.scss" as *;
@use "../../boilerplate/variables.scss" as *;

.subfooter {
  background-color: #0a0c1a;
  display: flex;
  justify-content: center;
  .container {
    flex-direction: row;
    justify-content: space-between;
    @media only screen and (max-width: $mobile-breakpoint) {
      flex-direction: column;
      align-items: center;
    }
    padding: $text-small-spacing 0;
    p {
      color: $white;
      opacity: 0.5;
      font-size: $xsmall-font-size;
    }
  }
}
