@use "../boilerplate/theme.scss" as *;
@use "../boilerplate/variables.scss" as *;
@use "../boilerplate/mixin.scss" as *;

.presentation {
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 8em;
  @media only screen and (max-width: $tablet-breakpoint) {
    padding: 0;
  }
  @media only screen and (max-width: $mobile-breakpoint) {
    margin-bottom: 1em;
  }

  // &::before {
  //   content: "";
  //   padding: $base-spacing 0;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 60%;
  //   height: 100vh;
  //   background-color: $dark-blue;
  //   z-index: -2;
  // }

  // &::after {
  //   content: "";
  //   padding: $base-spacing 0;
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   width: 50%;
  //   height: 60%;
  //   transform: translate(0, 10%);
  //   background-color: $white;
  //   z-index: -1;
  // }

  #pinSection {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    padding: $small-spacing 0;
    @media only screen and (max-width: $mobile-breakpoint) {
      padding-top: 3em;
      padding-bottom: 0;
    }
    &::after {
      content: "";
      padding: $base-spacing 0;
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      height: 60%;
      transform: translate(0, 10%);
      background-color: $white;
      z-index: -1;
      @media only screen and (max-width: $tablet-breakpoint) {
        z-index: 0;
        width: 100%;
        top: auto;
      }
    }
    .presentation-left-background {
      display: flex;
      justify-content: center;
      position: absolute;
      top: 0;
      height: 100vh;
      width: 100%;
      z-index: -1;
      @media only screen and (max-width: $tablet-breakpoint) {
        z-index: 0;
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 60%;
        height: 100vh;
        background-color: $dark-blue;
        z-index: -2;
        @media only screen and (max-width: $tablet-breakpoint) {
          width: 100%;
        }
      }
    }
    .presentation-right-background {
      @extend .presentation-left-background;
      &::before {
        // margin: $text-base-spacing 0;
        height: 100%;
        z-index: -1;
        width: 50%;
        background-color: $white;
        right: 0;
        // top: 9%;
        left: auto;
      }
    }
  }
  .container {
    flex-direction: row;
    justify-content: space-between;
    @media only screen and (max-width: $tablet-breakpoint) {
      z-index: 1;
      flex-direction: column;
      align-items: center;
    }
    .presentation-img {
      margin-top: $text-large-spacing;
      @media only screen and (max-width: $landscape-mobile-breakpoint) {
        margin-top: 0;
      }
      img {
        @media only screen and (max-width: $landscape-mobile-breakpoint) {
          width: 80%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: auto;
        }
      }
      p {
        color: $white;
        text-align: right;
      }
    }
    .presentation-text {
      max-width: 572px;
      padding-top: $text-wide-spacing;
      @media only screen and (max-width: $landscape-mobile-breakpoint) {
        padding-top: 0;
        padding-left: $text-small-spacing;
        padding-right: $text-small-spacing;
      }
      .presentation-subtitle_wrapper {
        overflow: hidden;
        .firstRow {
          transform: translate(0, 100px);
          transition: $transition-duration $transition-bezier;
        }

        &.is-inview {
          .firstRow {
            transform: translate(0, 0);
          }
        }
      }
      h3 {
        @media only screen and (max-width: $landscape-mobile-breakpoint) {
          text-align: center;
        }
        .script-font {
          text-transform: none;
          transition: $transition-duration $transition-bezier;
          opacity: 0;
          @media only screen and (max-width: $tablet-breakpoint) {
            font-size: $mobile-script-font-size;
          }
        }
        &.is-inview .script-font {
          opacity: 1;
          transition-delay: 1s;
        }
      }
      p.subtitle {
        @media only screen and (min-width: $tablet-breakpoint) and (max-width: $desktop-breakpoint) {
          text-align: left;
        }
      }
      .presentation-text-desc {
        // margin-top: $text-wide-spacing;
        margin-left: $text-large-spacing;
        @media only screen and (max-width: $desktop-breakpoint) {
          margin-left: 0;
        }
        max-width: 372px;
        .presentation-text-desc_wrapper {
          overflow: hidden;
          display: inline-block;
          .firstRow {
            transform: translate(0, 250px);
            transition: $transition-duration $transition-bezier;
          }

          &.is-inview {
            .firstRow {
              transform: translate(0, 0);
            }
          }
        }
        p {
          color: $light-gray2;
          max-width: 508px;
          font-weight: 100;
          margin: auto;
          line-height: $text-base-spacing;
          @media only screen and (max-width: $landscape-mobile-breakpoint) {
            text-align: center;
          }
        }
      }
    }
    @include animateLetters("presentation-text", true);
  }
}
