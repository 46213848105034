@font-face {
  font-family: Bigilla;
  font-weight: bold;
  src: url("../fonts/Bigilla-Bold.otf") format("opentype");
}

@font-face {
  font-family: Bigilla;
  font-weight: normal;
  src: url("../fonts/Bigilla.otf") format("opentype");
}

@font-face {
  font-family: WonderGardenScript;
  font-weight: bold;
  src: url("../fonts/WonderGardenScript-Regular.woff2") format("woff2");
}
