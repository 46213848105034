@use "../boilerplate/theme.scss" as *;
@use "../boilerplate/variables.scss" as *;

.prestations {
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 8em;
  @media only screen and (max-width: $landscape-mobile-breakpoint) {
    margin-bottom: 0em;
  }

  .container {
    align-items: center;

    .accordion {
      // overflow: hidden;
      display: flex;
      flex-direction: row;
      @media only screen and (max-width: $landscape-mobile-breakpoint) {
        flex-direction: column;
      }
      .cell {
        position: relative;
        width: 25%;
        height: 400px;
        @media only screen and (max-width: $landscape-mobile-breakpoint) {
          width: 100%;
          border-bottom: 1px solid $white;
          min-height: 400px;
        }
        overflow: hidden;
        transition: width 0.5s ease;
        .prestations-cell-svg {
          align-items: center;
          display: flex;
          height: 80px;
          margin: 0 auto;
          width: 60px;
          padding-top: $text-medium-spacing;
          .play {
            display: block;
            margin: auto;
            img {
              cursor: pointer;
            }
          }
          svg {
            display: none;
            margin: 0 auto;
            overflow: hidden;
          }

          .wave {
            stroke-dasharray: 0 16 101 16;
            animation: moveTheWave 2400ms linear infinite;
          }

          @keyframes moveTheWave {
            0% {
              stroke-dashoffset: 0;
              transform: translate3d(0, 0, 0);
            }
            100% {
              stroke-dashoffset: -133;
              transform: translate3d(-90px, 0, 0);
            }
          }
        }
        .img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 610px;
          transition: all 0s ease;
          backface-visibility: hidden;
          background-size: cover;
          background-color: $dark-blue;
        }
        .title {
          font-family: Bigilla;
          font-size: $medium-plus-font-size;
          color: $white;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          @media only screen and (max-width: $landscape-mobile-breakpoint) {
            opacity: 0;
          }
          .clubSong-title_wrapper {
            overflow: hidden;
            display: inline-block;
            .firstRow {
              transform: translate(0, 100px);
              transition: $transition-duration $transition-bezier;
            }

            &.is-inview {
              .firstRow {
                transform: translate(0, 0);
              }
            }
          }
        }
        .txt {
          padding: 0 1em;
          margin: auto;
          opacity: 0;
          padding-top: $text-large-spacing;
          @media only screen and (max-width: $landscape-mobile-breakpoint) {
            opacity: 1;
            padding-top: 3em;
          }
          text-align: center;
          position: relative;
          z-index: 1;
          transition: all 0.15s ease;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          align-content: center;
          .txt-title {
            margin-top: 1em;
            font-family: Bigilla;
            font-size: $medium-plus-font-size;
            color: $white;
            padding-bottom: $text-base-spacing;
          }
          .txt-content {
            color: $white;
            top: $text-big-spacing;
            width: 90%;
            font-weight: 100;
            line-height: $text-base-spacing;
            text-align: justify;
            @media only screen and (max-width: $landscape-mobile-breakpoint) {
              text-align: center;
              width: 100%;
            }
          }
        }
        &:nth-child(1) .img {
          background-image: linear-gradient(
              to bottom,
              rgba(19, 21, 46, 0.75),
              rgba(19, 21, 46, 0.75)
            ),
            url("../images/prestations/mariage.png");
          background-repeat: no-repeat;
          background-size: cover;
          background-position: top;
        }
        &:nth-child(2) .img {
          background-image: linear-gradient(
              to bottom,
              rgba(19, 21, 46, 0.75),
              rgba(19, 21, 46, 0.75)
            ),
            url("../images/prestations/entreprise.png");
          background-repeat: no-repeat;
          background-size: cover;
          background-position: top;
        }
        &:nth-child(3) .img {
          background-image: linear-gradient(
              to bottom,
              rgba(19, 21, 46, 0.75),
              rgba(19, 21, 46, 0.75)
            ),
            url("../images/prestations/gala.png");
          background-repeat: no-repeat;
          background-size: cover;
          background-position: top;
        }
        &:nth-child(4) .img {
          background-image: linear-gradient(
              to bottom,
              rgba(19, 21, 46, 0.75),
              rgba(19, 21, 46, 0.75)
            ),
            url("../images/prestations/clubbing.png");
          background-repeat: no-repeat;
          background-size: cover;
          background-position: top;
        }
        &:nth-child(5) .img {
          background-image: linear-gradient(
              to bottom,
              rgba(19, 21, 46, 0.75),
              rgba(19, 21, 46, 0.75)
            ),
            url("../images/prestations/croisiere.png");
          background-repeat: no-repeat;
          background-size: cover;
          background-position: top;
        }
      }
    }

    .accordion {
      &:hover {
        .cell {
          width: 16.666%;
          @media only screen and (max-width: $landscape-mobile-breakpoint) {
            width: auto;
          }
          &:hover {
            width: 40%;
            @media only screen and (max-width: $landscape-mobile-breakpoint) {
              width: auto;
            }
            .txt {
              opacity: 1;
              transition-delay: 0.5s;
            }
            .title {
              opacity: 0;
              transition-delay: 0.5s;
            }
          }
        }
      }
    }
  }
}
