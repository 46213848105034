@use "../../boilerplate/theme.scss" as *;
@use "../../boilerplate/variables.scss" as *;

nav#mobile-navbar {
  display: none;
  align-items: center;
  position: fixed;
  @media only screen and (max-width: $tablet-breakpoint) {
    display: flex;
  }
  &.mobile-navbar-sticky {
    background-color: #ffffff5a;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-bottom: 1px solid #ffffff48;
    .mobile-nav-left,
    .mobile-nav-right {
      &::before {
        border: 1px solid $black;
      }
      img {
        filter: invert(1);
      }
    }
  }
  .mobile-nav-left,
  .mobile-nav-right {
    position: relative;
    &::before {
      position: absolute;
      margin: auto;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      content: "";
      display: inline-block;
      width: 15px;
      height: 15px;
      -moz-border-radius: 7.5px;
      -webkit-border-radius: 7.5px;
      border-radius: 1000px;
      border: 1px solid $white;
      padding: $text-small-spacing;
      opacity: 0.5;
    }
    img {
      opacity: 0.5;
    }
  }
  .mobile-nav-right {
    img {
      position: relative;
      top: 2px;
    }
  }
}
