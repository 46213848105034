@use "../../boilerplate/theme.scss" as *;
@use "../../boilerplate/variables.scss" as *;
@use "../../boilerplate/mixin.scss" as *;

$background-color_1: #ffffff45;

#loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  pointer-events: none;
  z-index: 9999999;
  background: $darker-blue;
  animation: slideIn 3s forwards 2s;
  @media only screen and (max-width: $mobile-breakpoint) {
    animation: slideIn 2s forwards 2s;
  }
  .loader {
    display: flex;
    flex-direction: column;
    gap: $text-big-spacing;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    .shiny {
      @media only screen and (max-width: $mobile-breakpoint) {
        width: 65px;
        height: 164px;
      }
    }
    p {
      text-align: center;
      color: $white-transparent;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: lighter;
    }
  }
}

@keyframes slideIn {
  0% {
    top: 0;
  }
  100% {
    top: -200%;
  }
}
