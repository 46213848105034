// Colors
$light-gray: #f6f6f6;
$gold: #e4be72;
$gold-light: #ffd072;
$dark-gold: #b08c44;
$dark-blue: #13152e;
$darker-blue: #090a17;
$black: #000000;
$white-transparent: #ffffff50;
$white: #ffffff;
$light-gray2: #828282;
$light-gray3: #bebebe;

// text spacing
$text-smaller-spacing: 4px;
$text-xsmall-spacing: 8px;
$text-small-spacing: 12px;
$text-base-spacing: 24px;
$text-medium-spacing: 36px;
$text-big-spacing: 60px;
$text-large-spacing: 72px;
$text-wide-spacing: 92px;
$text-extra-wide-spacing: 124px;

// Spacing
$smaller-spacing: 4px;
$xsmall-spacing: 92px;
$small-spacing: 124px;
$base-spacing: 156px;
$medium-spacing: 188px;
$big-spacing: 210px;
$large-spacing: 242px;
$wide-spacing: 274px;

// Font sizes
$smaller-font-size: 10px;
$smaller-plus-font-size: 12px;
$xsmall-font-size: 14px;
$small-font-size: 16px;
$regular-font-size: 20px;
$medium-font-size: 26px;
$medium-plus-font-size: 39px;
$large-minus-font-size: 52px;
$large-font-size: 65px;
$wide-font-size: 78px;

// Scripts font sizes
$desktop-script-font-size: 220px;
$script-font-size: 240px;
$tablet-script-font-size: 180px;
$tablet-minus-script-font-size: 150px;
$landscape-script-font-size: 130px;
$mobile-script-font-size: 95px;

// Responsive
$mobile-breakpoint: 425px;
$landscape-mobile-breakpoint: 574px;
$tablet-breakpoint: 800px;
$laptop-breakpoint: 1023px;
$desktop-breakpoint: 1320px;
