@use "../boilerplate/theme.scss" as *;
@use "../boilerplate/variables.scss" as *;
@use "../boilerplate/mixin.scss" as *;

.introduction {
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 4em;
  @media only screen and (max-width: $mobile-breakpoint) {
    margin-bottom: 1em;
  }
  .container {
    .content {
      display: flex;
      flex-direction: row;
      @media only screen and (max-width: $tablet-breakpoint) {
        flex-direction: column-reverse;
      }
    }
  }
  @include animateLetters("introduction-text", true);
  .introduction-text {
    position: relative;
    top: 7em;
    z-index: 2;
    @media only screen and (max-width: $tablet-breakpoint) {
      top: 0;
      padding: $text-small-spacing;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    @media only screen and (min-width: $tablet-breakpoint) and (max-width: $laptop-breakpoint) {
      top: 2em;
      padding-left: $text-base-spacing;
    }
    @media only screen and (max-width: $landscape-mobile-breakpoint) {
      top: 0;
      padding-left: 0;
      text-align: center;
    }
    .introduction-subtitle_wrapper {
      overflow: hidden;
      display: inline-block;
      .firstRow {
        transform: translate(0, 100px);
        transition: $transition-duration $transition-bezier;
        @media only screen and (max-width: $landscape-mobile-breakpoint) {
          padding: 0 $text-base-spacing;
        }
      }

      &.is-inview {
        .firstRow {
          transform: translate(0, 0);
        }
      }
    }

    h3 {
      position: relative;
      @media only screen and (max-width: $laptop-breakpoint) {
        text-align: center;
      }
      .introduction-script-font_wrapper {
        display: inline-block;
        position: relative;
        top: $text-small-spacing;
        .script-font {
          display: inline-block;
          z-index: 2;
          top: 0;
          transition: $transition-duration $transition-bezier;
          opacity: 0;
          @media only screen and (max-width: $tablet-breakpoint) {
            font-size: $mobile-script-font-size;
          }
        }
        &.is-inview .script-font {
          opacity: 1;
          transition-delay: 1s;
        }
      }
    }
    .text {
      line-height: $text-base-spacing;
      color: $light-gray2;
      padding-top: $text-medium-spacing;
      padding-left: $text-medium-spacing;
      text-align: justify;
      width: 450px;
      @media only screen and (max-width: $tablet-breakpoint) {
        text-align: center;
        padding-top: 0;
        padding-left: 0;
        width: 100%;
      }
      @media only screen and (min-width: $tablet-breakpoint) and (max-width: $laptop-breakpoint) {
        padding-top: $text-base-spacing;
        padding-left: $text-base-spacing;
      }
    }
  }
  .introduction-image_wrapper {
    overflow: hidden;
    position: relative;
    top: -4em;
    @media only screen and (max-width: $mobile-breakpoint) {
      top: -2em;
    }
    img {
      position: relative;
      //   padding-top: 6em;
      //   transform: scale($scale);
      transition: $base-duration $transition-bezier,
        transform $base-duration $transition-bezier;
      @media only screen and (min-width: $mobile-breakpoint) and (max-width: $laptop-breakpoint) {
        width: 323px;
        // left: 3em;
        display: block;
        margin: auto;
      }
      @media only screen and (max-width: $tablet-breakpoint) {
        width: 70%;
        display: block;
        margin: auto;
      }
    }
  }
}
