@use "../../boilerplate/theme.scss" as *;
@use "../../boilerplate/variables.scss" as *;

#mobile-menu {
  display: none;
  gap: $xsmall-spacing;
  padding-bottom: $text-base-spacing;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: $dark-blue;
  z-index: 99999;
  flex-direction: column;
  &.active {
    display: flex;
    ul {
      li {
        .mobile-menu-text_wrapper {
          .firstRow {
            transform: translate(0px, 0px);
          }
        }
      }
    }
  }
  .mobile-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: $text-small-spacing $text-medium-spacing $text-xsmall-spacing
      $text-medium-spacing;
    .mobile-menu-left,
    .mobile-menu-right {
      position: relative;
      &::before {
        position: absolute;
        margin: auto;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        content: "";
        display: inline-block;
        width: 15px;
        height: 15px;
        -moz-border-radius: 7.5px;
        -webkit-border-radius: 7.5px;
        border-radius: 1000px;
        border: 1px solid $white;
        padding: $text-small-spacing;
        opacity: 0.5;
      }
      img {
        opacity: 0.5;
      }
    }
    .mobile-menu-right {
      img {
        position: relative;
        top: 2px;
      }
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: $text-medium-spacing;
    align-items: center;
    li {
      text-align: center;
      .mobile-menu-text_wrapper {
        overflow: hidden;
        display: inline-block;
        .firstRow {
          transform: translate(0, 40px);
          transition: $transition-duration $transition-bezier;
          a {
            text-decoration: none;
            text-transform: uppercase;
            font-weight: bold;
            font-family: Bigilla;
            font-size: $medium-plus-font-size;
          }
        }
      }
    }
  }

  .mobile-menu-social {
    display: flex;
    flex-direction: row;
    gap: $text-medium-spacing;
  }
}
