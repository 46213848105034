@use "../boilerplate/theme.scss" as *;
@use "../boilerplate/variables.scss" as *;
@use "../boilerplate/mixin.scss" as *;

.temoignages {
  display: flex;
  justify-content: center;
  position: relative;
  padding: $base-spacing 0;
  @media only screen and (max-width: $tablet-breakpoint) {
    padding: 0;
    padding-bottom: $text-big-spacing;
  }
  .container {
    flex-direction: row;
    justify-content: flex-start;
    gap: $medium-spacing;
    @media only screen and (max-width: $laptop-breakpoint) {
      gap: $xsmall-spacing;
    }
    @media only screen and (max-width: $tablet-breakpoint) {
      z-index: 1;
      flex-direction: column;
      align-items: center;
      gap: 0;
    }
    @include animateLetters("temoignages-text", true);
    .temoignages-left-side {
      &::after {
        content: "";
        padding: $base-spacing 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 60%;
        transform: translate(0, 10%);
        background-color: $white;
        z-index: -1;
        @media only screen and (max-width: $tablet-breakpoint) {
          background-color: inherit;
          width: 100%;
        }
      }
      .temoignages-text {
        margin-top: $text-wide-spacing;
        @media only screen and (max-width: $mobile-breakpoint) {
          margin-top: 4em;
        }
      }
      h3 {
        @media only screen and (max-width: $tablet-breakpoint) {
          text-align: center;
          color: $white;
        }
        .temoignages-text_wrapper {
          width: 110%;
          margin-bottom: $text-small-spacing;
          @media only screen and (max-width: $mobile-breakpoint) {
            width: 100%;
          }
        }
        .script-font {
          text-transform: none;
          transition: $transition-duration $transition-bezier;
          opacity: 0;
          @media only screen and (max-width: $tablet-breakpoint) {
            font-size: $mobile-script-font-size;
          }
        }
        &.is-inview .script-font {
          opacity: 1;
          transition-delay: 1s;
        }
      }
      .temoignages-subtitle_wrapper {
        padding-bottom: $text-medium-spacing;
        overflow: hidden;
        .firstRow {
          transform: translate(0, 100px);
          transition: $transition-duration $transition-bezier;
          p {
            color: $light-gray2;
            text-transform: uppercase;
            font-weight: 500;
            letter-spacing: 1px;
            text-align: left;
            font-size: 14px;
            @media only screen and (max-width: $tablet-breakpoint) {
              text-align: center;
            }
          }
        }

        &.is-inview {
          .firstRow {
            transform: translate(0, 0);
          }
        }
      }
      .script-font {
        text-transform: none;
        @media only screen and (max-width: $tablet-breakpoint) {
          font-size: $mobile-script-font-size;
        }
      }
      .temoignages-slider-wrapper {
        position: relative;
        overflow: hidden;
        margin-top: $text-wide-spacing;
        padding: $text-small-spacing;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media only screen and (max-width: $mobile-breakpoint) {
          margin-top: $text-medium-spacing;
        }
        .slides-container {
          height: 100%;
          width: 400px;
          display: flex;
          flex-direction: row;
          overflow: scroll;
          scroll-behavior: smooth;
          list-style: none;
          margin: 0;
          padding: 0;
          &::-webkit-scrollbar,
          &::-webkit-scrollbar-track,
          &::-webkit-scrollbar-thumb {
            opacity: 0;
          }
          @media only screen and (max-width: $mobile-breakpoint) {
            height: auto;
          }
        }
        .slide-arrow {
          position: absolute;
          display: flex;
          top: -1em;
          bottom: 0;
          margin: auto;
          height: 2rem;
          background-color: inherit;
          color: $light-gray2;
          border: none;
          width: 2rem;
          font-size: 2rem;
          padding: 0;
          cursor: pointer;
          opacity: 0.5;
          transition: opacity 100ms;
          &:hover {
            opacity: 1;
          }
          &:focus {
            opacity: 1;
          }
        }
        #slide-arrow-prev {
          left: 0;
          @media only screen and (max-width: $landscape-mobile-breakpoint) {
            left: $text-big-spacing * 0.95;
          }
          padding-left: 0.5rem;
          border-radius: 10px;
        }
        #slide-arrow-next {
          right: 0;
          @media only screen and (max-width: $landscape-mobile-breakpoint) {
            right: $text-big-spacing * 0.95;
          }
          padding-left: 0.55rem;
          border-radius: 10px;
        }
        .slide {
          width: 100%;
          height: 100%;
          flex: 1 0 100%;
          padding-left: $text-large-spacing;
          @media only screen and (max-width: $tablet-breakpoint) {
            padding-left: inherit;
          }
          .slide-header {
            display: flex;
            flex-direction: row;
            gap: $text-small-spacing;
            @media only screen and (max-width: $tablet-breakpoint) {
              flex-direction: column;
              align-items: center;
              text-align: center;
            }
            .slide-avatar {
              width: 35px;
              height: 35px;
              border-radius: 100%;
              background-color: $light-gray;
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;
              color: $light-gray2;
            }
            .slide-text {
              .slide-name {
                font-size: $xsmall-font-size;
                color: $light-gray2;
                @media only screen and (max-width: $tablet-breakpoint) {
                  color: $white;
                }
              }
              .slide-stars {
                color: $gold;
              }
            }
          }
          .slide-content {
            width: 90%;
            font-weight: 100;
            color: $light-gray2;
            line-height: $text-base-spacing;
            @media only screen and (max-width: $tablet-breakpoint) {
              text-align: center;
              color: $white;
              width: 60%;
              margin: auto;
            }
          }
        }

        a {
          font-weight: 100;
          text-decoration: underline;
          margin-top: $text-small-spacing;
        }
      }
    }
    .temoignages-right-side {
      padding-left: $text-wide-spacing;
      padding-right: $text-base-spacing;
      @media only screen and (max-width: $desktop-breakpoint) {
        padding-left: $text-base-spacing;
      }
      &::before {
        content: "";
        padding: $base-spacing 0;
        position: absolute;
        top: 0;
        right: 0;
        width: 60%;
        height: 80%;
        background-color: $dark-blue;
        z-index: -2;
        background-image: url("../images/temoignages.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        // background-attachment: fixed;
        @media only screen and (max-width: $tablet-breakpoint) {
          width: 100%;
        }
        @media only screen and (max-width: $mobile-breakpoint) {
          width: 100%;
          padding: $small-spacing 0;
        }
      }

      .temoignages-right-side-title {
        color: $light-gray3;
        text-transform: uppercase;
        letter-spacing: 1px;
        text-align: left;
        margin-top: 111px;
        margin-bottom: $text-wide-spacing;
        font-weight: 500;
        font-size: 14px;
        @media only screen and (max-width: $tablet-breakpoint) {
          text-align: center;
          margin-bottom: $text-big-spacing;
        }
      }

      .temoignages-display-logo {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: $text-medium-spacing;
        gap: $text-base-spacing;
        @media only screen and (max-width: $tablet-breakpoint) {
          display: none;
        }
      }
      .temoignages-mobile-display-logo {
        display: none;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-bottom: $text-medium-spacing;
        text-align: center;
        gap: $text-medium-spacing;
        .temoignages-img-responsive {
          height: 50%;
          width: 50%;
        }
        @media only screen and (max-width: $tablet-breakpoint) {
          display: flex;
        }
      }
    }
  }
}
