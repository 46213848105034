@use "../../boilerplate/theme.scss" as *;
@use "../../boilerplate/variables.scss" as *;

@keyframes zoomIn {
  0% {
    transform: scale(1.15, 1.15);
  }
  100% {
    transform: scale(1, 1);
  }
}

header {
  display: flex;
  flex-direction: column;
  padding-top: $wide-spacing;
  align-items: center;
  position: relative;

  @media only screen and (max-width: $landscape-mobile-breakpoint) {
    height: 100vh;
    min-height: 380px;
    padding-top: 0;
  }

  @media only screen and (min-width: $landscape-mobile-breakpoint) and (max-width: $laptop-breakpoint) {
    height: 85vh;
    min-height: 380px;
    padding-top: $medium-spacing;
  }

  @media only screen and (min-width: $laptop-breakpoint) {
    height: 110vh;
    min-height: 46.875rem;
  }

  .container {
    @media only screen and (max-width: $landscape-mobile-breakpoint) {
      align-items: center;
      height: 100vh;
      padding: $text-small-spacing;
      .header-text {
        display: flex;
        flex-direction: column;
        height: 100vh;
        justify-content: flex-end;
        padding-bottom: $text-medium-spacing;
      }
    }
    @media only screen and (min-width: $landscape-mobile-breakpoint) and (max-width: $desktop-breakpoint) {
      padding-left: $text-small-spacing;
      align-items: flex-start;
    }
  }

  .header-background {
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    background-image: url("../images/bg-header.webp");
    background-color: #111121;
    background-repeat: no-repeat;
    position: absolute;
    background-size: cover;
    background-position: right center;
    transform: scale(1, 1);
    animation: zoomIn 3s 2s;
    @media only screen and (max-width: $landscape-mobile-breakpoint) {
      background-position: 90% -4em;
      height: 100vh;
      min-height: 380px;
      padding: $small-spacing $text-small-spacing $text-small-spacing
        $text-small-spacing;
    }
    @media only screen and (min-width: $laptop-breakpoint) and (max-width: $desktop-breakpoint) {
      background-position: 70% 100%;
    }
  }
  a {
    text-decoration: none;
  }
  button {
    overflow: hidden;
    opacity: 0;
    transition: $transition-duration $transition-bezier;
    margin-right: $text-small-spacing;
    z-index: 2;
    position: relative;
    &.is-inview {
      opacity: 1;
      transition-delay: 2s * 1.6;
    }
    img {
      position: relative;
      top: 2px;
      height: 16px;
    }
  }

  h1,
  h2 {
    color: $white;
    @media only screen and (max-width: $landscape-mobile-breakpoint) {
      text-align: center;
    }
    .header-text_wrapper {
      overflow: hidden;
      display: inline-block;
    }
    .header-script-font_wrapper {
      display: inline-block;
      z-index: 2;
      position: relative;
    }
  }

  h1 {
    .header-text_wrapper {
      .firstRow,
      .secondRow,
      .thirdRow {
        transform: translate(0, 30px);
        transition: $transition-duration $transition-bezier;
      }
    }

    &.is-inview .header-text_wrapper {
      .firstRow,
      .secondRow,
      .thirdRow {
        transform: translate(0, 0);
        transition-delay: 2s;
      }
    }
  }

  h2 {
    text-transform: uppercase;
    padding-left: $text-base-spacing;
    @media only screen and (max-width: $landscape-mobile-breakpoint) {
      padding-left: 0;
    }
    .script-font {
      display: inline-block;
      z-index: 2;
      padding-left: 8px;
      transition: $transition-duration $transition-bezier;
      opacity: 0;
      position: relative;
      top: $text-base-spacing;
      font-size: 14vw;
      @media only screen and (max-width: $mobile-breakpoint) {
        font-size: $landscape-script-font-size;
        top: 4px;
      }
      @media only screen and (min-width: $mobile-breakpoint) and (max-width: $tablet-breakpoint) {
        font-size: $tablet-minus-script-font-size;
        top: 8px;
      }
      @media only screen and (min-width: $tablet-breakpoint) and (max-width: $laptop-breakpoint) {
        font-size: $tablet-script-font-size;
      }
      @media only screen and (min-width: $laptop-breakpoint) and (max-width: $desktop-breakpoint) {
        font-size: $tablet-script-font-size;
      }
    }
    .header-text_wrapper {
      .prestige {
        color: $light-gray;
      }

      .firstRow,
      .secondRow,
      .thirdRow {
        .letter {
          display: inline-block;
          line-height: 0;
          transform: translate(0, 100px);
          transition: $transition-duration $transition-bezier;
        }
      }
    }

    &.is-inview .header-text_wrapper {
      .firstRow {
        .letter {
          @for $i from 1 through 10 {
            &:nth-child(#{$i}) {
              transform: translate(0, 0);
              transition-delay: 2.25s + $transition-delay-increment * ($i - 1);
            }
          }
        }
      }

      .secondRow,
      .thirdRow {
        .letter {
          @for $i from 1 through 20 {
            &:nth-child(#{$i}) {
              transform: translate(0, 0);
              transition-delay: 2.5 + $transition-delay-increment * ($i - 1);
            }
          }
        }
      }
    }
    // optionnal, add .header-script_wrapper in .pug file above .script-font to see
    &.is-inview .header-script_wrapper {
      transition-delay: 2s * 1.6;
      width: 100%;
    }

    &.is-inview .script-font {
      opacity: 1;
      transition-delay: 2s * 1.4;
    }
  }

  .side-text {
    position: absolute;
    right: -11em;
    bottom: 0;
    @media only screen and (max-width: $tablet-breakpoint) {
      display: none;
    }
    p {
      color: $white;
      opacity: 0.5;
      transform: rotate(90deg);
      text-transform: uppercase;
      font-size: $smaller-font-size;
      letter-spacing: 2px;
      &::after {
        content: "";
        width: 400px;
        height: 1px;
        background-color: $white;
        display: inline-block;
        vertical-align: middle;
        margin-left: $text-base-spacing;
      }
    }
  }
}
