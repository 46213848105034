::selection {
  color: #f6f6f6;
  background: #e4be72;
}

* {
  box-sizing: border-box;
}

html.has-scroll-smooth {
  backface-visibility: hidden;
  transform: translateZ(0);
}

[data-load-container] {
  width: 100vw;
  position: fixed;
  inset: 0;
}

html, body {
  --background: #0a0c1a;
  --foreground: #0a0c1a;
  color: #000;
  scroll-behavior: smooth;
  background: #f6f6f6;
  margin: 0;
  padding: 0;
  font-family: -apple-system, Inter, sans-serif;
}

@media only screen and (min-width: 1023px) {
  html, body {
    overflow: hidden;
  }
}

h1, h2, h3, h4, h5, h6 {
  color: #13152e;
  margin: 0;
  font-weight: 400;
}

h1 {
  opacity: .75;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: #828282;
  padding-bottom: 60px;
  font-size: 14px;
  font-weight: lighter;
}

@media only screen and (max-width: 574px) {
  h1 {
    letter-spacing: 1px;
    padding-bottom: 36px;
    font-size: 10px;
    font-weight: bolder;
  }
}

h2, h3 {
  font-family: Bigilla;
  font-size: 5vw;
}

@media only screen and (max-width: 425px) {
  h2, h3 {
    font-size: 10vw;
    font-weight: bolder;
  }
}

@media only screen and (min-width: 425px) and (max-width: 800px) {
  h2, h3 {
    font-size: 10vw;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1023px) {
  h2, h3 {
    font-size: 65px;
  }
}

@media only screen and (min-width: 1023px) and (max-width: 1320px) {
  h2, h3 {
    font-size: 65px;
  }
}

h2 .script-font, h3 .script-font {
  text-transform: capitalize;
  color: #e4be72;
  font-family: WonderGardenScript;
  font-size: 12vw;
  font-weight: lighter;
  line-height: 0;
}

h3 {
  font-size: 4.8vw;
}

@media only screen and (max-width: 425px) {
  h3 {
    font-size: 9vw;
    font-weight: bolder;
  }
}

@media only screen and (max-width: 574px) {
  h3 {
    font-size: 9vw;
    font-weight: bolder;
  }
}

@media only screen and (min-width: 1320px) {
  h3 {
    font-size: 65px;
  }
}

p {
  font-size: 16px;
}

h2.section, h3.section {
  font-size: 65px;
}

p.subtitle {
  text-transform: uppercase;
  letter-spacing: 4px;
  color: #828282;
  font-size: 14px;
  font-weight: lighter;
}

@media only screen and (max-width: 1023px) {
  p.subtitle {
    text-align: center;
    letter-spacing: 1px;
    padding-bottom: 12px;
    font-size: 10px;
  }
}

@media only screen and (max-width: 574px) {
  p.subtitle {
    letter-spacing: 1px;
  }
}

a {
  color: #e4be72;
}

a svg {
  fill: #fff;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style: none;
}

button {
  cursor: pointer;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 4px;
  background-color: #e4be72;
  border: none;
  border-radius: 1000px;
  padding: 20px 36px;
  font-size: 16px;
  position: relative;
  overflow: hidden;
}

button:focus {
  outline: 0;
}

button:before {
  content: "";
  opacity: .5;
  filter: blur(30px);
  background: #ffffff80;
  width: 60px;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100px)skewX(-15deg);
}

button:after {
  content: "";
  opacity: 0;
  filter: blur(5px);
  background: #ffffff5a;
  width: 30px;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 30px;
  transform: translateX(-100px)skewX(-15deg);
}

button:hover {
  color: #fff;
  background-color: #ffd072;
}

button:hover:before {
  opacity: .6;
  background-color: #0000;
  transition: all 1s;
  transform: translateX(400px)skewX(-15deg);
}

button:hover:after {
  opacity: 1;
  background-color: #0000;
  transition: all 1s;
  transform: translateX(400px)skewX(-15deg);
}

button.secondary {
  background-color: #0000;
  border: 1px solid #e4be72;
  transition: all .5s;
}

button.secondary:hover {
  color: #fff;
  background-color: #e4be72;
}

button.tertiary {
  background-color: #bebebe;
  transition: all .5s;
}

button.tertiary:hover {
  color: #fff;
  background-color: #e4be72;
}

@media only screen and (max-width: 800px) {
  button {
    margin-bottom: 12px;
    padding: 15px 36px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 574px) {
  button {
    letter-spacing: 1px;
    width: 100%;
  }
}

button.small {
  letter-spacing: 0;
  padding: 10px 24px;
}

button img {
  width: 16px;
  margin-right: 12px;
}

input {
  color: #fff;
  background-color: #0000;
  border: 0;
  border-bottom: 1px solid #ffffff2c;
  outline: 0;
  padding: 24px;
  font-size: 20px;
}

.separator {
  width: 319px;
  padding-bottom: 72px;
}

@media only screen and (max-width: 800px) {
  .separator {
    padding-bottom: 0;
  }
}

#separator, #separator2 {
  justify-content: center;
  align-items: center;
  margin: 274px auto 124px;
  display: flex;
}

@media only screen and (max-width: 800px) {
  #separator, #separator2 {
    margin: 156px auto 124px;
  }
}

#separator2 {
  margin: 210px auto 30px;
}

@media only screen and (max-width: 800px) {
  #separator2 {
    margin: 156px auto 124px;
  }
}

@media only screen and (max-width: 425px) {
  #separator2 {
    margin: 4px auto 36px;
  }
}

#scroll-top {
  z-index: 99;
  display: none;
  position: fixed;
  top: 822px;
  right: 72px;
}

@media only screen and (max-width: 800px) {
  #scroll-top {
    top: 616.5px;
    right: 12px;
  }
}

#scroll-top.active-scroll-top {
  display: block;
}

#scroll-top .scroll-top {
  mix-blend-mode: difference;
}

@media only screen and (max-width: 800px) {
  .desktop-only {
    display: none;
  }
}

.gold {
  color: #e4be72;
}

.desc {
  color: #828282;
}

.test {
  height: 1000px;
  margin: 274px;
  padding: 274px;
  display: block;
}

.responsive {
  max-width: 100%;
  height: auto;
}

.required {
  text-align: left;
  color: #ffffff57;
  padding-left: 20px;
  font-size: 12px;
}

.hidden {
  display: none;
}

.circle {
  width: 252px;
  height: 252px;
  position: relative;
  top: -36px;
}

.circle .background {
  fill: #0000;
  stroke: #e4be72;
  transition: all .9s;
}

.circle .foreground {
  fill: #0000;
  stroke-dasharray: 790;
  stroke-dashoffset: 790px;
  stroke: #e4be72;
  transform-origin: 50%;
  transition: all 1.8s;
  transform: rotate(-360deg);
}

.circle:hover {
  cursor: pointer;
}

.circle:hover .background {
  stroke: #0000;
}

.circle:hover .foreground {
  stroke-dashoffset: 0;
  transform: rotate(-90deg);
}

.circle:hover .line2 {
  transform: rotate(0);
}

.c-scrollbar {
  transform-origin: 100%;
  width: 5px;
  height: 100vh;
  transition: transform .2s linear;
  position: absolute;
  top: 0;
  right: 0;
  transform: scaleX(1);
}

.c-scrollbar:hover {
  cursor: -webkit-grab;
  cursor: grab;
  transform: scaleX(1.3);
}

.c-scrollbar_thumb {
  background-color: #828282;
  border-radius: 124px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.c-scrollbar_thumb:hover, .scroll-top-link {
  cursor: pointer;
}

@font-face {
  font-family: Bigilla;
  font-weight: bold;
  src: url("Bigilla-Bold.9d4836d6.otf") format("opentype");
}

@font-face {
  font-family: Bigilla;
  font-weight: normal;
  src: url("Bigilla.9455b765.otf") format("opentype");
}

@font-face {
  font-family: WonderGardenScript;
  font-weight: bold;
  src: url("WonderGardenScript-Regular.de8ae705.woff2") format("woff2");
}

.scroll {
  box-sizing: border-box;
  width: 100vw;
  overflow: hidden;
}

.scroll.has-scroll-smooth {
  position: relative;
}

.container {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 1280px;
  display: flex;
}

.introduction {
  justify-content: center;
  margin-bottom: 4em;
  display: flex;
  position: relative;
}

@media only screen and (max-width: 425px) {
  .introduction {
    margin-bottom: 1em;
  }
}

.introduction .container .content {
  flex-direction: row;
  display: flex;
}

@media only screen and (max-width: 800px) {
  .introduction .container .content {
    flex-direction: column-reverse;
  }
}

.introduction .introduction-text h3 {
  max-width: 737px;
}

.introduction .introduction-text h3 .introduction-text_wrapper {
  display: inline-block;
  overflow: hidden;
}

.introduction .introduction-text h3 .introduction-text_wrapper .firstRow .letter, .introduction .introduction-text h3 .introduction-text_wrapper .secondRow .letter, .introduction .introduction-text h3 .introduction-text_wrapper .thirdRow .letter {
  line-height: 0;
  transition: all 1.4s cubic-bezier(.215, .61, .355, 1);
  display: inline-block;
  transform: translate(0, 100px);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:first-child {
  transition-delay: 50ms;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(2) {
  transition-delay: 75ms;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(3) {
  transition-delay: .1s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(4) {
  transition-delay: .125s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(5) {
  transition-delay: .15s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(6) {
  transition-delay: .175s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(7) {
  transition-delay: .2s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(8) {
  transition-delay: .225s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(9) {
  transition-delay: .25s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(10) {
  transition-delay: .275s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(11) {
  transition-delay: .3s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(12) {
  transition-delay: .325s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(13) {
  transition-delay: .35s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(14) {
  transition-delay: .375s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(15) {
  transition-delay: .4s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(16) {
  transition-delay: .425s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(17) {
  transition-delay: .45s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(18) {
  transition-delay: .475s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(19) {
  transition-delay: .5s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(20) {
  transition-delay: .525s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(21) {
  transition-delay: .55s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(22) {
  transition-delay: .575s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(23) {
  transition-delay: .6s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(24) {
  transition-delay: .625s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(25) {
  transition-delay: .65s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(26) {
  transition-delay: .675s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(27) {
  transition-delay: .7s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(28) {
  transition-delay: .725s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(29) {
  transition-delay: .75s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(30) {
  transition-delay: .775s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(31) {
  transition-delay: .8s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(32) {
  transition-delay: .825s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(33) {
  transition-delay: .85s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(34) {
  transition-delay: .875s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(35) {
  transition-delay: .9s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(36) {
  transition-delay: .925s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(37) {
  transition-delay: .95s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(38) {
  transition-delay: .975s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(39) {
  transition-delay: 1s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(40) {
  transition-delay: 1.025s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(41) {
  transition-delay: 1.05s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(42) {
  transition-delay: 1.075s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(43) {
  transition-delay: 1.1s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(44) {
  transition-delay: 1.125s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(45) {
  transition-delay: 1.15s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(46) {
  transition-delay: 1.175s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(47) {
  transition-delay: 1.2s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(48) {
  transition-delay: 1.225s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(49) {
  transition-delay: 1.25s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(50) {
  transition-delay: 1.275s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(51) {
  transition-delay: 1.3s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(52) {
  transition-delay: 1.325s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(53) {
  transition-delay: 1.35s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(54) {
  transition-delay: 1.375s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(55) {
  transition-delay: 1.4s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(56) {
  transition-delay: 1.425s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(57) {
  transition-delay: 1.45s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(58) {
  transition-delay: 1.475s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(59) {
  transition-delay: 1.5s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(60) {
  transition-delay: 1.525s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(61) {
  transition-delay: 1.55s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(62) {
  transition-delay: 1.575s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(63) {
  transition-delay: 1.6s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(64) {
  transition-delay: 1.625s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(65) {
  transition-delay: 1.65s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(66) {
  transition-delay: 1.675s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(67) {
  transition-delay: 1.7s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(68) {
  transition-delay: 1.725s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(69) {
  transition-delay: 1.75s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(70) {
  transition-delay: 1.775s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(71) {
  transition-delay: 1.8s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(72) {
  transition-delay: 1.825s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(73) {
  transition-delay: 1.85s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(74) {
  transition-delay: 1.875s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(75) {
  transition-delay: 1.9s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(76) {
  transition-delay: 1.925s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(77) {
  transition-delay: 1.95s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(78) {
  transition-delay: 1.975s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(79) {
  transition-delay: 2s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(80) {
  transition-delay: 2.025s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(81) {
  transition-delay: 2.05s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(82) {
  transition-delay: 2.075s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(83) {
  transition-delay: 2.1s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(84) {
  transition-delay: 2.125s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(85) {
  transition-delay: 2.15s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(86) {
  transition-delay: 2.175s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(87) {
  transition-delay: 2.2s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(88) {
  transition-delay: 2.225s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(89) {
  transition-delay: 2.25s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(90) {
  transition-delay: 2.275s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(91) {
  transition-delay: 2.3s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(92) {
  transition-delay: 2.325s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(93) {
  transition-delay: 2.35s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(94) {
  transition-delay: 2.375s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(95) {
  transition-delay: 2.4s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(96) {
  transition-delay: 2.425s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(97) {
  transition-delay: 2.45s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(98) {
  transition-delay: 2.475s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(99) {
  transition-delay: 2.5s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .firstRow .letter:nth-child(100) {
  transition-delay: 2.525s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:first-child {
  transition-delay: .3s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(2) {
  transition-delay: .325s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(3) {
  transition-delay: .35s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(4) {
  transition-delay: .375s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(5) {
  transition-delay: .4s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(6) {
  transition-delay: .425s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(7) {
  transition-delay: .45s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(8) {
  transition-delay: .475s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(9) {
  transition-delay: .5s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(10) {
  transition-delay: .525s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(11) {
  transition-delay: .55s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(12) {
  transition-delay: .575s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(13) {
  transition-delay: .6s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(14) {
  transition-delay: .625s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(15) {
  transition-delay: .65s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(16) {
  transition-delay: .675s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(17) {
  transition-delay: .7s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(18) {
  transition-delay: .725s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(19) {
  transition-delay: .75s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(20) {
  transition-delay: .775s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(21) {
  transition-delay: .8s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(22) {
  transition-delay: .825s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(23) {
  transition-delay: .85s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(24) {
  transition-delay: .875s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(25) {
  transition-delay: .9s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(26) {
  transition-delay: .925s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(27) {
  transition-delay: .95s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(28) {
  transition-delay: .975s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(29) {
  transition-delay: 1s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(30) {
  transition-delay: 1.025s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(31) {
  transition-delay: 1.05s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(32) {
  transition-delay: 1.075s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(33) {
  transition-delay: 1.1s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(34) {
  transition-delay: 1.125s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(35) {
  transition-delay: 1.15s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(36) {
  transition-delay: 1.175s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(37) {
  transition-delay: 1.2s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(38) {
  transition-delay: 1.225s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(39) {
  transition-delay: 1.25s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(40) {
  transition-delay: 1.275s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(41) {
  transition-delay: 1.3s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(42) {
  transition-delay: 1.325s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(43) {
  transition-delay: 1.35s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(44) {
  transition-delay: 1.375s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(45) {
  transition-delay: 1.4s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(46) {
  transition-delay: 1.425s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(47) {
  transition-delay: 1.45s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(48) {
  transition-delay: 1.475s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(49) {
  transition-delay: 1.5s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(50) {
  transition-delay: 1.525s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(51) {
  transition-delay: 1.55s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(52) {
  transition-delay: 1.575s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(53) {
  transition-delay: 1.6s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(54) {
  transition-delay: 1.625s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(55) {
  transition-delay: 1.65s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(56) {
  transition-delay: 1.675s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(57) {
  transition-delay: 1.7s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(58) {
  transition-delay: 1.725s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(59) {
  transition-delay: 1.75s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(60) {
  transition-delay: 1.775s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(61) {
  transition-delay: 1.8s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(62) {
  transition-delay: 1.825s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(63) {
  transition-delay: 1.85s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(64) {
  transition-delay: 1.875s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(65) {
  transition-delay: 1.9s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(66) {
  transition-delay: 1.925s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(67) {
  transition-delay: 1.95s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(68) {
  transition-delay: 1.975s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(69) {
  transition-delay: 2s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(70) {
  transition-delay: 2.025s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(71) {
  transition-delay: 2.05s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(72) {
  transition-delay: 2.075s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(73) {
  transition-delay: 2.1s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(74) {
  transition-delay: 2.125s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(75) {
  transition-delay: 2.15s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(76) {
  transition-delay: 2.175s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(77) {
  transition-delay: 2.2s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(78) {
  transition-delay: 2.225s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(79) {
  transition-delay: 2.25s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(80) {
  transition-delay: 2.275s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(81) {
  transition-delay: 2.3s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(82) {
  transition-delay: 2.325s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(83) {
  transition-delay: 2.35s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(84) {
  transition-delay: 2.375s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(85) {
  transition-delay: 2.4s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(86) {
  transition-delay: 2.425s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(87) {
  transition-delay: 2.45s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(88) {
  transition-delay: 2.475s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(89) {
  transition-delay: 2.5s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(90) {
  transition-delay: 2.525s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(91) {
  transition-delay: 2.55s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(92) {
  transition-delay: 2.575s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(93) {
  transition-delay: 2.6s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(94) {
  transition-delay: 2.625s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(95) {
  transition-delay: 2.65s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(96) {
  transition-delay: 2.675s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(97) {
  transition-delay: 2.7s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(98) {
  transition-delay: 2.725s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(99) {
  transition-delay: 2.75s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(100) {
  transition-delay: 2.775s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(101) {
  transition-delay: 2.8s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(102) {
  transition-delay: 2.825s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(103) {
  transition-delay: 2.85s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(104) {
  transition-delay: 2.875s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(105) {
  transition-delay: 2.9s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(106) {
  transition-delay: 2.925s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(107) {
  transition-delay: 2.95s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(108) {
  transition-delay: 2.975s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(109) {
  transition-delay: 3s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(110) {
  transition-delay: 3.025s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(111) {
  transition-delay: 3.05s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(112) {
  transition-delay: 3.075s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(113) {
  transition-delay: 3.1s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(114) {
  transition-delay: 3.125s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(115) {
  transition-delay: 3.15s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(116) {
  transition-delay: 3.175s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(117) {
  transition-delay: 3.2s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(118) {
  transition-delay: 3.225s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(119) {
  transition-delay: 3.25s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(120) {
  transition-delay: 3.275s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(121) {
  transition-delay: 3.3s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(122) {
  transition-delay: 3.325s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(123) {
  transition-delay: 3.35s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(124) {
  transition-delay: 3.375s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(125) {
  transition-delay: 3.4s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(126) {
  transition-delay: 3.425s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(127) {
  transition-delay: 3.45s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(128) {
  transition-delay: 3.475s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(129) {
  transition-delay: 3.5s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(130) {
  transition-delay: 3.525s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(131) {
  transition-delay: 3.55s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(132) {
  transition-delay: 3.575s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(133) {
  transition-delay: 3.6s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(134) {
  transition-delay: 3.625s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(135) {
  transition-delay: 3.65s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(136) {
  transition-delay: 3.675s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(137) {
  transition-delay: 3.7s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(138) {
  transition-delay: 3.725s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(139) {
  transition-delay: 3.75s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(140) {
  transition-delay: 3.775s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(141) {
  transition-delay: 3.8s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(142) {
  transition-delay: 3.825s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(143) {
  transition-delay: 3.85s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(144) {
  transition-delay: 3.875s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(145) {
  transition-delay: 3.9s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(146) {
  transition-delay: 3.925s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(147) {
  transition-delay: 3.95s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(148) {
  transition-delay: 3.975s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(149) {
  transition-delay: 4s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(150) {
  transition-delay: 4.025s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(151) {
  transition-delay: 4.05s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(152) {
  transition-delay: 4.075s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(153) {
  transition-delay: 4.1s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(154) {
  transition-delay: 4.125s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(155) {
  transition-delay: 4.15s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(156) {
  transition-delay: 4.175s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(157) {
  transition-delay: 4.2s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(158) {
  transition-delay: 4.225s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(159) {
  transition-delay: 4.25s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(160) {
  transition-delay: 4.275s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(161) {
  transition-delay: 4.3s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(162) {
  transition-delay: 4.325s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(163) {
  transition-delay: 4.35s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(164) {
  transition-delay: 4.375s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(165) {
  transition-delay: 4.4s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(166) {
  transition-delay: 4.425s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(167) {
  transition-delay: 4.45s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(168) {
  transition-delay: 4.475s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(169) {
  transition-delay: 4.5s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(170) {
  transition-delay: 4.525s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(171) {
  transition-delay: 4.55s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(172) {
  transition-delay: 4.575s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(173) {
  transition-delay: 4.6s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(174) {
  transition-delay: 4.625s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(175) {
  transition-delay: 4.65s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(176) {
  transition-delay: 4.675s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(177) {
  transition-delay: 4.7s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(178) {
  transition-delay: 4.725s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(179) {
  transition-delay: 4.75s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(180) {
  transition-delay: 4.775s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(181) {
  transition-delay: 4.8s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(182) {
  transition-delay: 4.825s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(183) {
  transition-delay: 4.85s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(184) {
  transition-delay: 4.875s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(185) {
  transition-delay: 4.9s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(186) {
  transition-delay: 4.925s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(187) {
  transition-delay: 4.95s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(188) {
  transition-delay: 4.975s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(189) {
  transition-delay: 5s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(190) {
  transition-delay: 5.025s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(191) {
  transition-delay: 5.05s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(192) {
  transition-delay: 5.075s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(193) {
  transition-delay: 5.1s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(194) {
  transition-delay: 5.125s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(195) {
  transition-delay: 5.15s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(196) {
  transition-delay: 5.175s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(197) {
  transition-delay: 5.2s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(198) {
  transition-delay: 5.225s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(199) {
  transition-delay: 5.25s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .secondRow .letter:nth-child(200) {
  transition-delay: 5.275s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:first-child {
  transition-delay: .5s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(2) {
  transition-delay: .525s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(3) {
  transition-delay: .55s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(4) {
  transition-delay: .575s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(5) {
  transition-delay: .6s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(6) {
  transition-delay: .625s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(7) {
  transition-delay: .65s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(8) {
  transition-delay: .675s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(9) {
  transition-delay: .7s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(10) {
  transition-delay: .725s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(11) {
  transition-delay: .75s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(12) {
  transition-delay: .775s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(13) {
  transition-delay: .8s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(14) {
  transition-delay: .825s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(15) {
  transition-delay: .85s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(16) {
  transition-delay: .875s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(17) {
  transition-delay: .9s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(18) {
  transition-delay: .925s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(19) {
  transition-delay: .95s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(20) {
  transition-delay: .975s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(21) {
  transition-delay: 1s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(22) {
  transition-delay: 1.025s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(23) {
  transition-delay: 1.05s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(24) {
  transition-delay: 1.075s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(25) {
  transition-delay: 1.1s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(26) {
  transition-delay: 1.125s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(27) {
  transition-delay: 1.15s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(28) {
  transition-delay: 1.175s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(29) {
  transition-delay: 1.2s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(30) {
  transition-delay: 1.225s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(31) {
  transition-delay: 1.25s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(32) {
  transition-delay: 1.275s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(33) {
  transition-delay: 1.3s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(34) {
  transition-delay: 1.325s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(35) {
  transition-delay: 1.35s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(36) {
  transition-delay: 1.375s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(37) {
  transition-delay: 1.4s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(38) {
  transition-delay: 1.425s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(39) {
  transition-delay: 1.45s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(40) {
  transition-delay: 1.475s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(41) {
  transition-delay: 1.5s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(42) {
  transition-delay: 1.525s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(43) {
  transition-delay: 1.55s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(44) {
  transition-delay: 1.575s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(45) {
  transition-delay: 1.6s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(46) {
  transition-delay: 1.625s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(47) {
  transition-delay: 1.65s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(48) {
  transition-delay: 1.675s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(49) {
  transition-delay: 1.7s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(50) {
  transition-delay: 1.725s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(51) {
  transition-delay: 1.75s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(52) {
  transition-delay: 1.775s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(53) {
  transition-delay: 1.8s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(54) {
  transition-delay: 1.825s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(55) {
  transition-delay: 1.85s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(56) {
  transition-delay: 1.875s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(57) {
  transition-delay: 1.9s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(58) {
  transition-delay: 1.925s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(59) {
  transition-delay: 1.95s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(60) {
  transition-delay: 1.975s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(61) {
  transition-delay: 2s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(62) {
  transition-delay: 2.025s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(63) {
  transition-delay: 2.05s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(64) {
  transition-delay: 2.075s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(65) {
  transition-delay: 2.1s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(66) {
  transition-delay: 2.125s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(67) {
  transition-delay: 2.15s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(68) {
  transition-delay: 2.175s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(69) {
  transition-delay: 2.2s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(70) {
  transition-delay: 2.225s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(71) {
  transition-delay: 2.25s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(72) {
  transition-delay: 2.275s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(73) {
  transition-delay: 2.3s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(74) {
  transition-delay: 2.325s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(75) {
  transition-delay: 2.35s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(76) {
  transition-delay: 2.375s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(77) {
  transition-delay: 2.4s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(78) {
  transition-delay: 2.425s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(79) {
  transition-delay: 2.45s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(80) {
  transition-delay: 2.475s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(81) {
  transition-delay: 2.5s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(82) {
  transition-delay: 2.525s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(83) {
  transition-delay: 2.55s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(84) {
  transition-delay: 2.575s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(85) {
  transition-delay: 2.6s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(86) {
  transition-delay: 2.625s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(87) {
  transition-delay: 2.65s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(88) {
  transition-delay: 2.675s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(89) {
  transition-delay: 2.7s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(90) {
  transition-delay: 2.725s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(91) {
  transition-delay: 2.75s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(92) {
  transition-delay: 2.775s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(93) {
  transition-delay: 2.8s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(94) {
  transition-delay: 2.825s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(95) {
  transition-delay: 2.85s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(96) {
  transition-delay: 2.875s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(97) {
  transition-delay: 2.9s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(98) {
  transition-delay: 2.925s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(99) {
  transition-delay: 2.95s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(100) {
  transition-delay: 2.975s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(101) {
  transition-delay: 3s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(102) {
  transition-delay: 3.025s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(103) {
  transition-delay: 3.05s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(104) {
  transition-delay: 3.075s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(105) {
  transition-delay: 3.1s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(106) {
  transition-delay: 3.125s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(107) {
  transition-delay: 3.15s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(108) {
  transition-delay: 3.175s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(109) {
  transition-delay: 3.2s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(110) {
  transition-delay: 3.225s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(111) {
  transition-delay: 3.25s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(112) {
  transition-delay: 3.275s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(113) {
  transition-delay: 3.3s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(114) {
  transition-delay: 3.325s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(115) {
  transition-delay: 3.35s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(116) {
  transition-delay: 3.375s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(117) {
  transition-delay: 3.4s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(118) {
  transition-delay: 3.425s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(119) {
  transition-delay: 3.45s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(120) {
  transition-delay: 3.475s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(121) {
  transition-delay: 3.5s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(122) {
  transition-delay: 3.525s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(123) {
  transition-delay: 3.55s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(124) {
  transition-delay: 3.575s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(125) {
  transition-delay: 3.6s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(126) {
  transition-delay: 3.625s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(127) {
  transition-delay: 3.65s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(128) {
  transition-delay: 3.675s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(129) {
  transition-delay: 3.7s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(130) {
  transition-delay: 3.725s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(131) {
  transition-delay: 3.75s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(132) {
  transition-delay: 3.775s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(133) {
  transition-delay: 3.8s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(134) {
  transition-delay: 3.825s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(135) {
  transition-delay: 3.85s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(136) {
  transition-delay: 3.875s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(137) {
  transition-delay: 3.9s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(138) {
  transition-delay: 3.925s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(139) {
  transition-delay: 3.95s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(140) {
  transition-delay: 3.975s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(141) {
  transition-delay: 4s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(142) {
  transition-delay: 4.025s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(143) {
  transition-delay: 4.05s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(144) {
  transition-delay: 4.075s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(145) {
  transition-delay: 4.1s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(146) {
  transition-delay: 4.125s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(147) {
  transition-delay: 4.15s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(148) {
  transition-delay: 4.175s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(149) {
  transition-delay: 4.2s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(150) {
  transition-delay: 4.225s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(151) {
  transition-delay: 4.25s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(152) {
  transition-delay: 4.275s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(153) {
  transition-delay: 4.3s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(154) {
  transition-delay: 4.325s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(155) {
  transition-delay: 4.35s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(156) {
  transition-delay: 4.375s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(157) {
  transition-delay: 4.4s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(158) {
  transition-delay: 4.425s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(159) {
  transition-delay: 4.45s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(160) {
  transition-delay: 4.475s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(161) {
  transition-delay: 4.5s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(162) {
  transition-delay: 4.525s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(163) {
  transition-delay: 4.55s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(164) {
  transition-delay: 4.575s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(165) {
  transition-delay: 4.6s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(166) {
  transition-delay: 4.625s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(167) {
  transition-delay: 4.65s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(168) {
  transition-delay: 4.675s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(169) {
  transition-delay: 4.7s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(170) {
  transition-delay: 4.725s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(171) {
  transition-delay: 4.75s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(172) {
  transition-delay: 4.775s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(173) {
  transition-delay: 4.8s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(174) {
  transition-delay: 4.825s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(175) {
  transition-delay: 4.85s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(176) {
  transition-delay: 4.875s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(177) {
  transition-delay: 4.9s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(178) {
  transition-delay: 4.925s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(179) {
  transition-delay: 4.95s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(180) {
  transition-delay: 4.975s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(181) {
  transition-delay: 5s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(182) {
  transition-delay: 5.025s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(183) {
  transition-delay: 5.05s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(184) {
  transition-delay: 5.075s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(185) {
  transition-delay: 5.1s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(186) {
  transition-delay: 5.125s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(187) {
  transition-delay: 5.15s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(188) {
  transition-delay: 5.175s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(189) {
  transition-delay: 5.2s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(190) {
  transition-delay: 5.225s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(191) {
  transition-delay: 5.25s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(192) {
  transition-delay: 5.275s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(193) {
  transition-delay: 5.3s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(194) {
  transition-delay: 5.325s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(195) {
  transition-delay: 5.35s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(196) {
  transition-delay: 5.375s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(197) {
  transition-delay: 5.4s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(198) {
  transition-delay: 5.425s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(199) {
  transition-delay: 5.45s;
  transform: translate(0);
}

.introduction .introduction-text h3.is-inview .introduction-text_wrapper .thirdRow .letter:nth-child(200) {
  transition-delay: 5.475s;
  transform: translate(0);
}

.introduction .introduction-text {
  z-index: 2;
  position: relative;
  top: 7em;
}

@media only screen and (max-width: 800px) {
  .introduction .introduction-text {
    flex-direction: column;
    align-items: center;
    padding: 12px;
    display: flex;
    top: 0;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1023px) {
  .introduction .introduction-text {
    padding-left: 24px;
    top: 2em;
  }
}

@media only screen and (max-width: 574px) {
  .introduction .introduction-text {
    text-align: center;
    padding-left: 0;
    top: 0;
  }
}

.introduction .introduction-text .introduction-subtitle_wrapper {
  display: inline-block;
  overflow: hidden;
}

.introduction .introduction-text .introduction-subtitle_wrapper .firstRow {
  transition: all 1.4s cubic-bezier(.215, .61, .355, 1);
  transform: translate(0, 100px);
}

@media only screen and (max-width: 574px) {
  .introduction .introduction-text .introduction-subtitle_wrapper .firstRow {
    padding: 0 24px;
  }
}

.introduction .introduction-text .introduction-subtitle_wrapper.is-inview .firstRow {
  transform: translate(0);
}

.introduction .introduction-text h3 {
  position: relative;
}

@media only screen and (max-width: 1023px) {
  .introduction .introduction-text h3 {
    text-align: center;
  }
}

.introduction .introduction-text h3 .introduction-script-font_wrapper {
  display: inline-block;
  position: relative;
  top: 12px;
}

.introduction .introduction-text h3 .introduction-script-font_wrapper .script-font {
  z-index: 2;
  opacity: 0;
  transition: all 1.4s cubic-bezier(.215, .61, .355, 1);
  display: inline-block;
  top: 0;
}

@media only screen and (max-width: 800px) {
  .introduction .introduction-text h3 .introduction-script-font_wrapper .script-font {
    font-size: 95px;
  }
}

.introduction .introduction-text h3 .introduction-script-font_wrapper.is-inview .script-font {
  opacity: 1;
  transition-delay: 1s;
}

.introduction .introduction-text .text {
  color: #828282;
  text-align: justify;
  width: 450px;
  padding-top: 36px;
  padding-left: 36px;
  line-height: 24px;
}

@media only screen and (max-width: 800px) {
  .introduction .introduction-text .text {
    text-align: center;
    width: 100%;
    padding-top: 0;
    padding-left: 0;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1023px) {
  .introduction .introduction-text .text {
    padding-top: 24px;
    padding-left: 24px;
  }
}

.introduction .introduction-image_wrapper {
  position: relative;
  top: -4em;
  overflow: hidden;
}

@media only screen and (max-width: 425px) {
  .introduction .introduction-image_wrapper {
    top: -2em;
  }
}

.introduction .introduction-image_wrapper img {
  transition: all 2.8s cubic-bezier(.215, .61, .355, 1), transform 2.8s cubic-bezier(.215, .61, .355, 1);
  position: relative;
}

@media only screen and (min-width: 425px) and (max-width: 1023px) {
  .introduction .introduction-image_wrapper img {
    width: 323px;
    margin: auto;
    display: block;
  }
}

@media only screen and (max-width: 800px) {
  .introduction .introduction-image_wrapper img {
    width: 70%;
    margin: auto;
    display: block;
  }
}

.prestations {
  justify-content: center;
  margin-bottom: 8em;
  display: flex;
  position: relative;
}

@media only screen and (max-width: 574px) {
  .prestations {
    margin-bottom: 0;
  }
}

.prestations .container {
  align-items: center;
}

.prestations .container .accordion {
  flex-direction: row;
  display: flex;
}

@media only screen and (max-width: 574px) {
  .prestations .container .accordion {
    flex-direction: column;
  }
}

.prestations .container .accordion .cell {
  width: 25%;
  height: 400px;
  transition: width .5s;
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: 574px) {
  .prestations .container .accordion .cell {
    border-bottom: 1px solid #fff;
    width: 100%;
    min-height: 400px;
  }
}

.prestations .container .accordion .cell .prestations-cell-svg {
  align-items: center;
  width: 60px;
  height: 80px;
  margin: 0 auto;
  padding-top: 36px;
  display: flex;
}

.prestations .container .accordion .cell .prestations-cell-svg .play {
  margin: auto;
  display: block;
}

.prestations .container .accordion .cell .prestations-cell-svg .play img {
  cursor: pointer;
}

.prestations .container .accordion .cell .prestations-cell-svg svg {
  margin: 0 auto;
  display: none;
  overflow: hidden;
}

.prestations .container .accordion .cell .prestations-cell-svg .wave {
  stroke-dasharray: 0 16 101 16;
  animation: 2.4s linear infinite moveTheWave;
}

@keyframes moveTheWave {
  0% {
    stroke-dashoffset: 0;
    transform: translate3d(0, 0, 0);
  }

  100% {
    stroke-dashoffset: -133px;
    transform: translate3d(-90px, 0, 0);
  }
}

.prestations .container .accordion .cell .img {
  backface-visibility: hidden;
  background-color: #13152e;
  background-size: cover;
  width: 100%;
  height: 610px;
  transition: all;
  position: absolute;
  top: 0;
  left: 0;
}

.prestations .container .accordion .cell .title {
  color: #fff;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-family: Bigilla;
  font-size: 39px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (max-width: 574px) {
  .prestations .container .accordion .cell .title {
    opacity: 0;
  }
}

.prestations .container .accordion .cell .title .clubSong-title_wrapper {
  display: inline-block;
  overflow: hidden;
}

.prestations .container .accordion .cell .title .clubSong-title_wrapper .firstRow {
  transition: all 1.4s cubic-bezier(.215, .61, .355, 1);
  transform: translate(0, 100px);
}

.prestations .container .accordion .cell .title .clubSong-title_wrapper.is-inview .firstRow {
  transform: translate(0);
}

.prestations .container .accordion .cell .txt {
  opacity: 0;
  text-align: center;
  z-index: 1;
  flex-direction: column;
  place-content: center;
  align-items: center;
  margin: auto;
  padding: 72px 1em 0;
  transition: all .15s;
  display: flex;
  position: relative;
}

@media only screen and (max-width: 574px) {
  .prestations .container .accordion .cell .txt {
    opacity: 1;
    padding-top: 3em;
  }
}

.prestations .container .accordion .cell .txt .txt-title {
  color: #fff;
  margin-top: 1em;
  padding-bottom: 24px;
  font-family: Bigilla;
  font-size: 39px;
}

.prestations .container .accordion .cell .txt .txt-content {
  color: #fff;
  text-align: justify;
  width: 90%;
  font-weight: 100;
  line-height: 24px;
  top: 60px;
}

@media only screen and (max-width: 574px) {
  .prestations .container .accordion .cell .txt .txt-content {
    text-align: center;
    width: 100%;
  }
}

.prestations .container .accordion .cell:first-child .img {
  background-image: linear-gradient(#13152ebf, #13152ebf), url("mariage.d53b5e82.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.prestations .container .accordion .cell:nth-child(2) .img {
  background-image: linear-gradient(#13152ebf, #13152ebf), url("entreprise.7fb00d3b.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.prestations .container .accordion .cell:nth-child(3) .img {
  background-image: linear-gradient(#13152ebf, #13152ebf), url("gala.58261626.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.prestations .container .accordion .cell:nth-child(4) .img {
  background-image: linear-gradient(#13152ebf, #13152ebf), url("clubbing.ae4e9797.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.prestations .container .accordion .cell:nth-child(5) .img {
  background-image: linear-gradient(#13152ebf, #13152ebf), url("croisiere.9c1815fd.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.prestations .container .accordion:hover .cell {
  width: 16.666%;
}

@media only screen and (max-width: 574px) {
  .prestations .container .accordion:hover .cell {
    width: auto;
  }
}

.prestations .container .accordion:hover .cell:hover {
  width: 40%;
}

@media only screen and (max-width: 574px) {
  .prestations .container .accordion:hover .cell:hover {
    width: auto;
  }
}

.prestations .container .accordion:hover .cell:hover .txt {
  opacity: 1;
  transition-delay: .5s;
}

.prestations .container .accordion:hover .cell:hover .title {
  opacity: 0;
  transition-delay: .5s;
}

.presentation {
  justify-content: center;
  margin-bottom: 8em;
  display: flex;
  position: relative;
}

@media only screen and (max-width: 800px) {
  .presentation {
    padding: 0;
  }
}

@media only screen and (max-width: 425px) {
  .presentation {
    margin-bottom: 1em;
  }
}

.presentation #pinSection {
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 124px 0;
  display: flex;
  position: relative;
}

@media only screen and (max-width: 425px) {
  .presentation #pinSection {
    padding-top: 3em;
    padding-bottom: 0;
  }
}

.presentation #pinSection:after {
  content: "";
  z-index: -1;
  background-color: #fff;
  width: 50%;
  height: 60%;
  padding: 156px 0;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(0, 10%);
}

@media only screen and (max-width: 800px) {
  .presentation #pinSection:after {
    z-index: 0;
    width: 100%;
    top: auto;
  }
}

.presentation #pinSection .presentation-left-background, .presentation #pinSection .presentation-right-background {
  z-index: -1;
  justify-content: center;
  width: 100%;
  height: 100vh;
  display: flex;
  position: absolute;
  top: 0;
}

@media only screen and (max-width: 800px) {
  .presentation #pinSection .presentation-left-background, .presentation #pinSection .presentation-right-background {
    z-index: 0;
  }
}

.presentation #pinSection .presentation-left-background:before, .presentation #pinSection .presentation-right-background:before {
  content: "";
  z-index: -2;
  background-color: #13152e;
  width: 60%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (max-width: 800px) {
  .presentation #pinSection .presentation-left-background:before, .presentation #pinSection .presentation-right-background:before {
    width: 100%;
  }
}

.presentation #pinSection .presentation-right-background:before {
  z-index: -1;
  background-color: #fff;
  width: 50%;
  height: 100%;
  left: auto;
  right: 0;
}

.presentation .container {
  flex-direction: row;
  justify-content: space-between;
}

@media only screen and (max-width: 800px) {
  .presentation .container {
    z-index: 1;
    flex-direction: column;
    align-items: center;
  }
}

.presentation .container .presentation-img {
  margin-top: 72px;
}

@media only screen and (max-width: 574px) {
  .presentation .container .presentation-img {
    margin-top: 0;
  }

  .presentation .container .presentation-img img {
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: auto;
    display: flex;
  }
}

.presentation .container .presentation-img p {
  color: #fff;
  text-align: right;
}

.presentation .container .presentation-text {
  max-width: 572px;
  padding-top: 92px;
}

@media only screen and (max-width: 574px) {
  .presentation .container .presentation-text {
    padding-top: 0;
    padding-left: 12px;
    padding-right: 12px;
  }
}

.presentation .container .presentation-text .presentation-subtitle_wrapper {
  overflow: hidden;
}

.presentation .container .presentation-text .presentation-subtitle_wrapper .firstRow {
  transition: all 1.4s cubic-bezier(.215, .61, .355, 1);
  transform: translate(0, 100px);
}

.presentation .container .presentation-text .presentation-subtitle_wrapper.is-inview .firstRow {
  transform: translate(0);
}

@media only screen and (max-width: 574px) {
  .presentation .container .presentation-text h3 {
    text-align: center;
  }
}

.presentation .container .presentation-text h3 .script-font {
  text-transform: none;
  opacity: 0;
  transition: all 1.4s cubic-bezier(.215, .61, .355, 1);
}

@media only screen and (max-width: 800px) {
  .presentation .container .presentation-text h3 .script-font {
    font-size: 95px;
  }
}

.presentation .container .presentation-text h3.is-inview .script-font {
  opacity: 1;
  transition-delay: 1s;
}

@media only screen and (min-width: 800px) and (max-width: 1320px) {
  .presentation .container .presentation-text p.subtitle {
    text-align: left;
  }
}

.presentation .container .presentation-text .presentation-text-desc {
  max-width: 372px;
  margin-left: 72px;
}

@media only screen and (max-width: 1320px) {
  .presentation .container .presentation-text .presentation-text-desc {
    margin-left: 0;
  }
}

.presentation .container .presentation-text .presentation-text-desc .presentation-text-desc_wrapper {
  display: inline-block;
  overflow: hidden;
}

.presentation .container .presentation-text .presentation-text-desc .presentation-text-desc_wrapper .firstRow {
  transition: all 1.4s cubic-bezier(.215, .61, .355, 1);
  transform: translate(0, 250px);
}

.presentation .container .presentation-text .presentation-text-desc .presentation-text-desc_wrapper.is-inview .firstRow {
  transform: translate(0);
}

.presentation .container .presentation-text .presentation-text-desc p {
  color: #828282;
  max-width: 508px;
  margin: auto;
  font-weight: 100;
  line-height: 24px;
}

@media only screen and (max-width: 574px) {
  .presentation .container .presentation-text .presentation-text-desc p {
    text-align: center;
  }
}

.presentation .container .presentation-text h3 {
  max-width: 737px;
}

.presentation .container .presentation-text h3 .presentation-text_wrapper {
  display: inline-block;
  overflow: hidden;
}

.presentation .container .presentation-text h3 .presentation-text_wrapper .firstRow .letter, .presentation .container .presentation-text h3 .presentation-text_wrapper .secondRow .letter, .presentation .container .presentation-text h3 .presentation-text_wrapper .thirdRow .letter {
  line-height: 0;
  transition: all 1.4s cubic-bezier(.215, .61, .355, 1);
  display: inline-block;
  transform: translate(0, 100px);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:first-child {
  transition-delay: 50ms;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(2) {
  transition-delay: 75ms;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(3) {
  transition-delay: .1s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(4) {
  transition-delay: .125s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(5) {
  transition-delay: .15s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(6) {
  transition-delay: .175s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(7) {
  transition-delay: .2s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(8) {
  transition-delay: .225s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(9) {
  transition-delay: .25s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(10) {
  transition-delay: .275s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(11) {
  transition-delay: .3s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(12) {
  transition-delay: .325s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(13) {
  transition-delay: .35s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(14) {
  transition-delay: .375s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(15) {
  transition-delay: .4s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(16) {
  transition-delay: .425s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(17) {
  transition-delay: .45s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(18) {
  transition-delay: .475s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(19) {
  transition-delay: .5s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(20) {
  transition-delay: .525s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(21) {
  transition-delay: .55s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(22) {
  transition-delay: .575s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(23) {
  transition-delay: .6s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(24) {
  transition-delay: .625s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(25) {
  transition-delay: .65s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(26) {
  transition-delay: .675s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(27) {
  transition-delay: .7s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(28) {
  transition-delay: .725s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(29) {
  transition-delay: .75s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(30) {
  transition-delay: .775s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(31) {
  transition-delay: .8s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(32) {
  transition-delay: .825s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(33) {
  transition-delay: .85s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(34) {
  transition-delay: .875s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(35) {
  transition-delay: .9s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(36) {
  transition-delay: .925s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(37) {
  transition-delay: .95s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(38) {
  transition-delay: .975s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(39) {
  transition-delay: 1s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(40) {
  transition-delay: 1.025s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(41) {
  transition-delay: 1.05s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(42) {
  transition-delay: 1.075s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(43) {
  transition-delay: 1.1s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(44) {
  transition-delay: 1.125s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(45) {
  transition-delay: 1.15s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(46) {
  transition-delay: 1.175s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(47) {
  transition-delay: 1.2s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(48) {
  transition-delay: 1.225s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(49) {
  transition-delay: 1.25s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(50) {
  transition-delay: 1.275s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(51) {
  transition-delay: 1.3s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(52) {
  transition-delay: 1.325s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(53) {
  transition-delay: 1.35s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(54) {
  transition-delay: 1.375s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(55) {
  transition-delay: 1.4s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(56) {
  transition-delay: 1.425s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(57) {
  transition-delay: 1.45s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(58) {
  transition-delay: 1.475s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(59) {
  transition-delay: 1.5s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(60) {
  transition-delay: 1.525s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(61) {
  transition-delay: 1.55s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(62) {
  transition-delay: 1.575s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(63) {
  transition-delay: 1.6s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(64) {
  transition-delay: 1.625s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(65) {
  transition-delay: 1.65s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(66) {
  transition-delay: 1.675s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(67) {
  transition-delay: 1.7s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(68) {
  transition-delay: 1.725s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(69) {
  transition-delay: 1.75s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(70) {
  transition-delay: 1.775s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(71) {
  transition-delay: 1.8s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(72) {
  transition-delay: 1.825s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(73) {
  transition-delay: 1.85s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(74) {
  transition-delay: 1.875s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(75) {
  transition-delay: 1.9s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(76) {
  transition-delay: 1.925s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(77) {
  transition-delay: 1.95s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(78) {
  transition-delay: 1.975s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(79) {
  transition-delay: 2s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(80) {
  transition-delay: 2.025s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(81) {
  transition-delay: 2.05s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(82) {
  transition-delay: 2.075s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(83) {
  transition-delay: 2.1s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(84) {
  transition-delay: 2.125s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(85) {
  transition-delay: 2.15s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(86) {
  transition-delay: 2.175s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(87) {
  transition-delay: 2.2s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(88) {
  transition-delay: 2.225s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(89) {
  transition-delay: 2.25s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(90) {
  transition-delay: 2.275s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(91) {
  transition-delay: 2.3s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(92) {
  transition-delay: 2.325s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(93) {
  transition-delay: 2.35s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(94) {
  transition-delay: 2.375s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(95) {
  transition-delay: 2.4s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(96) {
  transition-delay: 2.425s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(97) {
  transition-delay: 2.45s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(98) {
  transition-delay: 2.475s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(99) {
  transition-delay: 2.5s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .firstRow .letter:nth-child(100) {
  transition-delay: 2.525s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:first-child {
  transition-delay: .3s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(2) {
  transition-delay: .325s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(3) {
  transition-delay: .35s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(4) {
  transition-delay: .375s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(5) {
  transition-delay: .4s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(6) {
  transition-delay: .425s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(7) {
  transition-delay: .45s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(8) {
  transition-delay: .475s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(9) {
  transition-delay: .5s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(10) {
  transition-delay: .525s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(11) {
  transition-delay: .55s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(12) {
  transition-delay: .575s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(13) {
  transition-delay: .6s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(14) {
  transition-delay: .625s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(15) {
  transition-delay: .65s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(16) {
  transition-delay: .675s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(17) {
  transition-delay: .7s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(18) {
  transition-delay: .725s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(19) {
  transition-delay: .75s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(20) {
  transition-delay: .775s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(21) {
  transition-delay: .8s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(22) {
  transition-delay: .825s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(23) {
  transition-delay: .85s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(24) {
  transition-delay: .875s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(25) {
  transition-delay: .9s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(26) {
  transition-delay: .925s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(27) {
  transition-delay: .95s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(28) {
  transition-delay: .975s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(29) {
  transition-delay: 1s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(30) {
  transition-delay: 1.025s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(31) {
  transition-delay: 1.05s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(32) {
  transition-delay: 1.075s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(33) {
  transition-delay: 1.1s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(34) {
  transition-delay: 1.125s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(35) {
  transition-delay: 1.15s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(36) {
  transition-delay: 1.175s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(37) {
  transition-delay: 1.2s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(38) {
  transition-delay: 1.225s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(39) {
  transition-delay: 1.25s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(40) {
  transition-delay: 1.275s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(41) {
  transition-delay: 1.3s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(42) {
  transition-delay: 1.325s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(43) {
  transition-delay: 1.35s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(44) {
  transition-delay: 1.375s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(45) {
  transition-delay: 1.4s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(46) {
  transition-delay: 1.425s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(47) {
  transition-delay: 1.45s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(48) {
  transition-delay: 1.475s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(49) {
  transition-delay: 1.5s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(50) {
  transition-delay: 1.525s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(51) {
  transition-delay: 1.55s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(52) {
  transition-delay: 1.575s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(53) {
  transition-delay: 1.6s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(54) {
  transition-delay: 1.625s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(55) {
  transition-delay: 1.65s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(56) {
  transition-delay: 1.675s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(57) {
  transition-delay: 1.7s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(58) {
  transition-delay: 1.725s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(59) {
  transition-delay: 1.75s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(60) {
  transition-delay: 1.775s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(61) {
  transition-delay: 1.8s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(62) {
  transition-delay: 1.825s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(63) {
  transition-delay: 1.85s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(64) {
  transition-delay: 1.875s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(65) {
  transition-delay: 1.9s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(66) {
  transition-delay: 1.925s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(67) {
  transition-delay: 1.95s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(68) {
  transition-delay: 1.975s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(69) {
  transition-delay: 2s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(70) {
  transition-delay: 2.025s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(71) {
  transition-delay: 2.05s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(72) {
  transition-delay: 2.075s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(73) {
  transition-delay: 2.1s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(74) {
  transition-delay: 2.125s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(75) {
  transition-delay: 2.15s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(76) {
  transition-delay: 2.175s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(77) {
  transition-delay: 2.2s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(78) {
  transition-delay: 2.225s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(79) {
  transition-delay: 2.25s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(80) {
  transition-delay: 2.275s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(81) {
  transition-delay: 2.3s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(82) {
  transition-delay: 2.325s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(83) {
  transition-delay: 2.35s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(84) {
  transition-delay: 2.375s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(85) {
  transition-delay: 2.4s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(86) {
  transition-delay: 2.425s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(87) {
  transition-delay: 2.45s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(88) {
  transition-delay: 2.475s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(89) {
  transition-delay: 2.5s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(90) {
  transition-delay: 2.525s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(91) {
  transition-delay: 2.55s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(92) {
  transition-delay: 2.575s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(93) {
  transition-delay: 2.6s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(94) {
  transition-delay: 2.625s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(95) {
  transition-delay: 2.65s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(96) {
  transition-delay: 2.675s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(97) {
  transition-delay: 2.7s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(98) {
  transition-delay: 2.725s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(99) {
  transition-delay: 2.75s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(100) {
  transition-delay: 2.775s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(101) {
  transition-delay: 2.8s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(102) {
  transition-delay: 2.825s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(103) {
  transition-delay: 2.85s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(104) {
  transition-delay: 2.875s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(105) {
  transition-delay: 2.9s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(106) {
  transition-delay: 2.925s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(107) {
  transition-delay: 2.95s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(108) {
  transition-delay: 2.975s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(109) {
  transition-delay: 3s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(110) {
  transition-delay: 3.025s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(111) {
  transition-delay: 3.05s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(112) {
  transition-delay: 3.075s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(113) {
  transition-delay: 3.1s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(114) {
  transition-delay: 3.125s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(115) {
  transition-delay: 3.15s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(116) {
  transition-delay: 3.175s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(117) {
  transition-delay: 3.2s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(118) {
  transition-delay: 3.225s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(119) {
  transition-delay: 3.25s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(120) {
  transition-delay: 3.275s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(121) {
  transition-delay: 3.3s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(122) {
  transition-delay: 3.325s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(123) {
  transition-delay: 3.35s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(124) {
  transition-delay: 3.375s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(125) {
  transition-delay: 3.4s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(126) {
  transition-delay: 3.425s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(127) {
  transition-delay: 3.45s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(128) {
  transition-delay: 3.475s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(129) {
  transition-delay: 3.5s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(130) {
  transition-delay: 3.525s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(131) {
  transition-delay: 3.55s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(132) {
  transition-delay: 3.575s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(133) {
  transition-delay: 3.6s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(134) {
  transition-delay: 3.625s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(135) {
  transition-delay: 3.65s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(136) {
  transition-delay: 3.675s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(137) {
  transition-delay: 3.7s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(138) {
  transition-delay: 3.725s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(139) {
  transition-delay: 3.75s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(140) {
  transition-delay: 3.775s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(141) {
  transition-delay: 3.8s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(142) {
  transition-delay: 3.825s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(143) {
  transition-delay: 3.85s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(144) {
  transition-delay: 3.875s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(145) {
  transition-delay: 3.9s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(146) {
  transition-delay: 3.925s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(147) {
  transition-delay: 3.95s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(148) {
  transition-delay: 3.975s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(149) {
  transition-delay: 4s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(150) {
  transition-delay: 4.025s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(151) {
  transition-delay: 4.05s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(152) {
  transition-delay: 4.075s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(153) {
  transition-delay: 4.1s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(154) {
  transition-delay: 4.125s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(155) {
  transition-delay: 4.15s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(156) {
  transition-delay: 4.175s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(157) {
  transition-delay: 4.2s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(158) {
  transition-delay: 4.225s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(159) {
  transition-delay: 4.25s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(160) {
  transition-delay: 4.275s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(161) {
  transition-delay: 4.3s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(162) {
  transition-delay: 4.325s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(163) {
  transition-delay: 4.35s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(164) {
  transition-delay: 4.375s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(165) {
  transition-delay: 4.4s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(166) {
  transition-delay: 4.425s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(167) {
  transition-delay: 4.45s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(168) {
  transition-delay: 4.475s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(169) {
  transition-delay: 4.5s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(170) {
  transition-delay: 4.525s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(171) {
  transition-delay: 4.55s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(172) {
  transition-delay: 4.575s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(173) {
  transition-delay: 4.6s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(174) {
  transition-delay: 4.625s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(175) {
  transition-delay: 4.65s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(176) {
  transition-delay: 4.675s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(177) {
  transition-delay: 4.7s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(178) {
  transition-delay: 4.725s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(179) {
  transition-delay: 4.75s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(180) {
  transition-delay: 4.775s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(181) {
  transition-delay: 4.8s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(182) {
  transition-delay: 4.825s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(183) {
  transition-delay: 4.85s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(184) {
  transition-delay: 4.875s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(185) {
  transition-delay: 4.9s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(186) {
  transition-delay: 4.925s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(187) {
  transition-delay: 4.95s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(188) {
  transition-delay: 4.975s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(189) {
  transition-delay: 5s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(190) {
  transition-delay: 5.025s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(191) {
  transition-delay: 5.05s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(192) {
  transition-delay: 5.075s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(193) {
  transition-delay: 5.1s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(194) {
  transition-delay: 5.125s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(195) {
  transition-delay: 5.15s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(196) {
  transition-delay: 5.175s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(197) {
  transition-delay: 5.2s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(198) {
  transition-delay: 5.225s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(199) {
  transition-delay: 5.25s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .secondRow .letter:nth-child(200) {
  transition-delay: 5.275s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:first-child {
  transition-delay: .5s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(2) {
  transition-delay: .525s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(3) {
  transition-delay: .55s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(4) {
  transition-delay: .575s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(5) {
  transition-delay: .6s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(6) {
  transition-delay: .625s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(7) {
  transition-delay: .65s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(8) {
  transition-delay: .675s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(9) {
  transition-delay: .7s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(10) {
  transition-delay: .725s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(11) {
  transition-delay: .75s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(12) {
  transition-delay: .775s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(13) {
  transition-delay: .8s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(14) {
  transition-delay: .825s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(15) {
  transition-delay: .85s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(16) {
  transition-delay: .875s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(17) {
  transition-delay: .9s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(18) {
  transition-delay: .925s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(19) {
  transition-delay: .95s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(20) {
  transition-delay: .975s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(21) {
  transition-delay: 1s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(22) {
  transition-delay: 1.025s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(23) {
  transition-delay: 1.05s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(24) {
  transition-delay: 1.075s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(25) {
  transition-delay: 1.1s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(26) {
  transition-delay: 1.125s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(27) {
  transition-delay: 1.15s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(28) {
  transition-delay: 1.175s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(29) {
  transition-delay: 1.2s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(30) {
  transition-delay: 1.225s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(31) {
  transition-delay: 1.25s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(32) {
  transition-delay: 1.275s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(33) {
  transition-delay: 1.3s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(34) {
  transition-delay: 1.325s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(35) {
  transition-delay: 1.35s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(36) {
  transition-delay: 1.375s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(37) {
  transition-delay: 1.4s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(38) {
  transition-delay: 1.425s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(39) {
  transition-delay: 1.45s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(40) {
  transition-delay: 1.475s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(41) {
  transition-delay: 1.5s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(42) {
  transition-delay: 1.525s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(43) {
  transition-delay: 1.55s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(44) {
  transition-delay: 1.575s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(45) {
  transition-delay: 1.6s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(46) {
  transition-delay: 1.625s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(47) {
  transition-delay: 1.65s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(48) {
  transition-delay: 1.675s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(49) {
  transition-delay: 1.7s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(50) {
  transition-delay: 1.725s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(51) {
  transition-delay: 1.75s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(52) {
  transition-delay: 1.775s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(53) {
  transition-delay: 1.8s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(54) {
  transition-delay: 1.825s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(55) {
  transition-delay: 1.85s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(56) {
  transition-delay: 1.875s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(57) {
  transition-delay: 1.9s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(58) {
  transition-delay: 1.925s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(59) {
  transition-delay: 1.95s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(60) {
  transition-delay: 1.975s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(61) {
  transition-delay: 2s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(62) {
  transition-delay: 2.025s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(63) {
  transition-delay: 2.05s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(64) {
  transition-delay: 2.075s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(65) {
  transition-delay: 2.1s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(66) {
  transition-delay: 2.125s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(67) {
  transition-delay: 2.15s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(68) {
  transition-delay: 2.175s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(69) {
  transition-delay: 2.2s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(70) {
  transition-delay: 2.225s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(71) {
  transition-delay: 2.25s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(72) {
  transition-delay: 2.275s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(73) {
  transition-delay: 2.3s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(74) {
  transition-delay: 2.325s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(75) {
  transition-delay: 2.35s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(76) {
  transition-delay: 2.375s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(77) {
  transition-delay: 2.4s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(78) {
  transition-delay: 2.425s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(79) {
  transition-delay: 2.45s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(80) {
  transition-delay: 2.475s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(81) {
  transition-delay: 2.5s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(82) {
  transition-delay: 2.525s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(83) {
  transition-delay: 2.55s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(84) {
  transition-delay: 2.575s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(85) {
  transition-delay: 2.6s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(86) {
  transition-delay: 2.625s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(87) {
  transition-delay: 2.65s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(88) {
  transition-delay: 2.675s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(89) {
  transition-delay: 2.7s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(90) {
  transition-delay: 2.725s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(91) {
  transition-delay: 2.75s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(92) {
  transition-delay: 2.775s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(93) {
  transition-delay: 2.8s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(94) {
  transition-delay: 2.825s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(95) {
  transition-delay: 2.85s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(96) {
  transition-delay: 2.875s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(97) {
  transition-delay: 2.9s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(98) {
  transition-delay: 2.925s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(99) {
  transition-delay: 2.95s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(100) {
  transition-delay: 2.975s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(101) {
  transition-delay: 3s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(102) {
  transition-delay: 3.025s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(103) {
  transition-delay: 3.05s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(104) {
  transition-delay: 3.075s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(105) {
  transition-delay: 3.1s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(106) {
  transition-delay: 3.125s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(107) {
  transition-delay: 3.15s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(108) {
  transition-delay: 3.175s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(109) {
  transition-delay: 3.2s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(110) {
  transition-delay: 3.225s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(111) {
  transition-delay: 3.25s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(112) {
  transition-delay: 3.275s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(113) {
  transition-delay: 3.3s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(114) {
  transition-delay: 3.325s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(115) {
  transition-delay: 3.35s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(116) {
  transition-delay: 3.375s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(117) {
  transition-delay: 3.4s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(118) {
  transition-delay: 3.425s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(119) {
  transition-delay: 3.45s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(120) {
  transition-delay: 3.475s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(121) {
  transition-delay: 3.5s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(122) {
  transition-delay: 3.525s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(123) {
  transition-delay: 3.55s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(124) {
  transition-delay: 3.575s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(125) {
  transition-delay: 3.6s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(126) {
  transition-delay: 3.625s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(127) {
  transition-delay: 3.65s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(128) {
  transition-delay: 3.675s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(129) {
  transition-delay: 3.7s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(130) {
  transition-delay: 3.725s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(131) {
  transition-delay: 3.75s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(132) {
  transition-delay: 3.775s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(133) {
  transition-delay: 3.8s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(134) {
  transition-delay: 3.825s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(135) {
  transition-delay: 3.85s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(136) {
  transition-delay: 3.875s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(137) {
  transition-delay: 3.9s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(138) {
  transition-delay: 3.925s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(139) {
  transition-delay: 3.95s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(140) {
  transition-delay: 3.975s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(141) {
  transition-delay: 4s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(142) {
  transition-delay: 4.025s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(143) {
  transition-delay: 4.05s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(144) {
  transition-delay: 4.075s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(145) {
  transition-delay: 4.1s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(146) {
  transition-delay: 4.125s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(147) {
  transition-delay: 4.15s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(148) {
  transition-delay: 4.175s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(149) {
  transition-delay: 4.2s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(150) {
  transition-delay: 4.225s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(151) {
  transition-delay: 4.25s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(152) {
  transition-delay: 4.275s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(153) {
  transition-delay: 4.3s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(154) {
  transition-delay: 4.325s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(155) {
  transition-delay: 4.35s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(156) {
  transition-delay: 4.375s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(157) {
  transition-delay: 4.4s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(158) {
  transition-delay: 4.425s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(159) {
  transition-delay: 4.45s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(160) {
  transition-delay: 4.475s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(161) {
  transition-delay: 4.5s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(162) {
  transition-delay: 4.525s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(163) {
  transition-delay: 4.55s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(164) {
  transition-delay: 4.575s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(165) {
  transition-delay: 4.6s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(166) {
  transition-delay: 4.625s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(167) {
  transition-delay: 4.65s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(168) {
  transition-delay: 4.675s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(169) {
  transition-delay: 4.7s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(170) {
  transition-delay: 4.725s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(171) {
  transition-delay: 4.75s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(172) {
  transition-delay: 4.775s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(173) {
  transition-delay: 4.8s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(174) {
  transition-delay: 4.825s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(175) {
  transition-delay: 4.85s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(176) {
  transition-delay: 4.875s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(177) {
  transition-delay: 4.9s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(178) {
  transition-delay: 4.925s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(179) {
  transition-delay: 4.95s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(180) {
  transition-delay: 4.975s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(181) {
  transition-delay: 5s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(182) {
  transition-delay: 5.025s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(183) {
  transition-delay: 5.05s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(184) {
  transition-delay: 5.075s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(185) {
  transition-delay: 5.1s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(186) {
  transition-delay: 5.125s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(187) {
  transition-delay: 5.15s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(188) {
  transition-delay: 5.175s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(189) {
  transition-delay: 5.2s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(190) {
  transition-delay: 5.225s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(191) {
  transition-delay: 5.25s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(192) {
  transition-delay: 5.275s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(193) {
  transition-delay: 5.3s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(194) {
  transition-delay: 5.325s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(195) {
  transition-delay: 5.35s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(196) {
  transition-delay: 5.375s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(197) {
  transition-delay: 5.4s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(198) {
  transition-delay: 5.425s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(199) {
  transition-delay: 5.45s;
  transform: translate(0);
}

.presentation .container .presentation-text h3.is-inview .presentation-text_wrapper .thirdRow .letter:nth-child(200) {
  transition-delay: 5.475s;
  transform: translate(0);
}

.feed {
  justify-content: center;
  margin-bottom: 6em;
  display: flex;
  position: relative;
}

@media only screen and (max-width: 800px) {
  .feed {
    margin-bottom: 12px;
  }
}

@media only screen and (max-width: 425px) {
  .feed {
    margin-bottom: 0;
  }
}

.feed #feed {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 188px;
  display: flex;
}

@media only screen and (max-width: 800px) {
  .feed #feed {
    margin-bottom: 12px;
  }
}

@media only screen and (max-width: 425px) {
  .feed #feed {
    margin-bottom: 0;
  }
}

.feed #feed .container {
  justify-content: center;
  align-items: center;
}

.feed #feed .container .feed-text h3 {
  max-width: 737px;
}

.feed #feed .container .feed-text h3 .feed-text_wrapper {
  display: inline-block;
  overflow: hidden;
}

.feed #feed .container .feed-text h3 .feed-text_wrapper .firstRow .letter, .feed #feed .container .feed-text h3 .feed-text_wrapper .secondRow .letter, .feed #feed .container .feed-text h3 .feed-text_wrapper .thirdRow .letter {
  line-height: 0;
  transition: all 1.4s cubic-bezier(.215, .61, .355, 1);
  display: inline-block;
  transform: translate(0, 100px);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:first-child {
  transition-delay: 50ms;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(2) {
  transition-delay: 75ms;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(3) {
  transition-delay: .1s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(4) {
  transition-delay: .125s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(5) {
  transition-delay: .15s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(6) {
  transition-delay: .175s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(7) {
  transition-delay: .2s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(8) {
  transition-delay: .225s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(9) {
  transition-delay: .25s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(10) {
  transition-delay: .275s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(11) {
  transition-delay: .3s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(12) {
  transition-delay: .325s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(13) {
  transition-delay: .35s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(14) {
  transition-delay: .375s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(15) {
  transition-delay: .4s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(16) {
  transition-delay: .425s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(17) {
  transition-delay: .45s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(18) {
  transition-delay: .475s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(19) {
  transition-delay: .5s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(20) {
  transition-delay: .525s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(21) {
  transition-delay: .55s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(22) {
  transition-delay: .575s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(23) {
  transition-delay: .6s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(24) {
  transition-delay: .625s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(25) {
  transition-delay: .65s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(26) {
  transition-delay: .675s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(27) {
  transition-delay: .7s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(28) {
  transition-delay: .725s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(29) {
  transition-delay: .75s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(30) {
  transition-delay: .775s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(31) {
  transition-delay: .8s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(32) {
  transition-delay: .825s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(33) {
  transition-delay: .85s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(34) {
  transition-delay: .875s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(35) {
  transition-delay: .9s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(36) {
  transition-delay: .925s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(37) {
  transition-delay: .95s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(38) {
  transition-delay: .975s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(39) {
  transition-delay: 1s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(40) {
  transition-delay: 1.025s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(41) {
  transition-delay: 1.05s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(42) {
  transition-delay: 1.075s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(43) {
  transition-delay: 1.1s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(44) {
  transition-delay: 1.125s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(45) {
  transition-delay: 1.15s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(46) {
  transition-delay: 1.175s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(47) {
  transition-delay: 1.2s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(48) {
  transition-delay: 1.225s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(49) {
  transition-delay: 1.25s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(50) {
  transition-delay: 1.275s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(51) {
  transition-delay: 1.3s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(52) {
  transition-delay: 1.325s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(53) {
  transition-delay: 1.35s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(54) {
  transition-delay: 1.375s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(55) {
  transition-delay: 1.4s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(56) {
  transition-delay: 1.425s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(57) {
  transition-delay: 1.45s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(58) {
  transition-delay: 1.475s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(59) {
  transition-delay: 1.5s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(60) {
  transition-delay: 1.525s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(61) {
  transition-delay: 1.55s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(62) {
  transition-delay: 1.575s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(63) {
  transition-delay: 1.6s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(64) {
  transition-delay: 1.625s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(65) {
  transition-delay: 1.65s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(66) {
  transition-delay: 1.675s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(67) {
  transition-delay: 1.7s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(68) {
  transition-delay: 1.725s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(69) {
  transition-delay: 1.75s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(70) {
  transition-delay: 1.775s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(71) {
  transition-delay: 1.8s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(72) {
  transition-delay: 1.825s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(73) {
  transition-delay: 1.85s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(74) {
  transition-delay: 1.875s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(75) {
  transition-delay: 1.9s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(76) {
  transition-delay: 1.925s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(77) {
  transition-delay: 1.95s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(78) {
  transition-delay: 1.975s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(79) {
  transition-delay: 2s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(80) {
  transition-delay: 2.025s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(81) {
  transition-delay: 2.05s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(82) {
  transition-delay: 2.075s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(83) {
  transition-delay: 2.1s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(84) {
  transition-delay: 2.125s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(85) {
  transition-delay: 2.15s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(86) {
  transition-delay: 2.175s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(87) {
  transition-delay: 2.2s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(88) {
  transition-delay: 2.225s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(89) {
  transition-delay: 2.25s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(90) {
  transition-delay: 2.275s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(91) {
  transition-delay: 2.3s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(92) {
  transition-delay: 2.325s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(93) {
  transition-delay: 2.35s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(94) {
  transition-delay: 2.375s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(95) {
  transition-delay: 2.4s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(96) {
  transition-delay: 2.425s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(97) {
  transition-delay: 2.45s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(98) {
  transition-delay: 2.475s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(99) {
  transition-delay: 2.5s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .firstRow .letter:nth-child(100) {
  transition-delay: 2.525s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:first-child {
  transition-delay: .3s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(2) {
  transition-delay: .325s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(3) {
  transition-delay: .35s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(4) {
  transition-delay: .375s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(5) {
  transition-delay: .4s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(6) {
  transition-delay: .425s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(7) {
  transition-delay: .45s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(8) {
  transition-delay: .475s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(9) {
  transition-delay: .5s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(10) {
  transition-delay: .525s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(11) {
  transition-delay: .55s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(12) {
  transition-delay: .575s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(13) {
  transition-delay: .6s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(14) {
  transition-delay: .625s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(15) {
  transition-delay: .65s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(16) {
  transition-delay: .675s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(17) {
  transition-delay: .7s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(18) {
  transition-delay: .725s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(19) {
  transition-delay: .75s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(20) {
  transition-delay: .775s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(21) {
  transition-delay: .8s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(22) {
  transition-delay: .825s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(23) {
  transition-delay: .85s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(24) {
  transition-delay: .875s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(25) {
  transition-delay: .9s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(26) {
  transition-delay: .925s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(27) {
  transition-delay: .95s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(28) {
  transition-delay: .975s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(29) {
  transition-delay: 1s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(30) {
  transition-delay: 1.025s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(31) {
  transition-delay: 1.05s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(32) {
  transition-delay: 1.075s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(33) {
  transition-delay: 1.1s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(34) {
  transition-delay: 1.125s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(35) {
  transition-delay: 1.15s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(36) {
  transition-delay: 1.175s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(37) {
  transition-delay: 1.2s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(38) {
  transition-delay: 1.225s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(39) {
  transition-delay: 1.25s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(40) {
  transition-delay: 1.275s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(41) {
  transition-delay: 1.3s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(42) {
  transition-delay: 1.325s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(43) {
  transition-delay: 1.35s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(44) {
  transition-delay: 1.375s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(45) {
  transition-delay: 1.4s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(46) {
  transition-delay: 1.425s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(47) {
  transition-delay: 1.45s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(48) {
  transition-delay: 1.475s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(49) {
  transition-delay: 1.5s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(50) {
  transition-delay: 1.525s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(51) {
  transition-delay: 1.55s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(52) {
  transition-delay: 1.575s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(53) {
  transition-delay: 1.6s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(54) {
  transition-delay: 1.625s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(55) {
  transition-delay: 1.65s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(56) {
  transition-delay: 1.675s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(57) {
  transition-delay: 1.7s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(58) {
  transition-delay: 1.725s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(59) {
  transition-delay: 1.75s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(60) {
  transition-delay: 1.775s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(61) {
  transition-delay: 1.8s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(62) {
  transition-delay: 1.825s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(63) {
  transition-delay: 1.85s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(64) {
  transition-delay: 1.875s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(65) {
  transition-delay: 1.9s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(66) {
  transition-delay: 1.925s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(67) {
  transition-delay: 1.95s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(68) {
  transition-delay: 1.975s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(69) {
  transition-delay: 2s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(70) {
  transition-delay: 2.025s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(71) {
  transition-delay: 2.05s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(72) {
  transition-delay: 2.075s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(73) {
  transition-delay: 2.1s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(74) {
  transition-delay: 2.125s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(75) {
  transition-delay: 2.15s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(76) {
  transition-delay: 2.175s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(77) {
  transition-delay: 2.2s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(78) {
  transition-delay: 2.225s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(79) {
  transition-delay: 2.25s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(80) {
  transition-delay: 2.275s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(81) {
  transition-delay: 2.3s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(82) {
  transition-delay: 2.325s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(83) {
  transition-delay: 2.35s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(84) {
  transition-delay: 2.375s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(85) {
  transition-delay: 2.4s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(86) {
  transition-delay: 2.425s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(87) {
  transition-delay: 2.45s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(88) {
  transition-delay: 2.475s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(89) {
  transition-delay: 2.5s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(90) {
  transition-delay: 2.525s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(91) {
  transition-delay: 2.55s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(92) {
  transition-delay: 2.575s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(93) {
  transition-delay: 2.6s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(94) {
  transition-delay: 2.625s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(95) {
  transition-delay: 2.65s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(96) {
  transition-delay: 2.675s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(97) {
  transition-delay: 2.7s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(98) {
  transition-delay: 2.725s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(99) {
  transition-delay: 2.75s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(100) {
  transition-delay: 2.775s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(101) {
  transition-delay: 2.8s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(102) {
  transition-delay: 2.825s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(103) {
  transition-delay: 2.85s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(104) {
  transition-delay: 2.875s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(105) {
  transition-delay: 2.9s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(106) {
  transition-delay: 2.925s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(107) {
  transition-delay: 2.95s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(108) {
  transition-delay: 2.975s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(109) {
  transition-delay: 3s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(110) {
  transition-delay: 3.025s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(111) {
  transition-delay: 3.05s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(112) {
  transition-delay: 3.075s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(113) {
  transition-delay: 3.1s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(114) {
  transition-delay: 3.125s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(115) {
  transition-delay: 3.15s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(116) {
  transition-delay: 3.175s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(117) {
  transition-delay: 3.2s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(118) {
  transition-delay: 3.225s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(119) {
  transition-delay: 3.25s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(120) {
  transition-delay: 3.275s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(121) {
  transition-delay: 3.3s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(122) {
  transition-delay: 3.325s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(123) {
  transition-delay: 3.35s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(124) {
  transition-delay: 3.375s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(125) {
  transition-delay: 3.4s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(126) {
  transition-delay: 3.425s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(127) {
  transition-delay: 3.45s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(128) {
  transition-delay: 3.475s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(129) {
  transition-delay: 3.5s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(130) {
  transition-delay: 3.525s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(131) {
  transition-delay: 3.55s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(132) {
  transition-delay: 3.575s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(133) {
  transition-delay: 3.6s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(134) {
  transition-delay: 3.625s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(135) {
  transition-delay: 3.65s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(136) {
  transition-delay: 3.675s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(137) {
  transition-delay: 3.7s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(138) {
  transition-delay: 3.725s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(139) {
  transition-delay: 3.75s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(140) {
  transition-delay: 3.775s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(141) {
  transition-delay: 3.8s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(142) {
  transition-delay: 3.825s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(143) {
  transition-delay: 3.85s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(144) {
  transition-delay: 3.875s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(145) {
  transition-delay: 3.9s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(146) {
  transition-delay: 3.925s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(147) {
  transition-delay: 3.95s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(148) {
  transition-delay: 3.975s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(149) {
  transition-delay: 4s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(150) {
  transition-delay: 4.025s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(151) {
  transition-delay: 4.05s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(152) {
  transition-delay: 4.075s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(153) {
  transition-delay: 4.1s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(154) {
  transition-delay: 4.125s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(155) {
  transition-delay: 4.15s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(156) {
  transition-delay: 4.175s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(157) {
  transition-delay: 4.2s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(158) {
  transition-delay: 4.225s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(159) {
  transition-delay: 4.25s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(160) {
  transition-delay: 4.275s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(161) {
  transition-delay: 4.3s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(162) {
  transition-delay: 4.325s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(163) {
  transition-delay: 4.35s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(164) {
  transition-delay: 4.375s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(165) {
  transition-delay: 4.4s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(166) {
  transition-delay: 4.425s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(167) {
  transition-delay: 4.45s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(168) {
  transition-delay: 4.475s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(169) {
  transition-delay: 4.5s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(170) {
  transition-delay: 4.525s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(171) {
  transition-delay: 4.55s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(172) {
  transition-delay: 4.575s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(173) {
  transition-delay: 4.6s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(174) {
  transition-delay: 4.625s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(175) {
  transition-delay: 4.65s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(176) {
  transition-delay: 4.675s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(177) {
  transition-delay: 4.7s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(178) {
  transition-delay: 4.725s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(179) {
  transition-delay: 4.75s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(180) {
  transition-delay: 4.775s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(181) {
  transition-delay: 4.8s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(182) {
  transition-delay: 4.825s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(183) {
  transition-delay: 4.85s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(184) {
  transition-delay: 4.875s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(185) {
  transition-delay: 4.9s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(186) {
  transition-delay: 4.925s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(187) {
  transition-delay: 4.95s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(188) {
  transition-delay: 4.975s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(189) {
  transition-delay: 5s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(190) {
  transition-delay: 5.025s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(191) {
  transition-delay: 5.05s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(192) {
  transition-delay: 5.075s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(193) {
  transition-delay: 5.1s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(194) {
  transition-delay: 5.125s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(195) {
  transition-delay: 5.15s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(196) {
  transition-delay: 5.175s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(197) {
  transition-delay: 5.2s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(198) {
  transition-delay: 5.225s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(199) {
  transition-delay: 5.25s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .secondRow .letter:nth-child(200) {
  transition-delay: 5.275s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:first-child {
  transition-delay: .5s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(2) {
  transition-delay: .525s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(3) {
  transition-delay: .55s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(4) {
  transition-delay: .575s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(5) {
  transition-delay: .6s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(6) {
  transition-delay: .625s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(7) {
  transition-delay: .65s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(8) {
  transition-delay: .675s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(9) {
  transition-delay: .7s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(10) {
  transition-delay: .725s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(11) {
  transition-delay: .75s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(12) {
  transition-delay: .775s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(13) {
  transition-delay: .8s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(14) {
  transition-delay: .825s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(15) {
  transition-delay: .85s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(16) {
  transition-delay: .875s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(17) {
  transition-delay: .9s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(18) {
  transition-delay: .925s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(19) {
  transition-delay: .95s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(20) {
  transition-delay: .975s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(21) {
  transition-delay: 1s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(22) {
  transition-delay: 1.025s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(23) {
  transition-delay: 1.05s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(24) {
  transition-delay: 1.075s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(25) {
  transition-delay: 1.1s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(26) {
  transition-delay: 1.125s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(27) {
  transition-delay: 1.15s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(28) {
  transition-delay: 1.175s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(29) {
  transition-delay: 1.2s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(30) {
  transition-delay: 1.225s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(31) {
  transition-delay: 1.25s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(32) {
  transition-delay: 1.275s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(33) {
  transition-delay: 1.3s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(34) {
  transition-delay: 1.325s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(35) {
  transition-delay: 1.35s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(36) {
  transition-delay: 1.375s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(37) {
  transition-delay: 1.4s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(38) {
  transition-delay: 1.425s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(39) {
  transition-delay: 1.45s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(40) {
  transition-delay: 1.475s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(41) {
  transition-delay: 1.5s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(42) {
  transition-delay: 1.525s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(43) {
  transition-delay: 1.55s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(44) {
  transition-delay: 1.575s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(45) {
  transition-delay: 1.6s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(46) {
  transition-delay: 1.625s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(47) {
  transition-delay: 1.65s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(48) {
  transition-delay: 1.675s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(49) {
  transition-delay: 1.7s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(50) {
  transition-delay: 1.725s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(51) {
  transition-delay: 1.75s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(52) {
  transition-delay: 1.775s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(53) {
  transition-delay: 1.8s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(54) {
  transition-delay: 1.825s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(55) {
  transition-delay: 1.85s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(56) {
  transition-delay: 1.875s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(57) {
  transition-delay: 1.9s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(58) {
  transition-delay: 1.925s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(59) {
  transition-delay: 1.95s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(60) {
  transition-delay: 1.975s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(61) {
  transition-delay: 2s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(62) {
  transition-delay: 2.025s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(63) {
  transition-delay: 2.05s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(64) {
  transition-delay: 2.075s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(65) {
  transition-delay: 2.1s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(66) {
  transition-delay: 2.125s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(67) {
  transition-delay: 2.15s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(68) {
  transition-delay: 2.175s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(69) {
  transition-delay: 2.2s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(70) {
  transition-delay: 2.225s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(71) {
  transition-delay: 2.25s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(72) {
  transition-delay: 2.275s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(73) {
  transition-delay: 2.3s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(74) {
  transition-delay: 2.325s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(75) {
  transition-delay: 2.35s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(76) {
  transition-delay: 2.375s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(77) {
  transition-delay: 2.4s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(78) {
  transition-delay: 2.425s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(79) {
  transition-delay: 2.45s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(80) {
  transition-delay: 2.475s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(81) {
  transition-delay: 2.5s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(82) {
  transition-delay: 2.525s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(83) {
  transition-delay: 2.55s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(84) {
  transition-delay: 2.575s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(85) {
  transition-delay: 2.6s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(86) {
  transition-delay: 2.625s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(87) {
  transition-delay: 2.65s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(88) {
  transition-delay: 2.675s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(89) {
  transition-delay: 2.7s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(90) {
  transition-delay: 2.725s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(91) {
  transition-delay: 2.75s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(92) {
  transition-delay: 2.775s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(93) {
  transition-delay: 2.8s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(94) {
  transition-delay: 2.825s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(95) {
  transition-delay: 2.85s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(96) {
  transition-delay: 2.875s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(97) {
  transition-delay: 2.9s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(98) {
  transition-delay: 2.925s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(99) {
  transition-delay: 2.95s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(100) {
  transition-delay: 2.975s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(101) {
  transition-delay: 3s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(102) {
  transition-delay: 3.025s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(103) {
  transition-delay: 3.05s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(104) {
  transition-delay: 3.075s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(105) {
  transition-delay: 3.1s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(106) {
  transition-delay: 3.125s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(107) {
  transition-delay: 3.15s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(108) {
  transition-delay: 3.175s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(109) {
  transition-delay: 3.2s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(110) {
  transition-delay: 3.225s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(111) {
  transition-delay: 3.25s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(112) {
  transition-delay: 3.275s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(113) {
  transition-delay: 3.3s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(114) {
  transition-delay: 3.325s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(115) {
  transition-delay: 3.35s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(116) {
  transition-delay: 3.375s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(117) {
  transition-delay: 3.4s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(118) {
  transition-delay: 3.425s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(119) {
  transition-delay: 3.45s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(120) {
  transition-delay: 3.475s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(121) {
  transition-delay: 3.5s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(122) {
  transition-delay: 3.525s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(123) {
  transition-delay: 3.55s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(124) {
  transition-delay: 3.575s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(125) {
  transition-delay: 3.6s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(126) {
  transition-delay: 3.625s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(127) {
  transition-delay: 3.65s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(128) {
  transition-delay: 3.675s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(129) {
  transition-delay: 3.7s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(130) {
  transition-delay: 3.725s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(131) {
  transition-delay: 3.75s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(132) {
  transition-delay: 3.775s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(133) {
  transition-delay: 3.8s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(134) {
  transition-delay: 3.825s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(135) {
  transition-delay: 3.85s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(136) {
  transition-delay: 3.875s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(137) {
  transition-delay: 3.9s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(138) {
  transition-delay: 3.925s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(139) {
  transition-delay: 3.95s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(140) {
  transition-delay: 3.975s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(141) {
  transition-delay: 4s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(142) {
  transition-delay: 4.025s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(143) {
  transition-delay: 4.05s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(144) {
  transition-delay: 4.075s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(145) {
  transition-delay: 4.1s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(146) {
  transition-delay: 4.125s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(147) {
  transition-delay: 4.15s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(148) {
  transition-delay: 4.175s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(149) {
  transition-delay: 4.2s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(150) {
  transition-delay: 4.225s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(151) {
  transition-delay: 4.25s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(152) {
  transition-delay: 4.275s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(153) {
  transition-delay: 4.3s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(154) {
  transition-delay: 4.325s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(155) {
  transition-delay: 4.35s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(156) {
  transition-delay: 4.375s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(157) {
  transition-delay: 4.4s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(158) {
  transition-delay: 4.425s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(159) {
  transition-delay: 4.45s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(160) {
  transition-delay: 4.475s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(161) {
  transition-delay: 4.5s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(162) {
  transition-delay: 4.525s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(163) {
  transition-delay: 4.55s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(164) {
  transition-delay: 4.575s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(165) {
  transition-delay: 4.6s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(166) {
  transition-delay: 4.625s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(167) {
  transition-delay: 4.65s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(168) {
  transition-delay: 4.675s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(169) {
  transition-delay: 4.7s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(170) {
  transition-delay: 4.725s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(171) {
  transition-delay: 4.75s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(172) {
  transition-delay: 4.775s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(173) {
  transition-delay: 4.8s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(174) {
  transition-delay: 4.825s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(175) {
  transition-delay: 4.85s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(176) {
  transition-delay: 4.875s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(177) {
  transition-delay: 4.9s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(178) {
  transition-delay: 4.925s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(179) {
  transition-delay: 4.95s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(180) {
  transition-delay: 4.975s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(181) {
  transition-delay: 5s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(182) {
  transition-delay: 5.025s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(183) {
  transition-delay: 5.05s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(184) {
  transition-delay: 5.075s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(185) {
  transition-delay: 5.1s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(186) {
  transition-delay: 5.125s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(187) {
  transition-delay: 5.15s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(188) {
  transition-delay: 5.175s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(189) {
  transition-delay: 5.2s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(190) {
  transition-delay: 5.225s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(191) {
  transition-delay: 5.25s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(192) {
  transition-delay: 5.275s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(193) {
  transition-delay: 5.3s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(194) {
  transition-delay: 5.325s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(195) {
  transition-delay: 5.35s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(196) {
  transition-delay: 5.375s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(197) {
  transition-delay: 5.4s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(198) {
  transition-delay: 5.425s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(199) {
  transition-delay: 5.45s;
  transform: translate(0);
}

.feed #feed .container .feed-text h3.is-inview .feed-text_wrapper .thirdRow .letter:nth-child(200) {
  transition-delay: 5.475s;
  transform: translate(0);
}

.feed #feed .container .feed-subtitle_wrapper {
  display: inline-block;
  overflow: hidden;
}

.feed #feed .container .feed-subtitle_wrapper .firstRow {
  transition: all 1.4s cubic-bezier(.215, .61, .355, 1);
  transform: translate(0, 100px);
}

.feed #feed .container .feed-subtitle_wrapper.is-inview .firstRow {
  transform: translate(0);
}

.feed #feed .container h3 {
  text-align: center;
}

.feed #feed .container h3 .script-font {
  text-transform: none;
  opacity: 0;
  transition: all 1.4s cubic-bezier(.215, .61, .355, 1);
}

@media only screen and (max-width: 800px) {
  .feed #feed .container h3 .script-font {
    font-size: 95px;
  }
}

.feed #feed .container h3.is-inview .script-font {
  opacity: 1;
  transition-delay: 1s;
}

.feed #feed .container .script-font {
  text-transform: none;
  text-align: center;
}

@media only screen and (max-width: 800px) {
  .feed #feed .container .script-font {
    font-size: 95px;
  }
}

.feed #feed .container .separator {
  margin-bottom: 36px;
}

@media only screen and (max-width: 425px) {
  .feed #feed .container .separator {
    margin-top: 14px;
  }
}

.feed #feed #feed-instagram {
  flex-direction: row;
  gap: 24px;
  transition: all ease-in-out;
  display: flex;
  position: relative;
  top: 92px;
  left: 0;
}

@media only screen and (max-width: 425px) {
  .feed #feed #feed-instagram {
    top: 36px;
  }
}

.feed #feed #feed-instagram .feed-slider-container {
  width: 80%;
  height: 350px;
  position: relative;
  left: 2500px;
}

@media only screen and (max-width: 800px) {
  .feed #feed #feed-instagram .feed-slider-container {
    left: 1400px;
  }
}

.feed #feed #feed-instagram .feed-slider-container .feed-slider-inner {
  pointer-events: none;
  gap: 10px;
  width: 175%;
  display: flex;
  position: relative;
  top: 0;
  left: 0;
}

@media only screen and (max-width: 800px) {
  .feed #feed #feed-instagram .feed-slider-container .feed-slider-inner img {
    height: 200px;
  }
}

@media only screen and (max-width: 425px) {
  .feed .feed-circular-cta {
    height: 152px;
    position: relative;
    top: -24px;
  }
}

.feed .feed-circular-cta .circle {
  position: relative;
  top: 156px;
}

@media only screen and (max-width: 800px) {
  .feed .feed-circular-cta .circle {
    top: -124px;
  }
}

.temoignages {
  justify-content: center;
  padding: 156px 0;
  display: flex;
  position: relative;
}

@media only screen and (max-width: 800px) {
  .temoignages {
    padding: 0 0 60px;
  }
}

.temoignages .container {
  flex-direction: row;
  justify-content: flex-start;
  gap: 188px;
}

@media only screen and (max-width: 1023px) {
  .temoignages .container {
    gap: 92px;
  }
}

@media only screen and (max-width: 800px) {
  .temoignages .container {
    z-index: 1;
    flex-direction: column;
    align-items: center;
    gap: 0;
  }
}

.temoignages .container .temoignages-text h3 {
  max-width: 737px;
}

.temoignages .container .temoignages-text h3 .temoignages-text_wrapper {
  display: inline-block;
  overflow: hidden;
}

.temoignages .container .temoignages-text h3 .temoignages-text_wrapper .firstRow .letter, .temoignages .container .temoignages-text h3 .temoignages-text_wrapper .secondRow .letter, .temoignages .container .temoignages-text h3 .temoignages-text_wrapper .thirdRow .letter {
  line-height: 0;
  transition: all 1.4s cubic-bezier(.215, .61, .355, 1);
  display: inline-block;
  transform: translate(0, 100px);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:first-child {
  transition-delay: 50ms;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(2) {
  transition-delay: 75ms;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(3) {
  transition-delay: .1s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(4) {
  transition-delay: .125s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(5) {
  transition-delay: .15s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(6) {
  transition-delay: .175s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(7) {
  transition-delay: .2s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(8) {
  transition-delay: .225s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(9) {
  transition-delay: .25s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(10) {
  transition-delay: .275s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(11) {
  transition-delay: .3s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(12) {
  transition-delay: .325s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(13) {
  transition-delay: .35s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(14) {
  transition-delay: .375s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(15) {
  transition-delay: .4s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(16) {
  transition-delay: .425s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(17) {
  transition-delay: .45s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(18) {
  transition-delay: .475s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(19) {
  transition-delay: .5s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(20) {
  transition-delay: .525s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(21) {
  transition-delay: .55s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(22) {
  transition-delay: .575s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(23) {
  transition-delay: .6s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(24) {
  transition-delay: .625s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(25) {
  transition-delay: .65s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(26) {
  transition-delay: .675s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(27) {
  transition-delay: .7s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(28) {
  transition-delay: .725s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(29) {
  transition-delay: .75s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(30) {
  transition-delay: .775s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(31) {
  transition-delay: .8s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(32) {
  transition-delay: .825s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(33) {
  transition-delay: .85s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(34) {
  transition-delay: .875s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(35) {
  transition-delay: .9s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(36) {
  transition-delay: .925s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(37) {
  transition-delay: .95s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(38) {
  transition-delay: .975s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(39) {
  transition-delay: 1s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(40) {
  transition-delay: 1.025s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(41) {
  transition-delay: 1.05s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(42) {
  transition-delay: 1.075s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(43) {
  transition-delay: 1.1s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(44) {
  transition-delay: 1.125s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(45) {
  transition-delay: 1.15s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(46) {
  transition-delay: 1.175s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(47) {
  transition-delay: 1.2s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(48) {
  transition-delay: 1.225s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(49) {
  transition-delay: 1.25s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(50) {
  transition-delay: 1.275s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(51) {
  transition-delay: 1.3s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(52) {
  transition-delay: 1.325s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(53) {
  transition-delay: 1.35s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(54) {
  transition-delay: 1.375s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(55) {
  transition-delay: 1.4s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(56) {
  transition-delay: 1.425s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(57) {
  transition-delay: 1.45s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(58) {
  transition-delay: 1.475s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(59) {
  transition-delay: 1.5s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(60) {
  transition-delay: 1.525s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(61) {
  transition-delay: 1.55s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(62) {
  transition-delay: 1.575s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(63) {
  transition-delay: 1.6s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(64) {
  transition-delay: 1.625s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(65) {
  transition-delay: 1.65s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(66) {
  transition-delay: 1.675s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(67) {
  transition-delay: 1.7s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(68) {
  transition-delay: 1.725s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(69) {
  transition-delay: 1.75s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(70) {
  transition-delay: 1.775s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(71) {
  transition-delay: 1.8s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(72) {
  transition-delay: 1.825s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(73) {
  transition-delay: 1.85s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(74) {
  transition-delay: 1.875s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(75) {
  transition-delay: 1.9s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(76) {
  transition-delay: 1.925s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(77) {
  transition-delay: 1.95s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(78) {
  transition-delay: 1.975s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(79) {
  transition-delay: 2s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(80) {
  transition-delay: 2.025s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(81) {
  transition-delay: 2.05s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(82) {
  transition-delay: 2.075s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(83) {
  transition-delay: 2.1s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(84) {
  transition-delay: 2.125s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(85) {
  transition-delay: 2.15s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(86) {
  transition-delay: 2.175s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(87) {
  transition-delay: 2.2s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(88) {
  transition-delay: 2.225s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(89) {
  transition-delay: 2.25s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(90) {
  transition-delay: 2.275s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(91) {
  transition-delay: 2.3s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(92) {
  transition-delay: 2.325s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(93) {
  transition-delay: 2.35s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(94) {
  transition-delay: 2.375s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(95) {
  transition-delay: 2.4s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(96) {
  transition-delay: 2.425s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(97) {
  transition-delay: 2.45s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(98) {
  transition-delay: 2.475s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(99) {
  transition-delay: 2.5s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .firstRow .letter:nth-child(100) {
  transition-delay: 2.525s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:first-child {
  transition-delay: .3s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(2) {
  transition-delay: .325s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(3) {
  transition-delay: .35s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(4) {
  transition-delay: .375s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(5) {
  transition-delay: .4s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(6) {
  transition-delay: .425s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(7) {
  transition-delay: .45s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(8) {
  transition-delay: .475s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(9) {
  transition-delay: .5s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(10) {
  transition-delay: .525s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(11) {
  transition-delay: .55s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(12) {
  transition-delay: .575s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(13) {
  transition-delay: .6s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(14) {
  transition-delay: .625s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(15) {
  transition-delay: .65s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(16) {
  transition-delay: .675s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(17) {
  transition-delay: .7s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(18) {
  transition-delay: .725s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(19) {
  transition-delay: .75s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(20) {
  transition-delay: .775s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(21) {
  transition-delay: .8s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(22) {
  transition-delay: .825s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(23) {
  transition-delay: .85s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(24) {
  transition-delay: .875s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(25) {
  transition-delay: .9s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(26) {
  transition-delay: .925s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(27) {
  transition-delay: .95s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(28) {
  transition-delay: .975s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(29) {
  transition-delay: 1s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(30) {
  transition-delay: 1.025s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(31) {
  transition-delay: 1.05s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(32) {
  transition-delay: 1.075s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(33) {
  transition-delay: 1.1s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(34) {
  transition-delay: 1.125s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(35) {
  transition-delay: 1.15s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(36) {
  transition-delay: 1.175s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(37) {
  transition-delay: 1.2s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(38) {
  transition-delay: 1.225s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(39) {
  transition-delay: 1.25s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(40) {
  transition-delay: 1.275s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(41) {
  transition-delay: 1.3s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(42) {
  transition-delay: 1.325s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(43) {
  transition-delay: 1.35s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(44) {
  transition-delay: 1.375s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(45) {
  transition-delay: 1.4s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(46) {
  transition-delay: 1.425s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(47) {
  transition-delay: 1.45s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(48) {
  transition-delay: 1.475s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(49) {
  transition-delay: 1.5s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(50) {
  transition-delay: 1.525s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(51) {
  transition-delay: 1.55s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(52) {
  transition-delay: 1.575s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(53) {
  transition-delay: 1.6s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(54) {
  transition-delay: 1.625s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(55) {
  transition-delay: 1.65s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(56) {
  transition-delay: 1.675s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(57) {
  transition-delay: 1.7s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(58) {
  transition-delay: 1.725s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(59) {
  transition-delay: 1.75s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(60) {
  transition-delay: 1.775s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(61) {
  transition-delay: 1.8s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(62) {
  transition-delay: 1.825s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(63) {
  transition-delay: 1.85s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(64) {
  transition-delay: 1.875s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(65) {
  transition-delay: 1.9s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(66) {
  transition-delay: 1.925s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(67) {
  transition-delay: 1.95s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(68) {
  transition-delay: 1.975s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(69) {
  transition-delay: 2s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(70) {
  transition-delay: 2.025s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(71) {
  transition-delay: 2.05s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(72) {
  transition-delay: 2.075s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(73) {
  transition-delay: 2.1s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(74) {
  transition-delay: 2.125s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(75) {
  transition-delay: 2.15s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(76) {
  transition-delay: 2.175s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(77) {
  transition-delay: 2.2s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(78) {
  transition-delay: 2.225s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(79) {
  transition-delay: 2.25s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(80) {
  transition-delay: 2.275s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(81) {
  transition-delay: 2.3s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(82) {
  transition-delay: 2.325s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(83) {
  transition-delay: 2.35s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(84) {
  transition-delay: 2.375s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(85) {
  transition-delay: 2.4s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(86) {
  transition-delay: 2.425s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(87) {
  transition-delay: 2.45s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(88) {
  transition-delay: 2.475s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(89) {
  transition-delay: 2.5s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(90) {
  transition-delay: 2.525s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(91) {
  transition-delay: 2.55s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(92) {
  transition-delay: 2.575s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(93) {
  transition-delay: 2.6s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(94) {
  transition-delay: 2.625s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(95) {
  transition-delay: 2.65s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(96) {
  transition-delay: 2.675s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(97) {
  transition-delay: 2.7s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(98) {
  transition-delay: 2.725s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(99) {
  transition-delay: 2.75s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(100) {
  transition-delay: 2.775s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(101) {
  transition-delay: 2.8s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(102) {
  transition-delay: 2.825s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(103) {
  transition-delay: 2.85s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(104) {
  transition-delay: 2.875s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(105) {
  transition-delay: 2.9s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(106) {
  transition-delay: 2.925s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(107) {
  transition-delay: 2.95s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(108) {
  transition-delay: 2.975s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(109) {
  transition-delay: 3s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(110) {
  transition-delay: 3.025s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(111) {
  transition-delay: 3.05s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(112) {
  transition-delay: 3.075s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(113) {
  transition-delay: 3.1s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(114) {
  transition-delay: 3.125s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(115) {
  transition-delay: 3.15s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(116) {
  transition-delay: 3.175s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(117) {
  transition-delay: 3.2s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(118) {
  transition-delay: 3.225s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(119) {
  transition-delay: 3.25s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(120) {
  transition-delay: 3.275s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(121) {
  transition-delay: 3.3s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(122) {
  transition-delay: 3.325s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(123) {
  transition-delay: 3.35s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(124) {
  transition-delay: 3.375s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(125) {
  transition-delay: 3.4s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(126) {
  transition-delay: 3.425s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(127) {
  transition-delay: 3.45s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(128) {
  transition-delay: 3.475s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(129) {
  transition-delay: 3.5s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(130) {
  transition-delay: 3.525s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(131) {
  transition-delay: 3.55s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(132) {
  transition-delay: 3.575s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(133) {
  transition-delay: 3.6s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(134) {
  transition-delay: 3.625s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(135) {
  transition-delay: 3.65s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(136) {
  transition-delay: 3.675s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(137) {
  transition-delay: 3.7s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(138) {
  transition-delay: 3.725s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(139) {
  transition-delay: 3.75s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(140) {
  transition-delay: 3.775s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(141) {
  transition-delay: 3.8s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(142) {
  transition-delay: 3.825s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(143) {
  transition-delay: 3.85s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(144) {
  transition-delay: 3.875s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(145) {
  transition-delay: 3.9s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(146) {
  transition-delay: 3.925s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(147) {
  transition-delay: 3.95s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(148) {
  transition-delay: 3.975s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(149) {
  transition-delay: 4s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(150) {
  transition-delay: 4.025s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(151) {
  transition-delay: 4.05s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(152) {
  transition-delay: 4.075s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(153) {
  transition-delay: 4.1s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(154) {
  transition-delay: 4.125s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(155) {
  transition-delay: 4.15s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(156) {
  transition-delay: 4.175s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(157) {
  transition-delay: 4.2s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(158) {
  transition-delay: 4.225s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(159) {
  transition-delay: 4.25s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(160) {
  transition-delay: 4.275s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(161) {
  transition-delay: 4.3s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(162) {
  transition-delay: 4.325s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(163) {
  transition-delay: 4.35s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(164) {
  transition-delay: 4.375s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(165) {
  transition-delay: 4.4s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(166) {
  transition-delay: 4.425s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(167) {
  transition-delay: 4.45s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(168) {
  transition-delay: 4.475s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(169) {
  transition-delay: 4.5s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(170) {
  transition-delay: 4.525s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(171) {
  transition-delay: 4.55s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(172) {
  transition-delay: 4.575s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(173) {
  transition-delay: 4.6s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(174) {
  transition-delay: 4.625s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(175) {
  transition-delay: 4.65s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(176) {
  transition-delay: 4.675s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(177) {
  transition-delay: 4.7s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(178) {
  transition-delay: 4.725s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(179) {
  transition-delay: 4.75s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(180) {
  transition-delay: 4.775s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(181) {
  transition-delay: 4.8s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(182) {
  transition-delay: 4.825s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(183) {
  transition-delay: 4.85s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(184) {
  transition-delay: 4.875s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(185) {
  transition-delay: 4.9s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(186) {
  transition-delay: 4.925s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(187) {
  transition-delay: 4.95s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(188) {
  transition-delay: 4.975s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(189) {
  transition-delay: 5s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(190) {
  transition-delay: 5.025s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(191) {
  transition-delay: 5.05s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(192) {
  transition-delay: 5.075s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(193) {
  transition-delay: 5.1s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(194) {
  transition-delay: 5.125s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(195) {
  transition-delay: 5.15s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(196) {
  transition-delay: 5.175s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(197) {
  transition-delay: 5.2s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(198) {
  transition-delay: 5.225s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(199) {
  transition-delay: 5.25s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .secondRow .letter:nth-child(200) {
  transition-delay: 5.275s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:first-child {
  transition-delay: .5s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(2) {
  transition-delay: .525s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(3) {
  transition-delay: .55s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(4) {
  transition-delay: .575s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(5) {
  transition-delay: .6s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(6) {
  transition-delay: .625s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(7) {
  transition-delay: .65s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(8) {
  transition-delay: .675s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(9) {
  transition-delay: .7s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(10) {
  transition-delay: .725s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(11) {
  transition-delay: .75s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(12) {
  transition-delay: .775s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(13) {
  transition-delay: .8s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(14) {
  transition-delay: .825s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(15) {
  transition-delay: .85s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(16) {
  transition-delay: .875s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(17) {
  transition-delay: .9s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(18) {
  transition-delay: .925s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(19) {
  transition-delay: .95s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(20) {
  transition-delay: .975s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(21) {
  transition-delay: 1s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(22) {
  transition-delay: 1.025s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(23) {
  transition-delay: 1.05s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(24) {
  transition-delay: 1.075s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(25) {
  transition-delay: 1.1s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(26) {
  transition-delay: 1.125s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(27) {
  transition-delay: 1.15s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(28) {
  transition-delay: 1.175s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(29) {
  transition-delay: 1.2s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(30) {
  transition-delay: 1.225s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(31) {
  transition-delay: 1.25s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(32) {
  transition-delay: 1.275s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(33) {
  transition-delay: 1.3s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(34) {
  transition-delay: 1.325s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(35) {
  transition-delay: 1.35s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(36) {
  transition-delay: 1.375s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(37) {
  transition-delay: 1.4s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(38) {
  transition-delay: 1.425s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(39) {
  transition-delay: 1.45s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(40) {
  transition-delay: 1.475s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(41) {
  transition-delay: 1.5s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(42) {
  transition-delay: 1.525s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(43) {
  transition-delay: 1.55s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(44) {
  transition-delay: 1.575s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(45) {
  transition-delay: 1.6s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(46) {
  transition-delay: 1.625s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(47) {
  transition-delay: 1.65s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(48) {
  transition-delay: 1.675s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(49) {
  transition-delay: 1.7s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(50) {
  transition-delay: 1.725s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(51) {
  transition-delay: 1.75s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(52) {
  transition-delay: 1.775s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(53) {
  transition-delay: 1.8s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(54) {
  transition-delay: 1.825s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(55) {
  transition-delay: 1.85s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(56) {
  transition-delay: 1.875s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(57) {
  transition-delay: 1.9s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(58) {
  transition-delay: 1.925s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(59) {
  transition-delay: 1.95s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(60) {
  transition-delay: 1.975s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(61) {
  transition-delay: 2s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(62) {
  transition-delay: 2.025s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(63) {
  transition-delay: 2.05s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(64) {
  transition-delay: 2.075s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(65) {
  transition-delay: 2.1s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(66) {
  transition-delay: 2.125s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(67) {
  transition-delay: 2.15s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(68) {
  transition-delay: 2.175s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(69) {
  transition-delay: 2.2s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(70) {
  transition-delay: 2.225s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(71) {
  transition-delay: 2.25s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(72) {
  transition-delay: 2.275s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(73) {
  transition-delay: 2.3s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(74) {
  transition-delay: 2.325s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(75) {
  transition-delay: 2.35s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(76) {
  transition-delay: 2.375s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(77) {
  transition-delay: 2.4s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(78) {
  transition-delay: 2.425s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(79) {
  transition-delay: 2.45s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(80) {
  transition-delay: 2.475s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(81) {
  transition-delay: 2.5s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(82) {
  transition-delay: 2.525s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(83) {
  transition-delay: 2.55s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(84) {
  transition-delay: 2.575s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(85) {
  transition-delay: 2.6s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(86) {
  transition-delay: 2.625s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(87) {
  transition-delay: 2.65s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(88) {
  transition-delay: 2.675s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(89) {
  transition-delay: 2.7s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(90) {
  transition-delay: 2.725s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(91) {
  transition-delay: 2.75s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(92) {
  transition-delay: 2.775s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(93) {
  transition-delay: 2.8s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(94) {
  transition-delay: 2.825s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(95) {
  transition-delay: 2.85s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(96) {
  transition-delay: 2.875s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(97) {
  transition-delay: 2.9s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(98) {
  transition-delay: 2.925s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(99) {
  transition-delay: 2.95s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(100) {
  transition-delay: 2.975s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(101) {
  transition-delay: 3s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(102) {
  transition-delay: 3.025s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(103) {
  transition-delay: 3.05s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(104) {
  transition-delay: 3.075s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(105) {
  transition-delay: 3.1s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(106) {
  transition-delay: 3.125s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(107) {
  transition-delay: 3.15s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(108) {
  transition-delay: 3.175s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(109) {
  transition-delay: 3.2s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(110) {
  transition-delay: 3.225s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(111) {
  transition-delay: 3.25s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(112) {
  transition-delay: 3.275s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(113) {
  transition-delay: 3.3s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(114) {
  transition-delay: 3.325s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(115) {
  transition-delay: 3.35s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(116) {
  transition-delay: 3.375s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(117) {
  transition-delay: 3.4s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(118) {
  transition-delay: 3.425s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(119) {
  transition-delay: 3.45s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(120) {
  transition-delay: 3.475s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(121) {
  transition-delay: 3.5s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(122) {
  transition-delay: 3.525s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(123) {
  transition-delay: 3.55s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(124) {
  transition-delay: 3.575s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(125) {
  transition-delay: 3.6s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(126) {
  transition-delay: 3.625s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(127) {
  transition-delay: 3.65s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(128) {
  transition-delay: 3.675s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(129) {
  transition-delay: 3.7s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(130) {
  transition-delay: 3.725s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(131) {
  transition-delay: 3.75s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(132) {
  transition-delay: 3.775s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(133) {
  transition-delay: 3.8s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(134) {
  transition-delay: 3.825s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(135) {
  transition-delay: 3.85s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(136) {
  transition-delay: 3.875s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(137) {
  transition-delay: 3.9s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(138) {
  transition-delay: 3.925s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(139) {
  transition-delay: 3.95s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(140) {
  transition-delay: 3.975s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(141) {
  transition-delay: 4s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(142) {
  transition-delay: 4.025s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(143) {
  transition-delay: 4.05s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(144) {
  transition-delay: 4.075s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(145) {
  transition-delay: 4.1s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(146) {
  transition-delay: 4.125s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(147) {
  transition-delay: 4.15s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(148) {
  transition-delay: 4.175s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(149) {
  transition-delay: 4.2s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(150) {
  transition-delay: 4.225s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(151) {
  transition-delay: 4.25s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(152) {
  transition-delay: 4.275s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(153) {
  transition-delay: 4.3s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(154) {
  transition-delay: 4.325s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(155) {
  transition-delay: 4.35s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(156) {
  transition-delay: 4.375s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(157) {
  transition-delay: 4.4s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(158) {
  transition-delay: 4.425s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(159) {
  transition-delay: 4.45s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(160) {
  transition-delay: 4.475s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(161) {
  transition-delay: 4.5s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(162) {
  transition-delay: 4.525s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(163) {
  transition-delay: 4.55s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(164) {
  transition-delay: 4.575s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(165) {
  transition-delay: 4.6s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(166) {
  transition-delay: 4.625s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(167) {
  transition-delay: 4.65s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(168) {
  transition-delay: 4.675s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(169) {
  transition-delay: 4.7s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(170) {
  transition-delay: 4.725s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(171) {
  transition-delay: 4.75s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(172) {
  transition-delay: 4.775s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(173) {
  transition-delay: 4.8s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(174) {
  transition-delay: 4.825s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(175) {
  transition-delay: 4.85s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(176) {
  transition-delay: 4.875s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(177) {
  transition-delay: 4.9s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(178) {
  transition-delay: 4.925s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(179) {
  transition-delay: 4.95s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(180) {
  transition-delay: 4.975s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(181) {
  transition-delay: 5s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(182) {
  transition-delay: 5.025s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(183) {
  transition-delay: 5.05s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(184) {
  transition-delay: 5.075s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(185) {
  transition-delay: 5.1s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(186) {
  transition-delay: 5.125s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(187) {
  transition-delay: 5.15s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(188) {
  transition-delay: 5.175s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(189) {
  transition-delay: 5.2s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(190) {
  transition-delay: 5.225s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(191) {
  transition-delay: 5.25s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(192) {
  transition-delay: 5.275s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(193) {
  transition-delay: 5.3s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(194) {
  transition-delay: 5.325s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(195) {
  transition-delay: 5.35s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(196) {
  transition-delay: 5.375s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(197) {
  transition-delay: 5.4s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(198) {
  transition-delay: 5.425s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(199) {
  transition-delay: 5.45s;
  transform: translate(0);
}

.temoignages .container .temoignages-text h3.is-inview .temoignages-text_wrapper .thirdRow .letter:nth-child(200) {
  transition-delay: 5.475s;
  transform: translate(0);
}

.temoignages .container .temoignages-left-side:after {
  content: "";
  z-index: -1;
  background-color: #fff;
  width: 50%;
  height: 60%;
  padding: 156px 0;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(0, 10%);
}

@media only screen and (max-width: 800px) {
  .temoignages .container .temoignages-left-side:after {
    background-color: inherit;
    width: 100%;
  }
}

.temoignages .container .temoignages-left-side .temoignages-text {
  margin-top: 92px;
}

@media only screen and (max-width: 425px) {
  .temoignages .container .temoignages-left-side .temoignages-text {
    margin-top: 4em;
  }
}

@media only screen and (max-width: 800px) {
  .temoignages .container .temoignages-left-side h3 {
    text-align: center;
    color: #fff;
  }
}

.temoignages .container .temoignages-left-side h3 .temoignages-text_wrapper {
  width: 110%;
  margin-bottom: 12px;
}

@media only screen and (max-width: 425px) {
  .temoignages .container .temoignages-left-side h3 .temoignages-text_wrapper {
    width: 100%;
  }
}

.temoignages .container .temoignages-left-side h3 .script-font {
  text-transform: none;
  opacity: 0;
  transition: all 1.4s cubic-bezier(.215, .61, .355, 1);
}

@media only screen and (max-width: 800px) {
  .temoignages .container .temoignages-left-side h3 .script-font {
    font-size: 95px;
  }
}

.temoignages .container .temoignages-left-side h3.is-inview .script-font {
  opacity: 1;
  transition-delay: 1s;
}

.temoignages .container .temoignages-left-side .temoignages-subtitle_wrapper {
  padding-bottom: 36px;
  overflow: hidden;
}

.temoignages .container .temoignages-left-side .temoignages-subtitle_wrapper .firstRow {
  transition: all 1.4s cubic-bezier(.215, .61, .355, 1);
  transform: translate(0, 100px);
}

.temoignages .container .temoignages-left-side .temoignages-subtitle_wrapper .firstRow p {
  color: #828282;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
}

@media only screen and (max-width: 800px) {
  .temoignages .container .temoignages-left-side .temoignages-subtitle_wrapper .firstRow p {
    text-align: center;
  }
}

.temoignages .container .temoignages-left-side .temoignages-subtitle_wrapper.is-inview .firstRow {
  transform: translate(0);
}

.temoignages .container .temoignages-left-side .script-font {
  text-transform: none;
}

@media only screen and (max-width: 800px) {
  .temoignages .container .temoignages-left-side .script-font {
    font-size: 95px;
  }
}

.temoignages .container .temoignages-left-side .temoignages-slider-wrapper {
  flex-direction: column;
  align-items: center;
  margin-top: 92px;
  padding: 12px;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: 425px) {
  .temoignages .container .temoignages-left-side .temoignages-slider-wrapper {
    margin-top: 36px;
  }
}

.temoignages .container .temoignages-left-side .temoignages-slider-wrapper .slides-container {
  scroll-behavior: smooth;
  flex-direction: row;
  width: 400px;
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  overflow: scroll;
}

.temoignages .container .temoignages-left-side .temoignages-slider-wrapper .slides-container::-webkit-scrollbar {
  opacity: 0;
}

.temoignages .container .temoignages-left-side .temoignages-slider-wrapper .slides-container::-webkit-scrollbar-track {
  opacity: 0;
}

.temoignages .container .temoignages-left-side .temoignages-slider-wrapper .slides-container::-webkit-scrollbar-thumb {
  opacity: 0;
}

@media only screen and (max-width: 425px) {
  .temoignages .container .temoignages-left-side .temoignages-slider-wrapper .slides-container {
    height: auto;
  }
}

.temoignages .container .temoignages-left-side .temoignages-slider-wrapper .slide-arrow {
  background-color: inherit;
  color: #828282;
  cursor: pointer;
  opacity: .5;
  border: none;
  width: 2rem;
  height: 2rem;
  margin: auto;
  padding: 0;
  font-size: 2rem;
  transition: opacity .1s;
  display: flex;
  position: absolute;
  top: -1em;
  bottom: 0;
}

.temoignages .container .temoignages-left-side .temoignages-slider-wrapper .slide-arrow:hover, .temoignages .container .temoignages-left-side .temoignages-slider-wrapper .slide-arrow:focus {
  opacity: 1;
}

.temoignages .container .temoignages-left-side .temoignages-slider-wrapper #slide-arrow-prev {
  border-radius: 10px;
  padding-left: .5rem;
  left: 0;
}

@media only screen and (max-width: 574px) {
  .temoignages .container .temoignages-left-side .temoignages-slider-wrapper #slide-arrow-prev {
    left: 57px;
  }
}

.temoignages .container .temoignages-left-side .temoignages-slider-wrapper #slide-arrow-next {
  border-radius: 10px;
  padding-left: .55rem;
  right: 0;
}

@media only screen and (max-width: 574px) {
  .temoignages .container .temoignages-left-side .temoignages-slider-wrapper #slide-arrow-next {
    right: 57px;
  }
}

.temoignages .container .temoignages-left-side .temoignages-slider-wrapper .slide {
  flex: 1 0 100%;
  width: 100%;
  height: 100%;
  padding-left: 72px;
}

@media only screen and (max-width: 800px) {
  .temoignages .container .temoignages-left-side .temoignages-slider-wrapper .slide {
    padding-left: inherit;
  }
}

.temoignages .container .temoignages-left-side .temoignages-slider-wrapper .slide .slide-header {
  flex-direction: row;
  gap: 12px;
  display: flex;
}

@media only screen and (max-width: 800px) {
  .temoignages .container .temoignages-left-side .temoignages-slider-wrapper .slide .slide-header {
    text-align: center;
    flex-direction: column;
    align-items: center;
  }
}

.temoignages .container .temoignages-left-side .temoignages-slider-wrapper .slide .slide-header .slide-avatar {
  text-align: center;
  color: #828282;
  background-color: #f6f6f6;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  display: flex;
}

.temoignages .container .temoignages-left-side .temoignages-slider-wrapper .slide .slide-header .slide-text .slide-name {
  color: #828282;
  font-size: 14px;
}

@media only screen and (max-width: 800px) {
  .temoignages .container .temoignages-left-side .temoignages-slider-wrapper .slide .slide-header .slide-text .slide-name {
    color: #fff;
  }
}

.temoignages .container .temoignages-left-side .temoignages-slider-wrapper .slide .slide-header .slide-text .slide-stars {
  color: #e4be72;
}

.temoignages .container .temoignages-left-side .temoignages-slider-wrapper .slide .slide-content {
  color: #828282;
  width: 90%;
  font-weight: 100;
  line-height: 24px;
}

@media only screen and (max-width: 800px) {
  .temoignages .container .temoignages-left-side .temoignages-slider-wrapper .slide .slide-content {
    text-align: center;
    color: #fff;
    width: 60%;
    margin: auto;
  }
}

.temoignages .container .temoignages-left-side .temoignages-slider-wrapper a {
  margin-top: 12px;
  font-weight: 100;
  text-decoration: underline;
}

.temoignages .container .temoignages-right-side {
  padding-left: 92px;
  padding-right: 24px;
}

@media only screen and (max-width: 1320px) {
  .temoignages .container .temoignages-right-side {
    padding-left: 24px;
  }
}

.temoignages .container .temoignages-right-side:before {
  content: "";
  z-index: -2;
  background-color: #13152e;
  background-image: url("temoignages.1299c0c3.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 60%;
  height: 80%;
  padding: 156px 0;
  position: absolute;
  top: 0;
  right: 0;
}

@media only screen and (max-width: 800px) {
  .temoignages .container .temoignages-right-side:before {
    width: 100%;
  }
}

@media only screen and (max-width: 425px) {
  .temoignages .container .temoignages-right-side:before {
    width: 100%;
    padding: 124px 0;
  }
}

.temoignages .container .temoignages-right-side .temoignages-right-side-title {
  color: #bebebe;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: left;
  margin-top: 111px;
  margin-bottom: 92px;
  font-size: 14px;
  font-weight: 500;
}

@media only screen and (max-width: 800px) {
  .temoignages .container .temoignages-right-side .temoignages-right-side-title {
    text-align: center;
    margin-bottom: 60px;
  }
}

.temoignages .container .temoignages-right-side .temoignages-display-logo {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  margin-bottom: 36px;
  display: flex;
}

@media only screen and (max-width: 800px) {
  .temoignages .container .temoignages-right-side .temoignages-display-logo {
    display: none;
  }
}

.temoignages .container .temoignages-right-side .temoignages-mobile-display-logo {
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 36px;
  margin-bottom: 36px;
  display: none;
}

.temoignages .container .temoignages-right-side .temoignages-mobile-display-logo .temoignages-img-responsive {
  width: 50%;
  height: 50%;
}

@media only screen and (max-width: 800px) {
  .temoignages .container .temoignages-right-side .temoignages-mobile-display-logo {
    display: flex;
  }
}

nav {
  z-index: 999;
  justify-content: space-between;
  padding: 24px 36px 8px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: all !important;
}

@media only screen and (max-width: 800px) {
  nav {
    padding: 12px 36px 8px;
    display: none;
  }
}

nav .nav-left {
  color: #fff;
  align-items: center;
  gap: 24px;
  display: flex;
}

nav .nav-left svg {
  fill: #fff;
}

nav .nav-left img {
  filter: invert(0);
  cursor: pointer;
}

nav .nav-left img:hover {
  filter: contrast(1.2);
}

nav .nav-right {
  color: #fff;
  gap: 72px;
  display: flex;
}

nav .nav-right button {
  color: #fff;
  position: relative;
  top: 8px;
}

nav .nav-center {
  flex-direction: row;
}

nav .nav-center ul {
  flex-direction: row;
  align-items: center;
  gap: 156px;
  display: flex;
}

@media only screen and (min-width: 1023px) and (max-width: 1320px) {
  nav .nav-center ul {
    gap: 36px;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1023px) {
  nav .nav-center ul {
    gap: 24px;
  }
}

nav .nav-center ul li {
  overflow: hidden;
}

nav .nav-center ul li a {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  height: 5px;
  font-size: 12px;
  font-weight: lighter;
  text-decoration: none;
  overflow: hidden;
}

nav .nav-center ul li a span {
  transition: all .4s ease-in-out;
  display: inline-block;
  position: relative;
}

nav .nav-center ul li a span:before {
  color: #e4be72;
  content: attr(data-hover);
  position: absolute;
  top: 105%;
  transform: translate3d(0, 0, 0);
}

nav .nav-center ul li a:hover span {
  transform: translateY(-110%);
}

nav.sticky {
  backdrop-filter: blur(10px);
  background-color: #ffffff5a;
  border-bottom: 1px solid #ffffff48;
  padding: 8px 36px;
  transition: all .5s;
  position: fixed;
  top: 0;
  opacity: 1 !important;
}

nav.sticky .nav-left, nav.sticky .nav-right, nav.sticky a {
  color: #000 !important;
}

nav.sticky .nav-left svg, nav.sticky .nav-right svg, nav.sticky a svg {
  fill: #000 !important;
}

nav.sticky .nav-left img, nav.sticky .nav-right img, nav.sticky a img {
  filter: invert() !important;
}

nav.sticky .nav-left button, nav.sticky .nav-right button, nav.sticky a button {
  color: #000;
}

nav.sticky .nav-left button:hover, nav.sticky .nav-right button:hover, nav.sticky a button:hover {
  color: #fff;
}

nav#mobile-navbar {
  align-items: center;
  display: none;
  position: fixed;
}

@media only screen and (max-width: 800px) {
  nav#mobile-navbar {
    display: flex;
  }
}

nav#mobile-navbar.mobile-navbar-sticky {
  backdrop-filter: blur(10px);
  background-color: #ffffff5a;
  border-bottom: 1px solid #ffffff48;
}

nav#mobile-navbar.mobile-navbar-sticky .mobile-nav-left:before, nav#mobile-navbar.mobile-navbar-sticky .mobile-nav-right:before {
  border: 1px solid #000;
}

nav#mobile-navbar.mobile-navbar-sticky .mobile-nav-left img, nav#mobile-navbar.mobile-navbar-sticky .mobile-nav-right img {
  filter: invert();
}

nav#mobile-navbar .mobile-nav-left, nav#mobile-navbar .mobile-nav-right {
  position: relative;
}

nav#mobile-navbar .mobile-nav-left:before, nav#mobile-navbar .mobile-nav-right:before {
  content: "";
  -webkit-border-radius: 7.5px;
  -moz-border-radius: 7.5px;
  opacity: .5;
  border: 1px solid #fff;
  border-radius: 1000px;
  width: 15px;
  height: 15px;
  margin: auto;
  padding: 12px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

nav#mobile-navbar .mobile-nav-left img, nav#mobile-navbar .mobile-nav-right img {
  opacity: .5;
}

nav#mobile-navbar .mobile-nav-right img {
  position: relative;
  top: 2px;
}

#mobile-menu {
  z-index: 99999;
  background-color: #13152e;
  flex-direction: column;
  align-items: center;
  gap: 92px;
  width: 100vw;
  height: 100vh;
  padding-bottom: 24px;
  display: none;
  position: fixed;
}

#mobile-menu.active {
  display: flex;
}

#mobile-menu.active ul li .mobile-menu-text_wrapper .firstRow {
  transform: translate(0);
}

#mobile-menu .mobile-menu {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 36px 8px;
  display: flex;
}

#mobile-menu .mobile-menu .mobile-menu-left, #mobile-menu .mobile-menu .mobile-menu-right {
  position: relative;
}

#mobile-menu .mobile-menu .mobile-menu-left:before, #mobile-menu .mobile-menu .mobile-menu-right:before {
  content: "";
  -webkit-border-radius: 7.5px;
  -moz-border-radius: 7.5px;
  opacity: .5;
  border: 1px solid #fff;
  border-radius: 1000px;
  width: 15px;
  height: 15px;
  margin: auto;
  padding: 12px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#mobile-menu .mobile-menu .mobile-menu-left img, #mobile-menu .mobile-menu .mobile-menu-right img {
  opacity: .5;
}

#mobile-menu .mobile-menu .mobile-menu-right img {
  position: relative;
  top: 2px;
}

#mobile-menu ul {
  flex-direction: column;
  align-items: center;
  gap: 36px;
  display: flex;
}

#mobile-menu ul li {
  text-align: center;
}

#mobile-menu ul li .mobile-menu-text_wrapper {
  display: inline-block;
  overflow: hidden;
}

#mobile-menu ul li .mobile-menu-text_wrapper .firstRow {
  transition: all 1.4s cubic-bezier(.215, .61, .355, 1);
  transform: translate(0, 40px);
}

#mobile-menu ul li .mobile-menu-text_wrapper .firstRow a {
  text-transform: uppercase;
  font-family: Bigilla;
  font-size: 39px;
  font-weight: bold;
  text-decoration: none;
}

#mobile-menu .mobile-menu-social {
  flex-direction: row;
  gap: 36px;
  display: flex;
}

#loader {
  pointer-events: none;
  z-index: 9999999;
  background: #090a17;
  width: 100%;
  height: 100vh;
  animation: 3s 2s forwards slideIn;
  position: fixed;
  top: 0;
  left: 0;
}

@media only screen and (max-width: 425px) {
  #loader {
    animation: 2s 2s forwards slideIn;
  }
}

#loader .loader {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
  width: 100%;
  height: 100%;
  display: flex;
}

@media only screen and (max-width: 425px) {
  #loader .loader .shiny {
    width: 65px;
    height: 164px;
  }
}

#loader .loader p {
  text-align: center;
  color: #ffffff50;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: lighter;
}

@keyframes slideIn {
  0% {
    top: 0;
  }

  100% {
    top: -200%;
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
}

header {
  flex-direction: column;
  align-items: center;
  padding-top: 274px;
  display: flex;
  position: relative;
}

@media only screen and (max-width: 574px) {
  header {
    height: 100vh;
    min-height: 380px;
    padding-top: 0;
  }
}

@media only screen and (min-width: 574px) and (max-width: 1023px) {
  header {
    height: 85vh;
    min-height: 380px;
    padding-top: 188px;
  }
}

@media only screen and (min-width: 1023px) {
  header {
    height: 110vh;
    min-height: 46.875rem;
  }
}

@media only screen and (max-width: 574px) {
  header .container {
    align-items: center;
    height: 100vh;
    padding: 12px;
  }

  header .container .header-text {
    flex-direction: column;
    justify-content: flex-end;
    height: 100vh;
    padding-bottom: 36px;
    display: flex;
  }
}

@media only screen and (min-width: 574px) and (max-width: 1320px) {
  header .container {
    align-items: flex-start;
    padding-left: 12px;
  }
}

header .header-background {
  background-color: #111121;
  background-image: url("bg-header.7d0a19c6.webp");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  animation: 3s 2s zoomIn;
  display: flex;
  position: absolute;
  top: 0;
  transform: scale(1);
}

@media only screen and (max-width: 574px) {
  header .header-background {
    background-position: 90% -4em;
    height: 100vh;
    min-height: 380px;
    padding: 124px 12px 12px;
  }
}

@media only screen and (min-width: 1023px) and (max-width: 1320px) {
  header .header-background {
    background-position: 70% 100%;
  }
}

header a {
  text-decoration: none;
}

header button {
  opacity: 0;
  z-index: 2;
  margin-right: 12px;
  transition: all 1.4s cubic-bezier(.215, .61, .355, 1);
  position: relative;
  overflow: hidden;
}

header button.is-inview {
  opacity: 1;
  transition-delay: 3.2s;
}

header button img {
  height: 16px;
  position: relative;
  top: 2px;
}

header h1, header h2 {
  color: #fff;
}

@media only screen and (max-width: 574px) {
  header h1, header h2 {
    text-align: center;
  }
}

header h1 .header-text_wrapper, header h2 .header-text_wrapper {
  display: inline-block;
  overflow: hidden;
}

header h1 .header-script-font_wrapper, header h2 .header-script-font_wrapper {
  z-index: 2;
  display: inline-block;
  position: relative;
}

header h1 .header-text_wrapper .firstRow, header h1 .header-text_wrapper .secondRow, header h1 .header-text_wrapper .thirdRow {
  transition: all 1.4s cubic-bezier(.215, .61, .355, 1);
  transform: translate(0, 30px);
}

header h1.is-inview .header-text_wrapper .firstRow, header h1.is-inview .header-text_wrapper .secondRow, header h1.is-inview .header-text_wrapper .thirdRow {
  transition-delay: 2s;
  transform: translate(0);
}

header h2 {
  text-transform: uppercase;
  padding-left: 24px;
}

@media only screen and (max-width: 574px) {
  header h2 {
    padding-left: 0;
  }
}

header h2 .script-font {
  z-index: 2;
  opacity: 0;
  padding-left: 8px;
  font-size: 14vw;
  transition: all 1.4s cubic-bezier(.215, .61, .355, 1);
  display: inline-block;
  position: relative;
  top: 24px;
}

@media only screen and (max-width: 425px) {
  header h2 .script-font {
    font-size: 130px;
    top: 4px;
  }
}

@media only screen and (min-width: 425px) and (max-width: 800px) {
  header h2 .script-font {
    font-size: 150px;
    top: 8px;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1023px) {
  header h2 .script-font {
    font-size: 180px;
  }
}

@media only screen and (min-width: 1023px) and (max-width: 1320px) {
  header h2 .script-font {
    font-size: 180px;
  }
}

header h2 .header-text_wrapper .prestige {
  color: #f6f6f6;
}

header h2 .header-text_wrapper .firstRow .letter, header h2 .header-text_wrapper .secondRow .letter, header h2 .header-text_wrapper .thirdRow .letter {
  line-height: 0;
  transition: all 1.4s cubic-bezier(.215, .61, .355, 1);
  display: inline-block;
  transform: translate(0, 100px);
}

header h2.is-inview .header-text_wrapper .firstRow .letter:first-child {
  transition-delay: 2.25s;
  transform: translate(0);
}

header h2.is-inview .header-text_wrapper .firstRow .letter:nth-child(2) {
  transition-delay: 2.275s;
  transform: translate(0);
}

header h2.is-inview .header-text_wrapper .firstRow .letter:nth-child(3) {
  transition-delay: 2.3s;
  transform: translate(0);
}

header h2.is-inview .header-text_wrapper .firstRow .letter:nth-child(4) {
  transition-delay: 2.325s;
  transform: translate(0);
}

header h2.is-inview .header-text_wrapper .firstRow .letter:nth-child(5) {
  transition-delay: 2.35s;
  transform: translate(0);
}

header h2.is-inview .header-text_wrapper .firstRow .letter:nth-child(6) {
  transition-delay: 2.375s;
  transform: translate(0);
}

header h2.is-inview .header-text_wrapper .firstRow .letter:nth-child(7) {
  transition-delay: 2.4s;
  transform: translate(0);
}

header h2.is-inview .header-text_wrapper .firstRow .letter:nth-child(8) {
  transition-delay: 2.425s;
  transform: translate(0);
}

header h2.is-inview .header-text_wrapper .firstRow .letter:nth-child(9) {
  transition-delay: 2.45s;
  transform: translate(0);
}

header h2.is-inview .header-text_wrapper .firstRow .letter:nth-child(10) {
  transition-delay: 2.475s;
  transform: translate(0);
}

header h2.is-inview .header-text_wrapper .secondRow .letter:first-child, header h2.is-inview .header-text_wrapper .thirdRow .letter:first-child {
  transition-delay: 2.5s;
  transform: translate(0);
}

header h2.is-inview .header-text_wrapper .secondRow .letter:nth-child(2), header h2.is-inview .header-text_wrapper .thirdRow .letter:nth-child(2) {
  transition-delay: 2.525s;
  transform: translate(0);
}

header h2.is-inview .header-text_wrapper .secondRow .letter:nth-child(3), header h2.is-inview .header-text_wrapper .thirdRow .letter:nth-child(3) {
  transition-delay: 2.55s;
  transform: translate(0);
}

header h2.is-inview .header-text_wrapper .secondRow .letter:nth-child(4), header h2.is-inview .header-text_wrapper .thirdRow .letter:nth-child(4) {
  transition-delay: 2.575s;
  transform: translate(0);
}

header h2.is-inview .header-text_wrapper .secondRow .letter:nth-child(5), header h2.is-inview .header-text_wrapper .thirdRow .letter:nth-child(5) {
  transition-delay: 2.6s;
  transform: translate(0);
}

header h2.is-inview .header-text_wrapper .secondRow .letter:nth-child(6), header h2.is-inview .header-text_wrapper .thirdRow .letter:nth-child(6) {
  transition-delay: 2.625s;
  transform: translate(0);
}

header h2.is-inview .header-text_wrapper .secondRow .letter:nth-child(7), header h2.is-inview .header-text_wrapper .thirdRow .letter:nth-child(7) {
  transition-delay: 2.65s;
  transform: translate(0);
}

header h2.is-inview .header-text_wrapper .secondRow .letter:nth-child(8), header h2.is-inview .header-text_wrapper .thirdRow .letter:nth-child(8) {
  transition-delay: 2.675s;
  transform: translate(0);
}

header h2.is-inview .header-text_wrapper .secondRow .letter:nth-child(9), header h2.is-inview .header-text_wrapper .thirdRow .letter:nth-child(9) {
  transition-delay: 2.7s;
  transform: translate(0);
}

header h2.is-inview .header-text_wrapper .secondRow .letter:nth-child(10), header h2.is-inview .header-text_wrapper .thirdRow .letter:nth-child(10) {
  transition-delay: 2.725s;
  transform: translate(0);
}

header h2.is-inview .header-text_wrapper .secondRow .letter:nth-child(11), header h2.is-inview .header-text_wrapper .thirdRow .letter:nth-child(11) {
  transition-delay: 2.75s;
  transform: translate(0);
}

header h2.is-inview .header-text_wrapper .secondRow .letter:nth-child(12), header h2.is-inview .header-text_wrapper .thirdRow .letter:nth-child(12) {
  transition-delay: 2.775s;
  transform: translate(0);
}

header h2.is-inview .header-text_wrapper .secondRow .letter:nth-child(13), header h2.is-inview .header-text_wrapper .thirdRow .letter:nth-child(13) {
  transition-delay: 2.8s;
  transform: translate(0);
}

header h2.is-inview .header-text_wrapper .secondRow .letter:nth-child(14), header h2.is-inview .header-text_wrapper .thirdRow .letter:nth-child(14) {
  transition-delay: 2.825s;
  transform: translate(0);
}

header h2.is-inview .header-text_wrapper .secondRow .letter:nth-child(15), header h2.is-inview .header-text_wrapper .thirdRow .letter:nth-child(15) {
  transition-delay: 2.85s;
  transform: translate(0);
}

header h2.is-inview .header-text_wrapper .secondRow .letter:nth-child(16), header h2.is-inview .header-text_wrapper .thirdRow .letter:nth-child(16) {
  transition-delay: 2.875s;
  transform: translate(0);
}

header h2.is-inview .header-text_wrapper .secondRow .letter:nth-child(17), header h2.is-inview .header-text_wrapper .thirdRow .letter:nth-child(17) {
  transition-delay: 2.9s;
  transform: translate(0);
}

header h2.is-inview .header-text_wrapper .secondRow .letter:nth-child(18), header h2.is-inview .header-text_wrapper .thirdRow .letter:nth-child(18) {
  transition-delay: 2.925s;
  transform: translate(0);
}

header h2.is-inview .header-text_wrapper .secondRow .letter:nth-child(19), header h2.is-inview .header-text_wrapper .thirdRow .letter:nth-child(19) {
  transition-delay: 2.95s;
  transform: translate(0);
}

header h2.is-inview .header-text_wrapper .secondRow .letter:nth-child(20), header h2.is-inview .header-text_wrapper .thirdRow .letter:nth-child(20) {
  transition-delay: 2.975s;
  transform: translate(0);
}

header h2.is-inview .header-script_wrapper {
  width: 100%;
  transition-delay: 3.2s;
}

header h2.is-inview .script-font {
  opacity: 1;
  transition-delay: 2.8s;
}

header .side-text {
  position: absolute;
  bottom: 0;
  right: -11em;
}

@media only screen and (max-width: 800px) {
  header .side-text {
    display: none;
  }
}

header .side-text p {
  color: #fff;
  opacity: .5;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 10px;
  transform: rotate(90deg);
}

header .side-text p:after {
  content: "";
  vertical-align: middle;
  background-color: #fff;
  width: 400px;
  height: 1px;
  margin-left: 24px;
  display: inline-block;
}

footer {
  background-color: #13152e;
  justify-content: center;
  padding: 5em 0 2em;
  display: flex;
  position: relative;
}

@media only screen and (max-width: 425px) {
  footer {
    padding: 4em 0;
  }
}

footer .container {
  align-items: center;
}

footer .container .footer-text h3 {
  max-width: 737px;
}

footer .container .footer-text h3 .footer-text_wrapper {
  display: inline-block;
  overflow: hidden;
}

footer .container .footer-text h3 .footer-text_wrapper .firstRow .letter, footer .container .footer-text h3 .footer-text_wrapper .secondRow .letter, footer .container .footer-text h3 .footer-text_wrapper .thirdRow .letter {
  line-height: 0;
  transition: all 1.4s cubic-bezier(.215, .61, .355, 1);
  display: inline-block;
  transform: translate(0, 100px);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:first-child {
  transition-delay: 50ms;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(2) {
  transition-delay: 75ms;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(3) {
  transition-delay: .1s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(4) {
  transition-delay: .125s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(5) {
  transition-delay: .15s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(6) {
  transition-delay: .175s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(7) {
  transition-delay: .2s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(8) {
  transition-delay: .225s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(9) {
  transition-delay: .25s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(10) {
  transition-delay: .275s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(11) {
  transition-delay: .3s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(12) {
  transition-delay: .325s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(13) {
  transition-delay: .35s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(14) {
  transition-delay: .375s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(15) {
  transition-delay: .4s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(16) {
  transition-delay: .425s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(17) {
  transition-delay: .45s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(18) {
  transition-delay: .475s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(19) {
  transition-delay: .5s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(20) {
  transition-delay: .525s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(21) {
  transition-delay: .55s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(22) {
  transition-delay: .575s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(23) {
  transition-delay: .6s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(24) {
  transition-delay: .625s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(25) {
  transition-delay: .65s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(26) {
  transition-delay: .675s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(27) {
  transition-delay: .7s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(28) {
  transition-delay: .725s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(29) {
  transition-delay: .75s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(30) {
  transition-delay: .775s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(31) {
  transition-delay: .8s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(32) {
  transition-delay: .825s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(33) {
  transition-delay: .85s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(34) {
  transition-delay: .875s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(35) {
  transition-delay: .9s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(36) {
  transition-delay: .925s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(37) {
  transition-delay: .95s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(38) {
  transition-delay: .975s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(39) {
  transition-delay: 1s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(40) {
  transition-delay: 1.025s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(41) {
  transition-delay: 1.05s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(42) {
  transition-delay: 1.075s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(43) {
  transition-delay: 1.1s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(44) {
  transition-delay: 1.125s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(45) {
  transition-delay: 1.15s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(46) {
  transition-delay: 1.175s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(47) {
  transition-delay: 1.2s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(48) {
  transition-delay: 1.225s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(49) {
  transition-delay: 1.25s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(50) {
  transition-delay: 1.275s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(51) {
  transition-delay: 1.3s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(52) {
  transition-delay: 1.325s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(53) {
  transition-delay: 1.35s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(54) {
  transition-delay: 1.375s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(55) {
  transition-delay: 1.4s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(56) {
  transition-delay: 1.425s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(57) {
  transition-delay: 1.45s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(58) {
  transition-delay: 1.475s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(59) {
  transition-delay: 1.5s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(60) {
  transition-delay: 1.525s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(61) {
  transition-delay: 1.55s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(62) {
  transition-delay: 1.575s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(63) {
  transition-delay: 1.6s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(64) {
  transition-delay: 1.625s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(65) {
  transition-delay: 1.65s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(66) {
  transition-delay: 1.675s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(67) {
  transition-delay: 1.7s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(68) {
  transition-delay: 1.725s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(69) {
  transition-delay: 1.75s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(70) {
  transition-delay: 1.775s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(71) {
  transition-delay: 1.8s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(72) {
  transition-delay: 1.825s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(73) {
  transition-delay: 1.85s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(74) {
  transition-delay: 1.875s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(75) {
  transition-delay: 1.9s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(76) {
  transition-delay: 1.925s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(77) {
  transition-delay: 1.95s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(78) {
  transition-delay: 1.975s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(79) {
  transition-delay: 2s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(80) {
  transition-delay: 2.025s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(81) {
  transition-delay: 2.05s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(82) {
  transition-delay: 2.075s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(83) {
  transition-delay: 2.1s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(84) {
  transition-delay: 2.125s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(85) {
  transition-delay: 2.15s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(86) {
  transition-delay: 2.175s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(87) {
  transition-delay: 2.2s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(88) {
  transition-delay: 2.225s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(89) {
  transition-delay: 2.25s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(90) {
  transition-delay: 2.275s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(91) {
  transition-delay: 2.3s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(92) {
  transition-delay: 2.325s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(93) {
  transition-delay: 2.35s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(94) {
  transition-delay: 2.375s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(95) {
  transition-delay: 2.4s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(96) {
  transition-delay: 2.425s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(97) {
  transition-delay: 2.45s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(98) {
  transition-delay: 2.475s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(99) {
  transition-delay: 2.5s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .firstRow .letter:nth-child(100) {
  transition-delay: 2.525s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:first-child {
  transition-delay: .3s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(2) {
  transition-delay: .325s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(3) {
  transition-delay: .35s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(4) {
  transition-delay: .375s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(5) {
  transition-delay: .4s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(6) {
  transition-delay: .425s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(7) {
  transition-delay: .45s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(8) {
  transition-delay: .475s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(9) {
  transition-delay: .5s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(10) {
  transition-delay: .525s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(11) {
  transition-delay: .55s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(12) {
  transition-delay: .575s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(13) {
  transition-delay: .6s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(14) {
  transition-delay: .625s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(15) {
  transition-delay: .65s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(16) {
  transition-delay: .675s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(17) {
  transition-delay: .7s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(18) {
  transition-delay: .725s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(19) {
  transition-delay: .75s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(20) {
  transition-delay: .775s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(21) {
  transition-delay: .8s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(22) {
  transition-delay: .825s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(23) {
  transition-delay: .85s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(24) {
  transition-delay: .875s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(25) {
  transition-delay: .9s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(26) {
  transition-delay: .925s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(27) {
  transition-delay: .95s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(28) {
  transition-delay: .975s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(29) {
  transition-delay: 1s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(30) {
  transition-delay: 1.025s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(31) {
  transition-delay: 1.05s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(32) {
  transition-delay: 1.075s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(33) {
  transition-delay: 1.1s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(34) {
  transition-delay: 1.125s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(35) {
  transition-delay: 1.15s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(36) {
  transition-delay: 1.175s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(37) {
  transition-delay: 1.2s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(38) {
  transition-delay: 1.225s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(39) {
  transition-delay: 1.25s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(40) {
  transition-delay: 1.275s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(41) {
  transition-delay: 1.3s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(42) {
  transition-delay: 1.325s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(43) {
  transition-delay: 1.35s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(44) {
  transition-delay: 1.375s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(45) {
  transition-delay: 1.4s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(46) {
  transition-delay: 1.425s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(47) {
  transition-delay: 1.45s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(48) {
  transition-delay: 1.475s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(49) {
  transition-delay: 1.5s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(50) {
  transition-delay: 1.525s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(51) {
  transition-delay: 1.55s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(52) {
  transition-delay: 1.575s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(53) {
  transition-delay: 1.6s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(54) {
  transition-delay: 1.625s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(55) {
  transition-delay: 1.65s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(56) {
  transition-delay: 1.675s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(57) {
  transition-delay: 1.7s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(58) {
  transition-delay: 1.725s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(59) {
  transition-delay: 1.75s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(60) {
  transition-delay: 1.775s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(61) {
  transition-delay: 1.8s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(62) {
  transition-delay: 1.825s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(63) {
  transition-delay: 1.85s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(64) {
  transition-delay: 1.875s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(65) {
  transition-delay: 1.9s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(66) {
  transition-delay: 1.925s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(67) {
  transition-delay: 1.95s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(68) {
  transition-delay: 1.975s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(69) {
  transition-delay: 2s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(70) {
  transition-delay: 2.025s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(71) {
  transition-delay: 2.05s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(72) {
  transition-delay: 2.075s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(73) {
  transition-delay: 2.1s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(74) {
  transition-delay: 2.125s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(75) {
  transition-delay: 2.15s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(76) {
  transition-delay: 2.175s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(77) {
  transition-delay: 2.2s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(78) {
  transition-delay: 2.225s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(79) {
  transition-delay: 2.25s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(80) {
  transition-delay: 2.275s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(81) {
  transition-delay: 2.3s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(82) {
  transition-delay: 2.325s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(83) {
  transition-delay: 2.35s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(84) {
  transition-delay: 2.375s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(85) {
  transition-delay: 2.4s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(86) {
  transition-delay: 2.425s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(87) {
  transition-delay: 2.45s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(88) {
  transition-delay: 2.475s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(89) {
  transition-delay: 2.5s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(90) {
  transition-delay: 2.525s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(91) {
  transition-delay: 2.55s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(92) {
  transition-delay: 2.575s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(93) {
  transition-delay: 2.6s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(94) {
  transition-delay: 2.625s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(95) {
  transition-delay: 2.65s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(96) {
  transition-delay: 2.675s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(97) {
  transition-delay: 2.7s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(98) {
  transition-delay: 2.725s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(99) {
  transition-delay: 2.75s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(100) {
  transition-delay: 2.775s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(101) {
  transition-delay: 2.8s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(102) {
  transition-delay: 2.825s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(103) {
  transition-delay: 2.85s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(104) {
  transition-delay: 2.875s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(105) {
  transition-delay: 2.9s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(106) {
  transition-delay: 2.925s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(107) {
  transition-delay: 2.95s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(108) {
  transition-delay: 2.975s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(109) {
  transition-delay: 3s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(110) {
  transition-delay: 3.025s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(111) {
  transition-delay: 3.05s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(112) {
  transition-delay: 3.075s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(113) {
  transition-delay: 3.1s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(114) {
  transition-delay: 3.125s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(115) {
  transition-delay: 3.15s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(116) {
  transition-delay: 3.175s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(117) {
  transition-delay: 3.2s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(118) {
  transition-delay: 3.225s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(119) {
  transition-delay: 3.25s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(120) {
  transition-delay: 3.275s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(121) {
  transition-delay: 3.3s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(122) {
  transition-delay: 3.325s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(123) {
  transition-delay: 3.35s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(124) {
  transition-delay: 3.375s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(125) {
  transition-delay: 3.4s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(126) {
  transition-delay: 3.425s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(127) {
  transition-delay: 3.45s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(128) {
  transition-delay: 3.475s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(129) {
  transition-delay: 3.5s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(130) {
  transition-delay: 3.525s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(131) {
  transition-delay: 3.55s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(132) {
  transition-delay: 3.575s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(133) {
  transition-delay: 3.6s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(134) {
  transition-delay: 3.625s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(135) {
  transition-delay: 3.65s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(136) {
  transition-delay: 3.675s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(137) {
  transition-delay: 3.7s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(138) {
  transition-delay: 3.725s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(139) {
  transition-delay: 3.75s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(140) {
  transition-delay: 3.775s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(141) {
  transition-delay: 3.8s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(142) {
  transition-delay: 3.825s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(143) {
  transition-delay: 3.85s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(144) {
  transition-delay: 3.875s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(145) {
  transition-delay: 3.9s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(146) {
  transition-delay: 3.925s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(147) {
  transition-delay: 3.95s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(148) {
  transition-delay: 3.975s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(149) {
  transition-delay: 4s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(150) {
  transition-delay: 4.025s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(151) {
  transition-delay: 4.05s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(152) {
  transition-delay: 4.075s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(153) {
  transition-delay: 4.1s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(154) {
  transition-delay: 4.125s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(155) {
  transition-delay: 4.15s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(156) {
  transition-delay: 4.175s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(157) {
  transition-delay: 4.2s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(158) {
  transition-delay: 4.225s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(159) {
  transition-delay: 4.25s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(160) {
  transition-delay: 4.275s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(161) {
  transition-delay: 4.3s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(162) {
  transition-delay: 4.325s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(163) {
  transition-delay: 4.35s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(164) {
  transition-delay: 4.375s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(165) {
  transition-delay: 4.4s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(166) {
  transition-delay: 4.425s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(167) {
  transition-delay: 4.45s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(168) {
  transition-delay: 4.475s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(169) {
  transition-delay: 4.5s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(170) {
  transition-delay: 4.525s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(171) {
  transition-delay: 4.55s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(172) {
  transition-delay: 4.575s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(173) {
  transition-delay: 4.6s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(174) {
  transition-delay: 4.625s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(175) {
  transition-delay: 4.65s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(176) {
  transition-delay: 4.675s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(177) {
  transition-delay: 4.7s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(178) {
  transition-delay: 4.725s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(179) {
  transition-delay: 4.75s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(180) {
  transition-delay: 4.775s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(181) {
  transition-delay: 4.8s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(182) {
  transition-delay: 4.825s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(183) {
  transition-delay: 4.85s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(184) {
  transition-delay: 4.875s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(185) {
  transition-delay: 4.9s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(186) {
  transition-delay: 4.925s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(187) {
  transition-delay: 4.95s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(188) {
  transition-delay: 4.975s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(189) {
  transition-delay: 5s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(190) {
  transition-delay: 5.025s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(191) {
  transition-delay: 5.05s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(192) {
  transition-delay: 5.075s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(193) {
  transition-delay: 5.1s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(194) {
  transition-delay: 5.125s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(195) {
  transition-delay: 5.15s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(196) {
  transition-delay: 5.175s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(197) {
  transition-delay: 5.2s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(198) {
  transition-delay: 5.225s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(199) {
  transition-delay: 5.25s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .secondRow .letter:nth-child(200) {
  transition-delay: 5.275s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:first-child {
  transition-delay: .5s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(2) {
  transition-delay: .525s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(3) {
  transition-delay: .55s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(4) {
  transition-delay: .575s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(5) {
  transition-delay: .6s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(6) {
  transition-delay: .625s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(7) {
  transition-delay: .65s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(8) {
  transition-delay: .675s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(9) {
  transition-delay: .7s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(10) {
  transition-delay: .725s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(11) {
  transition-delay: .75s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(12) {
  transition-delay: .775s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(13) {
  transition-delay: .8s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(14) {
  transition-delay: .825s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(15) {
  transition-delay: .85s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(16) {
  transition-delay: .875s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(17) {
  transition-delay: .9s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(18) {
  transition-delay: .925s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(19) {
  transition-delay: .95s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(20) {
  transition-delay: .975s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(21) {
  transition-delay: 1s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(22) {
  transition-delay: 1.025s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(23) {
  transition-delay: 1.05s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(24) {
  transition-delay: 1.075s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(25) {
  transition-delay: 1.1s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(26) {
  transition-delay: 1.125s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(27) {
  transition-delay: 1.15s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(28) {
  transition-delay: 1.175s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(29) {
  transition-delay: 1.2s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(30) {
  transition-delay: 1.225s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(31) {
  transition-delay: 1.25s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(32) {
  transition-delay: 1.275s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(33) {
  transition-delay: 1.3s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(34) {
  transition-delay: 1.325s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(35) {
  transition-delay: 1.35s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(36) {
  transition-delay: 1.375s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(37) {
  transition-delay: 1.4s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(38) {
  transition-delay: 1.425s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(39) {
  transition-delay: 1.45s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(40) {
  transition-delay: 1.475s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(41) {
  transition-delay: 1.5s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(42) {
  transition-delay: 1.525s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(43) {
  transition-delay: 1.55s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(44) {
  transition-delay: 1.575s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(45) {
  transition-delay: 1.6s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(46) {
  transition-delay: 1.625s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(47) {
  transition-delay: 1.65s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(48) {
  transition-delay: 1.675s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(49) {
  transition-delay: 1.7s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(50) {
  transition-delay: 1.725s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(51) {
  transition-delay: 1.75s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(52) {
  transition-delay: 1.775s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(53) {
  transition-delay: 1.8s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(54) {
  transition-delay: 1.825s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(55) {
  transition-delay: 1.85s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(56) {
  transition-delay: 1.875s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(57) {
  transition-delay: 1.9s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(58) {
  transition-delay: 1.925s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(59) {
  transition-delay: 1.95s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(60) {
  transition-delay: 1.975s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(61) {
  transition-delay: 2s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(62) {
  transition-delay: 2.025s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(63) {
  transition-delay: 2.05s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(64) {
  transition-delay: 2.075s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(65) {
  transition-delay: 2.1s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(66) {
  transition-delay: 2.125s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(67) {
  transition-delay: 2.15s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(68) {
  transition-delay: 2.175s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(69) {
  transition-delay: 2.2s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(70) {
  transition-delay: 2.225s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(71) {
  transition-delay: 2.25s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(72) {
  transition-delay: 2.275s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(73) {
  transition-delay: 2.3s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(74) {
  transition-delay: 2.325s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(75) {
  transition-delay: 2.35s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(76) {
  transition-delay: 2.375s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(77) {
  transition-delay: 2.4s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(78) {
  transition-delay: 2.425s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(79) {
  transition-delay: 2.45s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(80) {
  transition-delay: 2.475s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(81) {
  transition-delay: 2.5s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(82) {
  transition-delay: 2.525s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(83) {
  transition-delay: 2.55s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(84) {
  transition-delay: 2.575s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(85) {
  transition-delay: 2.6s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(86) {
  transition-delay: 2.625s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(87) {
  transition-delay: 2.65s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(88) {
  transition-delay: 2.675s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(89) {
  transition-delay: 2.7s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(90) {
  transition-delay: 2.725s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(91) {
  transition-delay: 2.75s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(92) {
  transition-delay: 2.775s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(93) {
  transition-delay: 2.8s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(94) {
  transition-delay: 2.825s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(95) {
  transition-delay: 2.85s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(96) {
  transition-delay: 2.875s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(97) {
  transition-delay: 2.9s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(98) {
  transition-delay: 2.925s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(99) {
  transition-delay: 2.95s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(100) {
  transition-delay: 2.975s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(101) {
  transition-delay: 3s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(102) {
  transition-delay: 3.025s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(103) {
  transition-delay: 3.05s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(104) {
  transition-delay: 3.075s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(105) {
  transition-delay: 3.1s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(106) {
  transition-delay: 3.125s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(107) {
  transition-delay: 3.15s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(108) {
  transition-delay: 3.175s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(109) {
  transition-delay: 3.2s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(110) {
  transition-delay: 3.225s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(111) {
  transition-delay: 3.25s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(112) {
  transition-delay: 3.275s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(113) {
  transition-delay: 3.3s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(114) {
  transition-delay: 3.325s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(115) {
  transition-delay: 3.35s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(116) {
  transition-delay: 3.375s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(117) {
  transition-delay: 3.4s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(118) {
  transition-delay: 3.425s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(119) {
  transition-delay: 3.45s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(120) {
  transition-delay: 3.475s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(121) {
  transition-delay: 3.5s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(122) {
  transition-delay: 3.525s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(123) {
  transition-delay: 3.55s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(124) {
  transition-delay: 3.575s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(125) {
  transition-delay: 3.6s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(126) {
  transition-delay: 3.625s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(127) {
  transition-delay: 3.65s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(128) {
  transition-delay: 3.675s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(129) {
  transition-delay: 3.7s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(130) {
  transition-delay: 3.725s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(131) {
  transition-delay: 3.75s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(132) {
  transition-delay: 3.775s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(133) {
  transition-delay: 3.8s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(134) {
  transition-delay: 3.825s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(135) {
  transition-delay: 3.85s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(136) {
  transition-delay: 3.875s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(137) {
  transition-delay: 3.9s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(138) {
  transition-delay: 3.925s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(139) {
  transition-delay: 3.95s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(140) {
  transition-delay: 3.975s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(141) {
  transition-delay: 4s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(142) {
  transition-delay: 4.025s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(143) {
  transition-delay: 4.05s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(144) {
  transition-delay: 4.075s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(145) {
  transition-delay: 4.1s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(146) {
  transition-delay: 4.125s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(147) {
  transition-delay: 4.15s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(148) {
  transition-delay: 4.175s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(149) {
  transition-delay: 4.2s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(150) {
  transition-delay: 4.225s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(151) {
  transition-delay: 4.25s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(152) {
  transition-delay: 4.275s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(153) {
  transition-delay: 4.3s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(154) {
  transition-delay: 4.325s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(155) {
  transition-delay: 4.35s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(156) {
  transition-delay: 4.375s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(157) {
  transition-delay: 4.4s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(158) {
  transition-delay: 4.425s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(159) {
  transition-delay: 4.45s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(160) {
  transition-delay: 4.475s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(161) {
  transition-delay: 4.5s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(162) {
  transition-delay: 4.525s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(163) {
  transition-delay: 4.55s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(164) {
  transition-delay: 4.575s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(165) {
  transition-delay: 4.6s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(166) {
  transition-delay: 4.625s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(167) {
  transition-delay: 4.65s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(168) {
  transition-delay: 4.675s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(169) {
  transition-delay: 4.7s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(170) {
  transition-delay: 4.725s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(171) {
  transition-delay: 4.75s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(172) {
  transition-delay: 4.775s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(173) {
  transition-delay: 4.8s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(174) {
  transition-delay: 4.825s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(175) {
  transition-delay: 4.85s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(176) {
  transition-delay: 4.875s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(177) {
  transition-delay: 4.9s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(178) {
  transition-delay: 4.925s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(179) {
  transition-delay: 4.95s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(180) {
  transition-delay: 4.975s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(181) {
  transition-delay: 5s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(182) {
  transition-delay: 5.025s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(183) {
  transition-delay: 5.05s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(184) {
  transition-delay: 5.075s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(185) {
  transition-delay: 5.1s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(186) {
  transition-delay: 5.125s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(187) {
  transition-delay: 5.15s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(188) {
  transition-delay: 5.175s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(189) {
  transition-delay: 5.2s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(190) {
  transition-delay: 5.225s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(191) {
  transition-delay: 5.25s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(192) {
  transition-delay: 5.275s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(193) {
  transition-delay: 5.3s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(194) {
  transition-delay: 5.325s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(195) {
  transition-delay: 5.35s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(196) {
  transition-delay: 5.375s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(197) {
  transition-delay: 5.4s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(198) {
  transition-delay: 5.425s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(199) {
  transition-delay: 5.45s;
  transform: translate(0);
}

footer .container .footer-text h3.is-inview .footer-text_wrapper .thirdRow .letter:nth-child(200) {
  transition-delay: 5.475s;
  transform: translate(0);
}

footer .container .subtitle {
  padding: 36px 0 0;
}

footer .container .footer-subtitle_wrapper {
  overflow: hidden;
}

footer .container .footer-subtitle_wrapper .firstRow {
  transition: all 1.4s cubic-bezier(.215, .61, .355, 1);
  transform: translate(0, 100px);
}

footer .container .footer-subtitle_wrapper .firstRow p {
  text-align: center;
}

footer .container .footer-subtitle_wrapper.is-inview .firstRow {
  transform: translate(0);
}

footer .container h3 {
  color: #fff;
}

footer .container .form {
  flex-direction: column;
  gap: 24px;
  margin-top: 36px;
  margin-bottom: 92px;
  display: flex;
}

footer .container button {
  color: #e4be72;
  margin-bottom: 72px;
}

@media only screen and (max-width: 425px) {
  footer .container button {
    width: 80%;
  }
}

footer .container p.social {
  color: #fff;
  opacity: .75;
  margin-bottom: 36px;
}

footer .container .footer-social {
  flex-direction: row;
  gap: 24px;
  display: flex;
}

footer .container .footer-social .footer-whatsapp {
  position: relative;
  top: -2px;
}

.subfooter {
  background-color: #0a0c1a;
  justify-content: center;
  display: flex;
}

.subfooter .container {
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 0;
}

@media only screen and (max-width: 425px) {
  .subfooter .container {
    flex-direction: column;
    align-items: center;
  }
}

.subfooter .container p {
  color: #fff;
  opacity: .5;
  font-size: 14px;
}

/*# sourceMappingURL=index.fd6a0e79.css.map */
