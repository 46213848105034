@use "../boilerplate/theme.scss" as *;
@use "../boilerplate/variables.scss" as *;

$transition-duration: $rapid-duration;
$transition-bezier: cubic-bezier(0.215, 0.61, 0.355, 1);

$transition-delay-headline: 0.05s;
$transition-delay-base: 0.05s;
$transition-delay-second: 0.3s;
$transition-delay-third: 0.5s;
$transition-delay-increment: 0.025s;

@mixin animateLetters($class, $inview: false) {
  .#{$class} {
    h3 {
      max-width: 737px;
      .#{$class}_wrapper {
        overflow: hidden;
        display: inline-block;

        .firstRow,
        .secondRow,
        .thirdRow {
          .letter {
            display: inline-block;
            line-height: 0;
            transform: translate(0, 100px);
            transition: $transition-duration $transition-bezier;
          }
        }
      }

      @if $inview {
        &.is-inview .#{$class}_wrapper {
          .firstRow {
            .letter {
              @for $i from 1 through 100 {
                &:nth-child(#{$i}) {
                  transform: translate(0, 0);
                  transition-delay: $transition-delay-base +
                    $transition-delay-increment *
                    ($i - 1);
                }
              }
            }
          }

          .secondRow {
            .letter {
              @for $i from 1 through 200 {
                &:nth-child(#{$i}) {
                  transform: translate(0, 0);
                  transition-delay: $transition-delay-second +
                    $transition-delay-increment *
                    ($i - 1);
                }
              }
            }
          }

          .thirdRow {
            .letter {
              @for $i from 1 through 200 {
                &:nth-child(#{$i}) {
                  transform: translate(0, 0);
                  transition-delay: $transition-delay-third +
                    $transition-delay-increment *
                    ($i - 1);
                }
              }
            }
          }
        }
      }
    }
  }
}
