@use "../boilerplate/theme.scss" as *;
@use "../boilerplate/variables.scss" as *;
@use "../boilerplate/mixin.scss" as *;

.feed {
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 6em;
  @media only screen and (max-width: $tablet-breakpoint) {
    margin-bottom: $text-small-spacing;
  }
  @media only screen and (max-width: $mobile-breakpoint) {
    margin-bottom: 0;
  }
  #feed {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: $medium-spacing;
    @media only screen and (max-width: $tablet-breakpoint) {
      margin-bottom: $text-small-spacing;
    }
    @media only screen and (max-width: $mobile-breakpoint) {
      margin-bottom: 0;
    }
    .container {
      align-items: center;
      justify-content: center;
      @include animateLetters("feed-text", true);
      .feed-subtitle_wrapper {
        overflow: hidden;
        display: inline-block;
        .firstRow {
          transform: translate(0, 100px);
          transition: $transition-duration $transition-bezier;
        }

        &.is-inview {
          .firstRow {
            transform: translate(0, 0);
          }
        }
      }
      h3 {
        text-align: center;
        .script-font {
          text-transform: none;
          transition: $transition-duration $transition-bezier;
          opacity: 0;
          @media only screen and (max-width: $tablet-breakpoint) {
            font-size: $mobile-script-font-size;
          }
        }
        &.is-inview .script-font {
          opacity: 1;
          transition-delay: 1s;
        }
      }
      .script-font {
        text-transform: none;
        text-align: center;
        @media only screen and (max-width: $tablet-breakpoint) {
          font-size: $mobile-script-font-size;
        }
      }
      .separator {
        margin-bottom: $text-medium-spacing;
        @media only screen and (max-width: $mobile-breakpoint) {
          margin-top: 14px;
        }
      }
    }
    #feed-instagram {
      display: flex;
      flex-direction: row;
      gap: $text-base-spacing;
      transition: all 0s ease-in-out;
      position: relative;
      left: 0;
      top: $text-wide-spacing;
      @media only screen and (max-width: $mobile-breakpoint) {
        top: $text-medium-spacing;
      }
      .feed-slider-container {
        width: 80%;
        height: 350px;
        left: 2500px;
        @media only screen and (max-width: $tablet-breakpoint) {
          left: 1400px;
        }
        position: relative;
        .feed-slider-inner {
          width: 175%;
          display: flex;
          gap: 10px;
          pointer-events: none;
          position: relative;
          top: 0;
          left: 0;
          @media only screen and (max-width: $tablet-breakpoint) {
            img {
              height: 200px;
            }
          }
        }
      }
    }
  }
  .feed-circular-cta {
    @media only screen and (max-width: $mobile-breakpoint) {
      height: 152px;
      position: relative;
      top: -$text-base-spacing;
    }
    .circle {
      position: relative;
      top: $base-spacing;
      @media only screen and (max-width: $tablet-breakpoint) {
        top: -$small-spacing;
      }
    }
  }
}
