@use "../../boilerplate/theme.scss" as *;
@use "../../boilerplate/variables.scss" as *;
@use "../../boilerplate/mixin.scss" as *;

footer {
  background-color: $dark-blue;
  display: flex;
  justify-content: center;
  position: relative;
  padding: 5em 0 2em 0;
  @media only screen and (max-width: $mobile-breakpoint) {
    padding: 4em 0;
  }
  .container {
    @include animateLetters("footer-text", true);
    align-items: center;
    .subtitle {
      padding: $text-medium-spacing 0 0 0;
    }
    .footer-subtitle_wrapper {
      overflow: hidden;
      .firstRow {
        transform: translate(0, 100px);
        transition: $transition-duration $transition-bezier;
        p {
          text-align: center;
        }
      }

      &.is-inview {
        .firstRow {
          transform: translate(0, 0);
        }
      }
    }
    h3 {
      color: $white;
    }
    .form {
      display: flex;
      flex-direction: column;
      gap: $text-base-spacing;
      margin-top: $text-medium-spacing;
      margin-bottom: $text-wide-spacing;
    }
    button {
      color: $gold;
      margin-bottom: $text-large-spacing;
      @media only screen and (max-width: $mobile-breakpoint) {
        width: 80%;
      }
    }
    p.social {
      color: $white;
      margin-bottom: $text-medium-spacing;
      opacity: 0.75;
    }
    .footer-social {
      display: flex;
      flex-direction: row;
      gap: $text-base-spacing;
      .footer-whatsapp {
        position: relative;
        top: -2px;
      }
    }
  }
}
