@use "./theme.scss" as *;

.scroll {
  box-sizing: border-box;
  overflow: hidden;
  width: 100vw;

  &.has-scroll-smooth {
    position: relative;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 1280px;
  width: 100%;
}
