@use "../../boilerplate/theme.scss" as *;
@use "../../boilerplate/variables.scss" as *;

nav {
  position: absolute;
  display: none;
  left: 0;
  top: 0em;
  right: 0;
  z-index: 999;
  // opacity: 0 !important;
  padding: $text-base-spacing $text-medium-spacing $text-xsmall-spacing
    $text-medium-spacing;
  // transition: all 0.025s;
  display: flex;
  justify-content: space-between;
  pointer-events: all !important;
  @media only screen and (max-width: $tablet-breakpoint) {
    display: none;
    padding: $text-small-spacing $text-medium-spacing $text-xsmall-spacing
      $text-medium-spacing;
  }
  .nav-left {
    color: $white;
    display: flex;
    gap: $text-base-spacing;
    align-items: center;
    svg {
      fill: $white;
    }
    img {
      filter: invert(0);
      cursor: pointer;
      &:hover {
        filter: contrast(1.2);
      }
    }
  }
  .nav-right {
    color: $white;
    display: flex;
    gap: $text-large-spacing;
    button {
      color: $white;
      position: relative;
      top: 8px;
    }
  }
  .nav-center {
    flex-direction: row;
    ul {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: $base-spacing;
      @media only screen and (min-width: $laptop-breakpoint) and (max-width: $desktop-breakpoint) {
        gap: $text-medium-spacing;
      }
      @media only screen and (min-width: $tablet-breakpoint) and (max-width: $laptop-breakpoint) {
        gap: $text-base-spacing;
      }
      li {
        overflow: hidden;
        a {
          text-decoration: none;
          color: $white;
          text-transform: uppercase;
          font-weight: lighter;
          letter-spacing: 2px;
          font-size: $smaller-plus-font-size;
          overflow: hidden;
          height: 5px;
          span {
            position: relative;
            display: inline-block;
            transition: all 0.4s ease-in-out;
            &::before {
              color: $gold;
              position: absolute;
              top: 105%;
              content: attr(data-hover);
              transform: translate3d(0, 0, 0);
            }
          }
          &:hover {
            span {
              transform: translateY(-110%);
            }
          }
        }
      }
    }
  }

  &.sticky {
    position: fixed;
    top: 0em;
    padding: $text-xsmall-spacing $text-medium-spacing;
    transition: all 0.5s;
    opacity: 1 !important;
    background-color: #ffffff5a;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-bottom: 1px solid #ffffff48;
    .nav-left,
    .nav-right,
    a {
      color: $black !important;
      svg {
        fill: $black !important;
      }
      img {
        filter: invert(1) !important;
      }
      button {
        color: $black;
        &:hover {
          color: $white;
        }
      }
    }
  }
}
